import constants from "../constants";
import axios from "axios";
import { baseUrl } from "../../config";

export const AdminActionLogin = (logindetails) => (dispatch) => {
  return axios
    .post(`${baseUrl}/auth/login/`, logindetails, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log("admin", res);
      if (res?.data?.data?.role === "admin") {
        dispatch({ type: constants.ADMIN_USER, payload: res?.data?.data });
        dispatch({
          type: constants.ADMIN_TOKEN,
          payload: res?.data?.data?.token,
        });
      } else {
        dispatch({ type: constants.ADMIN_USER, payload: null });
        dispatch({ type: constants.ADMIN_TOKEN, payload: null });
      }
      return {
        success: true,
        role: res?.data?.data?.role,
        message: "Login Successfully! Redirecting to dashboard...",
      };
    })
    .catch((err) => {
      console.log("rrr", err?.response);
      return {
        success: false,
        message: err?.response?.data?.message || "Something went wrong",
      };
    });
};

export const AdminActionLogout = () => (dispatch) => {
  dispatch({ type: constants.ADMIN_USER, payload: null });
  dispatch({ type: constants.ADMIN_TOKEN, payload: null });
  return { success: true, message: "Logout Successfully" };
};

// System Settings
export const ConfigureSystemSettings = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .post(`${baseUrl}/system_setup/system_configuration/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetSystemConfiguredRecords());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.[0] };
    });
};

export const UpdateSystemSettings = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .patch(
      `${baseUrl}/system_setup/system_configuration/${details.id}/`,
      details,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
    .then((res) => {
      dispatch(GetSystemConfiguredRecords());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const DeleteSystemSettings = (id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .delete(`${baseUrl}/system_setup/system_configuration/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetSystemConfiguredRecords());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const GetSystemConfiguredRecords = () => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/system_setup/system_configuration/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.SYSTEM_CONFIG_RECORD, payload: res?.data });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

// Subscription Actions
export const ClearSubValues = (formMode) => (dispatch) => {
  if (formMode === "CREATE_MODE") {
    dispatch({ type: constants.GET_ONE_SUBSCRIPTION, payload: null });
  }
  dispatch({ type: constants.GET_ONE_SUBSCRIPTION, payload: null });
  return { success: true };
};
export const ConfigureSubscriptions = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .post(`${baseUrl}/system_setup/subscription_plan/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetConfiguredSubscriptionsRecords());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.[0] };
    });
};

export const UpdateSubscription = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .patch(
      `${baseUrl}/system_setup/subscription_plan/${details?.id}/`,
      details,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
    .then((res) => {
      dispatch(GetConfiguredSubscriptionsRecords());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const DeleteSubscription = (sub_id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .delete(`${baseUrl}/system_setup/subscription_plan/${sub_id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetConfiguredSubscriptionsRecords());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const GetConfiguredSubscriptionsRecords = () => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/system_setup/subscription_plan/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      console.log("get", res);
      dispatch({ type: constants.ALL_SUBSCRIPTION_RECORD, payload: res?.data });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const GetOneSubscription = (id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/system_setup/subscription_plan/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.GET_ONE_SUBSCRIPTION, payload: res?.data });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

// Service Configuration Actions
export const ConfigureOneServices = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .post(`${baseUrl}/system_setup/service/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetConfiguredServices());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.[0] };
    });
};

export const UpdateServices = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .patch(`${baseUrl}/system_setup/service/${details.id}/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetConfiguredServices());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const GetConfiguredServices = () => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/system_setup/service/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.ALL_SERVICES_RECORD, payload: res?.data });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const GetOneConfiguredServices = (id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/system_setup/service/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.GET_ONE_SERVICE, payload: res?.data });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const DeleteConfiguredService = (id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .delete(`${baseUrl}/system_setup/service/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetConfiguredServices());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

// Products Actions
export const GetAllProducts = () => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/catalogue/products/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({ type: constants.ALL_PRODUCT_RECORD, payload: res?.data });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};
export const CreateOneProduct = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  console.log({ token, details });
  return axios
    .post(`${baseUrl}/catalogue/products/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllProducts());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.[0] };
    });
};

export const UpdateOneProduct = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .patch(`${baseUrl}/catalogue/products/${details.id}/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllProducts());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const DeleteProduct = (id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .delete(`${baseUrl}/catalogue/products/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllProducts());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

// Raw Materials Actions
export const GetAllRawMaterials = () => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/catalogue/raw-materials/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: constants.ALL_RAW_MATERIALS_RECORD,
        payload: res?.data,
      });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};
export const CreateRawMaterial = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  console.log({ token, details });
  return axios
    .post(`${baseUrl}/catalogue/raw-materials/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllRawMaterials());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.[0] };
    });
};

export const UpdateRawMaterial = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .patch(`${baseUrl}/catalogue/raw-materials/${details.id}/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllRawMaterials());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const DeleteRawMaterial = (id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .delete(`${baseUrl}/catalogue/raw-materials/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllRawMaterials());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

// Inventory Products Actions
export const GetAllProductInventory = () => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/inventory/product/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: constants.ALL_PRODUCT_INVENTORY_RECORD,
        payload: res?.data,
      });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};
export const CreateProductInventory = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  console.log({ token, details });
  return axios
    .post(`${baseUrl}/inventory/product/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllProductInventory());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.[0] };
    });
};

export const UpdateProductInventory = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .patch(`${baseUrl}/inventory/product/${details.id}/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllProductInventory());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const DeleteProductInventory = (id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .delete(`${baseUrl}/inventory/product/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllProductInventory());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

// Inventory Raw Materials Actions
export const GetRawMaterialsInventory = () => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/inventory/raw-material/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: constants.ALL_RAW_MATERIALS_RECORD_INVENTORY,
        payload: res?.data,
      });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};
export const CreateRawMaterialInventory = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  console.log({ token, details });
  return axios
    .post(`${baseUrl}/inventory/raw-material/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetRawMaterialsInventory());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.[0] };
    });
};

export const UpdateRawMaterialInventory = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .patch(`${baseUrl}/inventory/raw-material/${details.id}/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetRawMaterialsInventory());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const DeleteRawMaterialInventory = (id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .delete(`${baseUrl}/inventory/raw-material/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetRawMaterialsInventory());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

// Sales Actions
export const GetAllSales = () => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/sales/sale-item/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: constants.ALL_SALES_RECORD,
        payload: res?.data,
      });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};
export const CreateSales = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  console.log({ token, details });
  return axios
    .post(`${baseUrl}/sales/sale-item/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllSales());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.[0] };
    });
};

export const UpdateSales = (details) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .patch(`${baseUrl}/sales/sale-item/${details.id}/`, details, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllSales());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

export const DeleteSales = (id) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .delete(`${baseUrl}/sales/sale-item/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch(GetAllSales());
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

// Product Inventory Chart
export const GetProductInventoryChart =
  (year, product_id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios
      .get(`${baseUrl}/inventory/chart/product/${year}/${product_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: constants.PRODUCT_INVENTORY_CHART,
          payload: res?.data,
        });
        return { success: true, message: res?.data?.details };
      })
      .catch((err) => {
        console.log("err", err?.response);
        return { success: false, message: err?.response?.data?.detail };
      });
  };

// Raw Materials Inventory Chart
export const GetRawMaterialsInventoryChart =
  (year, raw_material_id) => (dispatch, getState) => {
    let token = getState().authReducer.access_token;
    return axios
      .get(
        `${baseUrl}/inventory/chart/raw-material/${year}/${raw_material_id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: constants.RAW_MATERIALS_INVENTORY_CHART,
          payload: res?.data,
        });
        return { success: true, message: res?.data?.details };
      })
      .catch((err) => {
        console.log("err", err?.response);
        return { success: false, message: err?.response?.data?.detail };
      });
  };

// Sales AAmount Chart
export const GetSalesAmountChart = (year) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/sales/chart/amount/${year}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: constants.SALES_AMOUNT_CHART,
        payload: res?.data,
      });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};

// Sales Quantity Chart
export const GetSalesQuantityChart = (year) => (dispatch, getState) => {
  let token = getState().authReducer.access_token;
  return axios
    .get(`${baseUrl}/sales/chart/quantity/${year}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: constants.SALES_QUANTITY_CHART,
        payload: res?.data,
      });
      return { success: true, message: res?.data?.details };
    })
    .catch((err) => {
      console.log("err", err?.response);
      return { success: false, message: err?.response?.data?.detail };
    });
};
