import React, { useEffect } from "react";
import DashboardLayout from "../Layout/DashboardLayout";
import {
  FarmActivitiesHeader,
  MyFarmTaskContainer,
} from "./FarmActivitiy.styled";
import { Button, Card, Col } from "antd";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { connect } from "react-redux";
import moment from "moment";
import { getUserFarmTask } from "../../redux/actions/farmActions";
import AllFarmFieldActivities from "./AllFarmFieldActivities";
import { useHistory } from "react-router-dom";
import { Save } from "@mui/icons-material";

const MyTaskCalendar = (props) => {
  const { user_farm_task, getUserFarmTask } = props;
  const localizer = momentLocalizer(moment); // or globalizeLocalizer

  const history = useHistory();

  const newEventList =
    user_farm_task?.map((task) => {
      return {
        ...task,
        title: task.name,
        allDay: true,
        start: task.start_date,
        end: task.end_date,
      };
    }) || [];

  useEffect(() => {
    const getAllUserTask = async () => {
      await getUserFarmTask();
    };
    getAllUserTask();
  }, [getUserFarmTask]);

  const createTask = () => {
    history.push("/dashboard/farmactivity/configure");
  };

  return (
    <DashboardLayout pageTitle="My Tasks">
      <FarmActivitiesHeader>
        <h3>My Tasks</h3>
        <Button icon={<Save />} onClick={createTask} type="primary">
          New Task
        </Button>
      </FarmActivitiesHeader>
      <MyFarmTaskContainer
        data-aos="fade-up"
        data-aos-delay="2s"
        data-aos-duration="1s"
      >
        {user_farm_task?.length === 0 ? (
          <>
            <div style={{ marginTop: "3rem" }}>
              <div className="notask">There is no task for you.</div>
            </div>
          </>
        ) : (
          <>
            <Col xs={24} xl={24} lg={24}>
              <Card className="card-shadow">
                <Calendar
                  localizer={localizer}
                  events={newEventList}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500 }}
                />
              </Card>
            </Col>
            {/* <AllFarmFieldActivities
              data-aos="fade-up"
              data-aos-delay="2s"
              data-aos-duration="1s"
            /> */}
          </>
        )}
      </MyFarmTaskContainer>
    </DashboardLayout>
  );
};
const mapStateToProps = (state) => {
  return {
    user_farm_task: state.FarmReducer.user_farm_task,
  };
};

export default connect(mapStateToProps, { getUserFarmTask })(MyTaskCalendar);
