import React from "react";
import { AgentHeaderLayout } from "./AdminLayout.styled";
import { Home, Menu } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const AdminHeader = (props) => {
  const history = useHistory();
  const gotoHome = () => {
    history.push("/dashboard");
  };
  return (
    <AgentHeaderLayout>
      <div onClick={props.openDrawer} className="opendrawer">
        <Menu />
      </div>
      <div onClick={gotoHome} className="user_notification">
        <Home />
        {/*<div className="count">*/}
        {/*    <span>2</span>*/}
        {/*</div>*/}
      </div>
    </AgentHeaderLayout>
  );
};

export default AdminHeader;
