import React, { useContext, useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  FarmSummaryDataTable,
  // FarmSummaryDownloadBtn,
  // FarmSummaryEachNav,
  // FarmSummaryNav,
  // FarmSummaryNavInner,
  FarmSummaryData,
  FarmSummarySearch,
  FarmSummaryDataGrid,
  FarmViewBtn,
  FarmEditBtn,
  FarmSummaryInner,
  FarmSummaryNav,
  FarmSummaryNavInner,
  FarmSummaryEachNav,
  FarmSummaryDownloadBtn,
} from "../../styles/farmManagement/FarmSummaryStyle";
// import SkeletonCard from "../../adapters/util/SkeletonCard";
import DashboardLayout from "../Layout/DashboardLayout";
import { Card, Table } from "antd";

import { AppContext } from "../../appRouter";
import { IndexMapRender } from "./IndexMapRender";
import { EditModal } from "../util/EditFieldModal";
import { FormControl, Grid, InputLabel, Select } from "@mui/material";
import {
  HistoryCalendarBox,
  HistoryCalendarPickDate,
  HistoryCalendarTitle,
} from "../../styles/weather/WeatherStyle";
import { backDate, cloudLimit } from "../util/Util";
import { getIndexValAndValueChange } from "../../adapters/sentinelRequests";

const FarmSummary = (props) => {
  const { handleSelectedFieldId } = useContext(AppContext);
  const [editOpen, setEditOpen] = useState(false);
  const [SelfieldData, setSelFieldData] = useState({});

  const [buttonState, setButtonState] = useState(false);
  const [allIndexData, setAllIndexData] = useState([
    { indexVal: 0, valChange: 0, imageDate: "", index: "", isReady: false },
    { indexVal: 0, valChange: 0, imageDate: "", index: "", isReady: false },
    { indexVal: 0, valChange: 0, imageDate: "", index: "", isReady: false },
    { indexVal: 0, valChange: 0, imageDate: "", index: "", isReady: false },
    { indexVal: 0, valChange: 0, imageDate: "", index: "", isReady: false },
    { indexVal: 0, valChange: 0, imageDate: "", index: "", isReady: false },
    { indexVal: 0, valChange: 0, imageDate: "", index: "", isReady: false },
    { indexVal: 0, valChange: 0, imageDate: "", index: "", isReady: false },
    { indexVal: 0, valChange: 0, imageDate: "", index: "", isReady: false },
    { indexVal: 0, valChange: 0, imageDate: "", index: "", isReady: false },
  ]);

  const [date, setDate] = useState(backDate(0));

  const [selIndex, setSelIndex] = useState("NDMI");

  const [indexList, setIndexList] = useState([
    { id: 2, name: "NDMI", value: "NDMI" },
    { id: 3, name: "NDWI", value: "NDWII" },
  ]);

  const [indexImagery, setIndexImagery] = useState({
    index: selIndex,
    time: date,
  });

  // --- Handler to update the imagery details upon click---
  const handleUpdateImage = (index, time) => {
    setIndexImagery({
      index: index,
      time: date,
    });
  };

  const history = useHistory();

  const handleEditField = (fieldData) => {
    setSelFieldData(fieldData);
    setEditOpen(true);
  };

  const handleViewField = (fieldId) => {
    // -- set the global field id to this viewed
    handleSelectedFieldId(fieldId);
    setTimeout(() => {
      history.push(`/dashboard/farmsummary/info/${fieldId}`);
    }, 1000);
  };

  const searchDataGrid = (e) => {
    let searchItem = e.target.value;
    let res = [];
    props?.originalRows.find(function (post, index) {
      if (
        post?.name.includes(searchItem) ||
        post?.group_name?.includes(searchItem) ||
        post?.location?.includes(searchItem)
      ) {
        res.push(post);
        return props?.setRows(res);
      }
      return 0;
    });
  };

  const columns = [
    {
      title: <strong>Field</strong>,
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
    },
    {
      title: <strong>Location</strong>,
      dataIndex: "location",
      key: "location",
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
    },
    {
      title: <strong>Centroid</strong>,
      dataIndex: "centroid",
      key: "centroid",
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
      render: (val) => (
        <div>
          {val?.length > 0 ? "Lat :" + val[1] + ", Lng :" + val[0] : " null "}
        </div>
      ),
    },
    {
      title: <strong>Area</strong>,
      dataIndex: "size",
      key: "size",
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
      render: (val) => <div>{val + " ha"}</div>,
    },
    {
      title: <strong>Group</strong>,
      dataIndex: "group_name",
      key: "group_name",
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
    },
    {
      title: <strong>Last Crop</strong>,
      dataIndex: "crop_season",
      key: "crop_season",
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
      render: (val) => <div>{val.length ? val[0]?.crop?.name : "None"}</div>,
    },
    {
      title: <strong>Last Sowing Date</strong>,
      dataIndex: "crop_season",
      key: "crop_season",
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
      render: (val) => <div>{val.length ? val[0]?.sowing_date : "None"}</div>,
    },
    {
      title: <strong>Index Name</strong>,
      dataIndex: "id",
      key: "id",
      render: (text, row) => <div>{indexImagery?.index}</div>,
    },
    {
      title: <strong>Index Value</strong>,
      dataIndex: "indexValue",
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
      // key: 'indexValue',
      render: (text, row, index) => (
        <div>
          {!allIndexData[index]?.isReady ? (
            <CircularProgress
              variant="indeterminate"
              disableShrink
              style={{ color: "rgba(0, 106, 75, 1)" }}
              size={40}
              thickness={4}
              value={100}
            />
          ) : (
            allIndexData[index]?.indexVal
          )}
        </div>
      ),
    },
    {
      title: <strong>Value Change</strong>,
      dataIndex: "valueChange",
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
      render: (text, row, index) => (
        <div>
          {!allIndexData[index]?.isReady ? (
            <CircularProgress
              variant="indeterminate"
              disableShrink
              style={{ color: "rgba(0, 106, 75, 1)" }}
              size={40}
              thickness={4}
              value={100}
            />
          ) : (
            allIndexData[index]?.valChange
          )}
        </div>
      ),
    },
    {
      title: <strong>Image Date</strong>,
      dataIndex: "valueChange",
      sorter: {
        compare: (a, b) => a.size - b.size,
        multiple: 3,
      },
      render: (text, row, index) => (
        <div>
          {!allIndexData[index]?.isReady ? (
            <CircularProgress
              variant="indeterminate"
              disableShrink
              style={{ color: "rgba(0, 106, 75, 1)" }}
              size={40}
              thickness={4}
              value={100}
            />
          ) : (
            allIndexData[index]?.imageDate
          )}
        </div>
      ),
    },
    {
      title: <strong>Index Imagery</strong>,
      dataIndex: "Imagery",
      render: (text, row) => (
        // --- Import the satellite image here. It does not require cloud cover, date range and others cos they've been set already
        <IndexMapRender
          cloudCover={cloudLimit}
          dateRange={indexImagery?.time}
          selectedCentroid={row?.centroid}
          selectedCoords={row?.coordinates}
          index={indexImagery?.index}
          height={"8em"}
          zoomVal={9}
          removeBg
        />
      ),
    },
    {
      title: <strong>Actions</strong>,
      dataIndex: "actions",
      render: (text, row) => (
        <>
          <FarmViewBtn onClick={() => handleViewField(row?.id)}>
            View Field
          </FarmViewBtn>
          <FarmEditBtn onClick={() => handleEditField(row)}>
            Edit Field
          </FarmEditBtn>
        </>
      ),
    },
  ];

  const tableState = {
    bordered: false,
    loading: props?.stillFetching,
    size: "default",
    scroll: true,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
  };

  const handleCloseEdit = () => {
    setEditOpen(false);
  };

  const handleIndexType = (e) => {
    if (e === "Vegetative") {
      setIndexList([
        { id: 1, name: "Choose Index", value: null },
        { id: 11, name: "NDVI", value: "NDVI" },
        { id: 22, name: "LAI", value: "LAII" },
        { id: 33, name: "SAVI", value: "SAVI" },
      ]);
    } else {
      setIndexList([
        { id: 1, name: "Choose Index", value: null },
        { id: 14, name: "NDMI", value: "NDMI" },
        { id: 12, name: "NDWI", value: "NDWII" },
      ]);
    }
  };

  const getIndexValValuChange = () => {
    let allFields = props?.rows;

    // --- Disable the button ----
    setButtonState(false);

    let update = {
      indexVal: 0,
      valChange: 0,
      imageDate: "",
      index: "",
      isReady: false,
    };

    async function fetchData(date, selIndex, geometry, maxCC, fieldPos) {
      setAllIndexData((prevData) => {
        return [
          { ...prevData[fieldPos], ...update },
          ...prevData.splice(fieldPos),
        ];
      });

      let { isError, data } = await getIndexValAndValueChange(
        date,
        selIndex,
        geometry,
        maxCC
      );

      if (isError) {
        let update = {
          indexVal: "No Value Found",
          valChange: "No Value Found",
          imageDate: "No Value Found",
          index: selIndex,
          isReady: true,
        };

        setAllIndexData((prevData) => {
          return [
            { ...prevData[fieldPos], ...update },
            ...prevData.splice(fieldPos),
          ];
        });

        if (fieldPos === allFields.length - 1) {
          // --- Enable Button When done, that is when the endpoint hits the last field ---
          setButtonState(true);
        }
      } else {
        let update = {
          indexVal: data?.indexValue?.value.toFixed(4),
          valChange: data?.valueChange.toFixed(4),
          imageDate: data?.indexValue?.dateCaptured,
          index: selIndex,
          isReady: true,
        };

        setAllIndexData((prevData) => {
          return [
            { ...prevData[fieldPos], ...update },
            ...prevData.splice(fieldPos),
          ];
        });

        if (fieldPos === allFields.length - 1) {
          // --- Enable Button When done, that is when the endpoint hits the last field ---
          setButtonState(true);
        }
      }
    }

    allFields.map((eachField, pos) => {
      fetchData(date, selIndex, eachField?.geometry, cloudLimit, pos);
      return 0;
    });
  };

  const handleCompute = () => {
    // buttonState
    if (selIndex === null) {
      return toast.warning("Please select an index!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // --- Re-compute the index and update the image ----
    getIndexValValuChange();
    handleUpdateImage(selIndex, date);
  };

  // --- Hit the endpoint to query all the index value, value change and image date for all the fields (10 per page)
  useEffect(() => {
    getIndexValValuChange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.rows]);

  return (
    <>
      <DashboardLayout pageTitle="Farm Summary">
        <h4 style={{ marginTop: "2em" }}>Farm Summary</h4>

        <FarmSummaryNav style={{ marginTop: "2em", minHeight: "7em" }}>
          <FarmSummaryNavInner
            style={{
              marginLeft: "1em",
              marginRight: "1em",
              marginBottom: "1em",
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} xs={6} sm={4}>
                <FarmSummaryEachNav>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: "100%",
                      color: "#05674a",
                      border: "1px solid rgba(255,255,255,0.3)",
                      borderRadius: "0.3em",
                    }}
                  >
                    <InputLabel
                      htmlFor="outlined-age-native-simple"
                      style={{ color: "#05674a" }}
                    >
                      Index Type
                    </InputLabel>
                    <Select
                      native
                      defaultValue={"Moisture"}
                      label="Index Type"
                      onChange={(e) => handleIndexType(e.target.value)}
                      style={{
                        border: "1px solid rgba(255,255,255,1) !important",
                        color: "#05674a",
                        height: "3em",
                      }}
                    >
                      <option
                        defaultValue={"Moisture"}
                        style={{ color: "#05674a" }}
                      >
                        Moisture
                      </option>
                      <option
                        defaultValue={"Vegetative"}
                        style={{ color: "#05674a" }}
                      >
                        Vegetative
                      </option>
                    </Select>
                  </FormControl>
                </FarmSummaryEachNav>
              </Grid>

              <Grid item lg={3} md={3} xs={6} sm={4}>
                <FarmSummaryEachNav>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: "100%",
                      color: "white",
                      border: "1px solid rgba(255,255,255,0.3)",
                      borderRadius: "0.3em",
                    }}
                  >
                    <InputLabel
                      htmlFor="outlined-age-native-simple"
                      style={{ color: "#05674a", marginTop: "0.5em" }}
                    >
                      Index
                    </InputLabel>
                    <Select
                      native
                      defaultValue={selIndex}
                      label="Index"
                      style={{
                        border: "1px solid rgba(255,255,255,1) !important",
                        color: "#05674a",
                        height: "3em",
                      }}
                      onChange={(e) => setSelIndex(e?.target?.value)}
                    >
                      {indexList?.map((eachItem) => (
                        <option
                          key={eachItem?.name}
                          value={eachItem?.value}
                          style={{ color: "#05674a" }}
                        >
                          {eachItem?.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </FarmSummaryEachNav>
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={4}>
                <HistoryCalendarBox style={{ marginTop: "1em" }}>
                  <HistoryCalendarTitle>Date</HistoryCalendarTitle>
                  <HistoryCalendarPickDate
                    value={date}
                    type="date"
                    max={backDate(0)}
                    onInput={(e) => setDate(e.target.value)}
                  />
                </HistoryCalendarBox>
              </Grid>

              <Grid item lg={3} md={3} xs={12} sm={12}>
                <FarmSummaryEachNav>
                  {buttonState ? (
                    <FarmSummaryDownloadBtn onClick={handleCompute}>
                      Compute Index Details
                    </FarmSummaryDownloadBtn>
                  ) : (
                    <FarmSummaryDownloadBtn
                      style={{ background: "rgba(5,5,5,0.1)" }}
                      disabled
                    >
                      Please wait ....
                    </FarmSummaryDownloadBtn>
                  )}
                </FarmSummaryEachNav>
              </Grid>
            </Grid>
          </FarmSummaryNavInner>
        </FarmSummaryNav>

        <FarmSummaryData style={{ marginTop: "2em", height: "7em" }}>
          <FarmSummaryDataTable>
            <FarmSummaryInner>
              <FarmSummarySearch
                type="search"
                placeholder="Search by Field Name"
                onInput={searchDataGrid}
              />
            </FarmSummaryInner>
          </FarmSummaryDataTable>
        </FarmSummaryData>

        {/* <FarmSummaryData style={{ marginTop: '2em' }}> */}
        <FarmSummaryDataTable>
          <FarmSummaryDataGrid>
            <Card bordered={false} className="card-shadow">
              <Table
                {...tableState}
                dataSource={props?.rows}
                columns={columns}
                scroll={{ x: 500 }}
                rowKey="id"
              />
            </Card>
          </FarmSummaryDataGrid>
        </FarmSummaryDataTable>
        {/* </FarmSummaryData> */}
      </DashboardLayout>

      <EditModal
        open={editOpen}
        fieldData={SelfieldData}
        setRenderData={props?.setRenderData}
        handleCloseEdit={handleCloseEdit}
      />
    </>
  );
};

export default FarmSummary;
