import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import { FarmActivitiesHeader, AllFarmTable } from "./FarmActivitiy.styled";
import { Card, Row, Col, Button, Modal, Table, Tag } from "antd";
import { connect } from "react-redux";
import {
  getAllFarmActivities,
  closeFarmActivities,
  ReAssignFarmTask,
  updateFarmActivities,
  DeleteFarmActivities,
} from "../../redux/actions/farmActions";
import "react-image-lightbox/style.css";
import { Save, GetApp } from "@mui/icons-material";
import CloseFarmActivities from "./CloseFarmActivities";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { TableMenu } from "./TableMenu";
import ReAssignFarmActivities from "./ReassignFarmActivities";
import { toast } from "react-toastify";
import { getTeamMembers } from "../../redux/actions/userManagement";
import UpdateFarmTaskModal from "./UpdateFarmTaskModal";
import { useContext } from "react";
import { AppContext } from "../../appRouter";
import { CSVLink } from "react-csv";

const AllFarmActivityTable = (props) => {
  const {
    getAllFarmActivities,
    closeFarmActivities,
    ReAssignFarmTask,
    updateFarmActivities,
    DeleteFarmActivities,
    getTeamMembers,
    all_farm_activity,
    user_details,
    team_members = [],
  } = props;

  // ---- Call the global Context ----
  const { selectedFieldId } = useContext(AppContext);

  const [loading, setloading] = useState(false);
  const [deleteloading, setDeleteLoading] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [image, setImage] = useState("default.jpg");
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [showassign, setShowReassign] = useState(false);
  const [taskrecord, setTaskRecord] = useState({});
  const [taskAssign, setTaskAssign] = useState({});
  const [csvData, setcsvData] = useState([]);

  // Update Modal
  const [updatevisible, setUpdateVisible] = useState(false);
  const history = useHistory();

  // const location = useLocation();

  // let selectedFieldId = location.pathname.substr(33);

  // in case of refresh of the page
  // if (selectedFieldId === "") {
  //   selectedFieldId = sessionStorage.getItem("selectedFieldId");
  // }

  // const ViewImage = (record) => {
  //   setOpen(true);
  //   setImage(FarmCord);
  // };

  // View Added Services
  const viewSubRecord = (record) => {
    setModalVisible(true);
    setTaskAssign(record?.assignees);
  };

  const closeSubModal = () => {
    setModalVisible(false);
    // setTaskAssign();
  };

  const reformattedRecord = all_farm_activity?.map((rec) => {
    return {
      ...rec,
      assign: rec.assignees[0]?.email,
    };
  });

  const cancelDelete = () => {
    setCurrentIndex(0);
    setVisible(false);
  };

  const showPopconfirm = (x) => {
    setCurrentIndex(x);
    setVisible(true);
  };

  const UpdateFarmTask = (record) => {
    const convertToList = record?.assignees?.map((res) => res.email);
    const updatedRecord = {
      ...record,
      assignees: convertToList,
    };
    setTaskRecord(updatedRecord);
    setUpdateVisible(true);
  };

  const GetOneFarmTask = (record) => {
    const path = generatePath("/dashboard/farmactivity/all-activities/:id", {
      id: `${record.id}`,
    });
    history.push(`${path}`);
  };

  const DeleteOneTask = (record) => {
    Modal.confirm({
      title: "Do you want to delete this task?",
      content: "Click yes to proceed",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      loading: deleteloading,
      async onOk() {
        setDeleteLoading(true);
        const res = await DeleteFarmActivities(record.id);
        if (res.success) {
          setDeleteLoading(false);
          toast("Farm Activity Delete Successfully.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setDeleteLoading(false);
          toast.error(`${res.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      },
      onCancel() {
        setDeleteLoading(false);
        return false;
      },
    });
  };

  const ReassignTask = (record) => {
    setShowReassign(true);
    const convertToList = record?.assignees?.map((res) => res.email);
    const updatedRecord = {
      ...record,
      assignees: convertToList,
    };
    setTaskRecord(updatedRecord);
  };

  const closeReassignTask = () => {
    setShowReassign(false);
  };

  // Close Update Modal
  const closeUpdateModalTask = () => {
    setUpdateVisible(false);
  };

  const column = [
    {
      title: "Task Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "200px",
    },
    {
      title: "Assignee",
      dataIndex: "assignees",
      // render: (_text, record) => (
      //   <>{record.assignees?.length > 1 ? `${record.assign}` : ""}</>
      // ),
      render: (_text, record) => (
        <>
          {
            <Tag
              onClick={() => viewSubRecord(record)}
              style={{ cursor: "pointer" }}
            >
              View Assignee
            </Tag>
          }
        </>
      ),
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   render: (_text, record) => (
    //     <>
    //       <div style={{ cursor: "pointer" }} onClick={() => ViewImage(record)}>
    //         <img style={{ width: "100px" }} src={FarmCord} alt="" />
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "Start Date",
      dataIndex: "start_date",
    },
    {
      title: "End Date",
      dataIndex: "end_date",
    },
    {
      title: "Field info",
      dataIndex: "field_info",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <>
          {status === "close" ? (
            <Tag color="magenta">Close</Tag>
          ) : status === "open" ? (
            <Tag color="green">Open</Tag>
          ) : (
            ""
          )}
        </>
      ),
    },
    // {
    //   title: "action",
    //   dataIndex: "",
    //   align: "center",
    //   render: (_text, record) => (
    //     <>
    //       <div className="tableaction">
    //         <div onClick={() => GetOneFarmTask(record)}>
    //           <Visibility />
    //         </div>
    //         <div onClick={() => UpdateFarmTask(record)}>
    //           <Edit />
    //           <span>Edit</span>
    //         </div>
    //         <div className="del" onClick={() => showPopconfirm(record)}>
    //           <HighlightOff /> <span>Close</span>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "Actions",
      dataIndex: "",
      align: "center",
      render: (_text, record) => (
        <TableMenu
          record={record}
          UpdateFarmTask={UpdateFarmTask}
          ReassignTask={ReassignTask}
          DeleteOneTask={DeleteOneTask}
          closeTask={showPopconfirm}
          GetOneFarmTask={GetOneFarmTask}
        />
      ),
    },
  ];

  useEffect(() => {
    const getCSVData = () => {
      const restructureData = all_farm_activity?.map((item) => ({
        name: item.name,
        description: item.description,
        ...item,
        assignees: item.assignees?.map((role) => role.email),
      }));
      setcsvData(restructureData);
    };
    getCSVData();
  }, [all_farm_activity]);

  const seColumn = [
    {
      title: "Email Address",
      dataIndex: "email",
    },
  ];

  const createTask = () => {
    history.push("/dashboard/farmactivity/configure");
  };

  useEffect(() => {
    const fetchAllUsers = async () => {
      await getTeamMembers(
        user_details?.owned_team?.id || user_details?.user_team
      );
    };
    fetchAllUsers();
  }, [user_details?.owned_team?.id || user_details?.user_team, getTeamMembers]);

  useEffect(() => {
    const getAllActivities = async () => {
      setloading(true);
      const res = await getAllFarmActivities(selectedFieldId);
      if (res.success) {
        setloading(false);
      } else {
        setloading(false);
      }
    };
    getAllActivities();
  }, [selectedFieldId, getAllFarmActivities]);

  return (
    <>
      <DashboardLayout pageTitle="All Farm Activities">
        <FarmActivitiesHeader>
          <h3>All Farm Activities</h3>
        </FarmActivitiesHeader>
        <AllFarmTable
          data-aos="fade-up"
          data-aos-delay="2s"
          data-aos-duration="1s"
        >
          <Row justify="end" align="middle">
            <Col style={{ marginRight: "10px" }}>
              <Button icon={<Save />} onClick={createTask} type="default">
                New Task
              </Button>
            </Col>
            <Col style={{ textAlign: "right" }}>
              {csvData !== undefined && (
                <CSVLink
                  data={csvData}
                  filename={"farm_activity_report.csv"}
                  className="btn btn-primary"
                  target="_blank"
                >
                  Download Task Report <GetApp />
                </CSVLink>
              )}
            </Col>
          </Row>
          <Card
            bordered={false}
            className="card-shadow"
            style={{ marginTop: "2rem" }}
          >
            <Row>
              <Col xs={24}>
                <Table
                  rowKey="id"
                  loading={loading}
                  dataSource={reformattedRecord}
                  columns={column}
                  scroll={{ x: 1000 }}
                />
              </Col>
            </Row>
          </Card>
        </AllFarmTable>
        {/*{open && (*/}
        {/*  <Lightbox mainSrc={image} onCloseRequest={() => setOpen(false)} />*/}
        {/*)}*/}

        <Modal
          title={`Assignee Lists`}
          visible={modalVisible}
          width="650px"
          footer={null}
          onCancel={closeSubModal}
        >
          <Table rowKey="id" columns={seColumn} dataSource={taskAssign} />
        </Modal>

        <CloseFarmActivities
          isModalVisible={visible}
          handleCancel={cancelDelete}
          farmRecordId={currentIndex}
          closeFarmActivities={closeFarmActivities}
        />

        <ReAssignFarmActivities
          team_members={team_members}
          isModalVisible={showassign}
          handleCancel={closeReassignTask}
          taskrecord={taskrecord}
          ReAssignFarmTask={ReAssignFarmTask}
        />
      </DashboardLayout>

      <UpdateFarmTaskModal
        isModalVisible={updatevisible}
        handleCancel={closeUpdateModalTask}
        formData={taskrecord}
        user_details={user_details}
        team_members={team_members}
        selectedFieldId={selectedFieldId}
        updateFarmActivities={updateFarmActivities}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    all_farm_activity: state.FarmReducer.all_farm_activity,
    user_details: state.authReducer.user_details,
    team_members: state.userManagementReducer.team_members,
  };
};

export default connect(mapStateToProps, {
  getAllFarmActivities,
  closeFarmActivities,
  ReAssignFarmTask,
  DeleteFarmActivities,
  updateFarmActivities,
  getTeamMembers,
})(AllFarmActivityTable);
