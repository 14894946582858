import React from "react";
import { Row, Col, Button } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessMessage = (props) => {
  return (
    <div>
      <div className="emailsent">
        <div className="emailsent__message">
          <div className="icon">
            <CheckCircleIcon />
          </div>
          <Row>
            <Col span={24}>
              <h3>{props.successTitle}</h3>
              <h4>{props.description}</h4>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button loading={props.loading} block type="primary">
                <a
                  href={`https://mail.google.com/mail/u/0/#inbox`}
                  target="_blank"
                  rel="noreferrer"
                >
                  OPEN YOUR EMAIL
                </a>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
