import React from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import { ConfigurationContainer } from "./AdminStyle.styled";
import Sales from "./config/sales/Sales";

const AdminSales = () => {
  return (
    <DefaultLayout browserTitle="Inventory">
      <ConfigurationContainer data-aos="fade-right">
        <h3>Sales Management</h3>
        <Sales />
      </ConfigurationContainer>
    </DefaultLayout>
  );
};

export default AdminSales;
