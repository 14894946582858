import React, { useEffect, useState } from "react";
import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
  LineElement,
  CategoryScale,
  Title,
  LinearScale,
  PointElement,
  Filler, // 1. Import Filler plugin
} from "chart.js";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import "./chart.css";
import { toast } from "react-toastify";
import { Col, Row, Select } from "antd";

import {
  GetSalesAmountChart,
  GetSalesQuantityChart,
} from "../../../../redux/actions/adminActions";
import moment from "moment";
const { Option } = Select;

const DashboardChart = (props) => {
  const [amountYear, setAmountYear] = useState(null);
  const [quantityYear, setQuantityYear] = useState(null);
  const {
    GetSalesQuantityChart,
    GetSalesAmountChart,
    sales_quantity_chart,
    sales_amount_chart,
  } = props;

  // Chart
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler,
    Title
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const getSalesAmountRecords = async () => {
    const res = await GetSalesAmountChart(amountYear);
    if (!res.success) {
      toast.error(`Error`);
    }
  };
  const getQuantityRecords = async () => {
    const res = await GetSalesQuantityChart(quantityYear);
    if (!res.success) {
      toast.error(`Error`);
    }
  };
  useEffect(() => {
    if (amountYear) {
      getSalesAmountRecords(amountYear);
    }
    if (quantityYear) {
      getQuantityRecords(quantityYear);
    }
  }, [quantityYear, amountYear]);

  const amount_data = {
    labels: sales_amount_chart ? Object?.keys(sales_amount_chart) : [],
    datasets: [
      {
        label: "Amount",
        data: sales_amount_chart ? Object.values(sales_amount_chart) : [],
        // backgroundColor: "#0A4C3E",
        borderColor: "#0A4C3E",
        color: "#777777",
        pointRadius: 0,
        pointBackgroundColor: "",
        pointBorderWidth: 0,
        fill: true, // Fill the area under the line
        tension: 0.4,
      },
    ],
  };
  const quantity_data = {
    labels: sales_quantity_chart ? Object?.keys(sales_quantity_chart) : [],
    datasets: [
      {
        label: "Quantity",
        data: sales_quantity_chart ? Object.values(sales_quantity_chart) : [],
        // backgroundColor: "#0A4C3E",
        borderColor: "#0A4C3E",
        color: "#777777",
        pointRadius: 0,
        pointBackgroundColor: "",
        pointBorderWidth: 0,
        fill: true, // Fill the area under the line
        tension: 0.4,
      },
    ],
  };

  const checkOnchange = (value, type) => {
    if (type === "amount-year") {
      setAmountYear(value);
    }
    if (type === "quantity-year") {
      setQuantityYear(value);
    }
  };

  const years = ["2024", JSON.stringify(moment().year())];
  const yearChildren = years?.map((obj) => {
    return (
      <Option key={obj} value={obj}>
        {obj}
      </Option>
    );
  });

  useEffect(() => {
    setAmountYear(JSON.stringify(moment().year()));
    setQuantityYear(JSON.stringify(moment().year()));
  }, []);

  return (
    <div className="chart-container">
      <div className="chart-item">
        <Row gutter={24}>
          <Col xs={24} xl={12} lg={12}>
            <h6>Sales Amount</h6>
          </Col>
          <Col xs={24} xl={12} lg={12}>
            <Row gutter={24}>
              <Col xs={24} xl={24} lg={24}>
                <Select
                  name="amount-year"
                  placeholder="Select year"
                  size="medium"
                  value={amountYear}
                  onChange={(event) => checkOnchange(event, "amount-year")}
                  className="select"
                >
                  {yearChildren}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ height: "200px", marginTop: "20px" }}>
          <Line options={options} data={amount_data} />
        </div>
      </div>
      <div className="chart-item">
        <Row gutter={24}>
          <Col xs={24} xl={12} lg={12}>
            <h6>Sales Quantity</h6>
          </Col>
          <Col xs={24} xl={12} lg={12}>
            <Row gutter={24}>
              <Col xs={24} xl={24} lg={24}>
                <Select
                  name="quantity-year"
                  placeholder="Select year"
                  size="medium"
                  value={quantityYear}
                  onChange={(event) => checkOnchange(event, "quantity-year")}
                  className="select"
                >
                  {yearChildren}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ height: "200px", marginTop: "20px" }}>
          <Line options={options} data={quantity_data} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allProducts: state.adminReducer.allProducts,
    allRawMaterials: state.adminReducer.allRawMaterials,
    sales_amount_chart: state.adminReducer.sales_amount_chart,
    sales_quantity_chart: state.adminReducer.sales_quantity_chart,
  };
};

export default connect(mapStateToProps, {
  GetSalesAmountChart,
  GetSalesQuantityChart,
})(DashboardChart);
