import React from "react";

// import RedoIcon from '@mui/icons-material/Redo';
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import MapIcon from "@mui/icons-material/Map";
import SatelliteIcon from "@mui/icons-material/Satellite";

import { useHistory } from "react-router-dom";
import { SwitchBox } from "../../styles/satelliteField/SatelliteFieldStyle";

const SwitchButton = (props) => {
  let history = useHistory();

  const handleRedir = () => {
    history.push(props?.redr);
  };

  return (
    <>
      <SwitchBox onClick={handleRedir}>
        {props?.isDefault ? (
          <VerticalSplitIcon
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />
        ) : (
          <>
            {props?.redrType === "dashboard" ? (
              <MapIcon style={{ marginLeft: "auto", marginRight: "auto" }} />
            ) : (
              <SatelliteIcon
                style={{ marginLeft: "auto", marginRight: "auto" }}
              />
            )}
          </>
        )}
      </SwitchBox>
    </>
  );
};

export default SwitchButton;
