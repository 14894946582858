import React, { useState } from "react";

import { useLocation, Link } from "react-router-dom";
import { DashboardContainer } from "../../styles/util/UtilStyle";
import { Grid, Select, MenuItem, FormControl } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";

import {
  HistoryCalendarCard,
  HistoryDownloadBox,
  ForeCastCard,
  WeatherContainer,
  WeatherLeftContainer,
  WeatherRightContainer,
  WeatherTitle,
  ForeCastTopCardText,
  ForeCastTopCard,
  ForeCastBodyCard,
  HistoryChartCard,
  HistoryCalendarBox,
  HistoryCalendarPickDate,
  HistoryCalendarTitle,
  HistoryBtn,
  HistoryCalendarCardInner,
} from "../../styles/weather/WeatherStyle";
import SkeletonCard from "../../adapters/util/SkeletonCard";
import {
  downloadHistoryChart,
  get5YearsHistoryChart,
  get5YrsAvgHistory,
} from "../../redux/actions/weatherManagement";

import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
  CartesianGrid,
} from "recharts";
import { FarmInfoLink } from "../../styles/farmManagement/FarmSummaryStyle";
import { HumidityChart, PrecipChart, TempChart, WindChart } from "./charts";

const {
  backDate,
  renderColorfulLegendText,
  TriangleBar,
  getNoOfDaysDiff,
} = require("../util/Util");

const HistoryChart = (props) => {
  const {
    from,
    to,
    chartData,
    setStillFetching,
    setFromDate,
    setToDate,
    setCentroid,
    stillFetching,
    rainfallData,
    setChartData,
    centroid,
    urlCoords,
  } = props;

  const [compareOption, setCompareOption] = useState("None");
  const [startDate, setStartDate] = useState(from);
  const [endDate, setEndDate] = useState(to);
  const [chartType, setChartType] = useState("None");

  const [downloadBtnState, setDownloadBtnState] = useState({
    text: "Generate History File",
    canDownload: false,
  });

  const [csvData, setCsvData] = useState([]);

  let startYear = from?.substr(0, 4);
  let endYear = to?.substr(0, 4);

  if (startYear === endYear) {
    endYear = "";
  } else {
    endYear = "/" + endYear;
  }

  // let endYear = to?.substr(0, 4);

  let location = useLocation();

  const loadDefaultHistory = () => {
    setStillFetching(true);

    let coord = location?.pathname.substr(27).split(",");
    let lat = coord[1].substr(0, 5);
    let long = coord[0].substr(0, 5);
    let queryCoord = lat + "," + long;

    if (startDate.length > 0 && endDate.length > 0) {
      setFromDate(startDate);
      setToDate(endDate);
      setCentroid(queryCoord);
      setStillFetching(true);

      setDownloadBtnState({
        text: "Generate History File",
        canDownload: false,
      });
    } else {
      toast.warning("Please choose date range to load history within.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setStillFetching(false);
    }
  };

  const load5YearsHistory = () => {
    setStillFetching(true);
    async function fetchData() {
      let { isError, data } = await get5YearsHistoryChart(
        centroid,
        startDate,
        endDate
      );
      if (isError) {
        setStillFetching(false);
        toast.error(
          "It looks like we could not provide the weather data at the moment. Please kindly try again or ensure you select the right field or date range.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        setChartData(data);
        setStillFetching(false);
      }
    }
    fetchData();
  };

  const loadAverageHistory = () => {
    setStillFetching(true);
    async function fetchData() {
      let { isError, data } = await get5YrsAvgHistory(
        centroid,
        startDate,
        endDate
      );
      if (isError) {
        setStillFetching(false);
        toast.error(
          "It looks like we could not provide the weather data at the moment. Please kindly try again or ensure you select the right field or date range.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        setChartData(data);
        setStillFetching(false);
      }
    }
    fetchData();
  };

  const handleLoadHistory = () => {
    if (compareOption === "None") {
      setChartType(compareOption);
      loadDefaultHistory();
    } else if (compareOption === "5 Years") {
      if (getNoOfDaysDiff(endDate, startDate) > 365) {
        return toast.warning(
          "5 Years compare doesn't work for date range beyond a year",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setChartType(compareOption);
      load5YearsHistory();
    } else {
      if (getNoOfDaysDiff(endDate, startDate) > 365) {
        return toast.warning(
          "Average compare doesn't work for date range beyond a year",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setChartType(compareOption);
      loadAverageHistory();
    }
  };

  const handleDownload = () => {
    if (downloadBtnState.canDownload) {
      toast.success("Please wait...while it downloads on your device", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let coord = location?.pathname.substr(27).split(",");
      let lat = coord[1].substr(0, 5);
      let long = coord[0].substr(0, 5);
      let queryCoord = lat + "," + long;
      async function fetchData() {
        let { isError, data } = await downloadHistoryChart(
          queryCoord,
          startDate,
          endDate
        );
        if (isError) {
          toast.error(
            "It looks like we could not download the weather history at the moment. Please try again later.",
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else {
          setCsvData(data);
          toast.success(
            "Successfully generated file. Please kindly click the button again to download",
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setDownloadBtnState({
            text: "Proceed to Download Generated File",
            canDownload: true,
          });
        }
      }

      if (startDate?.length > 0 && endDate?.length > 0) {
        toast("Please wait...while we generate the file", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchData();
      } else {
        toast.warning("Please choose date range to download history within.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleCompare = (option) => {
    setCompareOption(option);
  };

  return (
    <>
      <DashboardContainer style={{ height: "fit-content" }}>
        <WeatherContainer>
          <WeatherLeftContainer></WeatherLeftContainer>
          <WeatherRightContainer style={{ width: "100%" }}>
            <HistoryCalendarCard>
              <HistoryCalendarCardInner>
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} xs={6} sm={6}>
                    <HistoryCalendarBox>
                      <HistoryCalendarTitle>
                        Starting From:
                      </HistoryCalendarTitle>
                      <HistoryCalendarPickDate
                        defaultValue={from}
                        type="date"
                        max={endDate}
                        onInput={(e) => setStartDate(e.target.value)}
                      />
                    </HistoryCalendarBox>
                  </Grid>
                  <Grid item lg={3} md={3} xs={6} sm={6}>
                    <HistoryCalendarBox>
                      <HistoryCalendarTitle>Ending To</HistoryCalendarTitle>
                      <HistoryCalendarPickDate
                        type="date"
                        defaultValue={to}
                        max={backDate(0)}
                        onInput={(e) => setEndDate(e.target.value)}
                      />
                    </HistoryCalendarBox>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12} sm={6}>
                    {/* <HistoryCalendarBox> */}
                    <HistoryCalendarTitle>Compare</HistoryCalendarTitle>
                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        // value={age}
                        defaultValue={compareOption}
                        variant="outlined"
                        label="Age"
                        style={{ height: "2.5em" }}
                        onChange={(e) => handleCompare(e.target.value)}
                      >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value={"5 Years"}>5 Years</MenuItem>
                        <MenuItem value={"Average"}>Average</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12} sm={6}>
                    <HistoryCalendarBox>
                      <HistoryBtn onClick={handleLoadHistory}>
                        Load History
                      </HistoryBtn>
                    </HistoryCalendarBox>
                  </Grid>
                </Grid>
              </HistoryCalendarCardInner>
            </HistoryCalendarCard>

            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <WeatherTitle>Weather History</WeatherTitle>
                {stillFetching ? (
                  <SkeletonCard height={"10vh"} />
                ) : (
                  <ForeCastCard></ForeCastCard>
                )}
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                style={{ marginTop: "2em" }}
              >
                <ForeCastTopCard>
                  <ForeCastTopCardText>Humidity History</ForeCastTopCardText>
                </ForeCastTopCard>

                <ForeCastBodyCard>
                  {stillFetching ? (
                    <SkeletonCard height={"50vh"} />
                  ) : (
                    <HistoryChartCard>
                      <HumidityChart
                        chartData={chartData}
                        type={chartType}
                        startYear={startYear}
                        endYear={endYear}
                      />
                    </HistoryChartCard>
                  )}
                </ForeCastBodyCard>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                style={{ marginTop: "2em" }}
              >
                <ForeCastTopCard>
                  <ForeCastTopCardText>
                    Precipitation History
                  </ForeCastTopCardText>
                </ForeCastTopCard>

                <ForeCastBodyCard>
                  {stillFetching ? (
                    <SkeletonCard height={"50vh"} />
                  ) : (
                    <HistoryChartCard
                      style={{ background: "rgba(1, 28, 20, 1)" }}
                    >
                      <PrecipChart
                        chartData={chartData}
                        type={chartType}
                        startYear={startYear}
                        endYear={endYear}
                      />
                    </HistoryChartCard>
                  )}
                </ForeCastBodyCard>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                style={{ marginTop: "2em" }}
              >
                <ForeCastTopCard>
                  <ForeCastTopCardText>Temperature History</ForeCastTopCardText>
                </ForeCastTopCard>

                <ForeCastBodyCard>
                  {stillFetching ? (
                    <SkeletonCard height={"50vh"} />
                  ) : (
                    <HistoryChartCard>
                      <TempChart
                        chartData={chartData}
                        type={chartType}
                        startYear={startYear}
                        endYear={endYear}
                      />
                    </HistoryChartCard>
                  )}
                </ForeCastBodyCard>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                style={{ marginTop: "2em" }}
              >
                <ForeCastTopCard>
                  <ForeCastTopCardText>Wind History</ForeCastTopCardText>
                </ForeCastTopCard>

                <ForeCastBodyCard>
                  {stillFetching ? (
                    <SkeletonCard height={"50vh"} />
                  ) : (
                    <HistoryChartCard>
                      <WindChart
                        chartData={chartData}
                        type={chartType}
                        startYear={startYear}
                        endYear={endYear}
                      />
                    </HistoryChartCard>
                  )}
                </ForeCastBodyCard>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                style={{ marginTop: "2em" }}
              >
                <ForeCastTopCard>
                  <ForeCastTopCardText>Average Rain fall</ForeCastTopCardText>
                </ForeCastTopCard>

                <ForeCastBodyCard>
                  {!rainfallData?.length ? (
                    <SkeletonCard height={"50vh"} />
                  ) : (
                    <HistoryChartCard
                      style={{ background: "rgba(1, 28, 20, 0)" }}
                    >
                      <ResponsiveContainer width={"100%"} height="100%">
                        <BarChart
                          data={rainfallData}
                          margin={{ top: 15, right: 30, bottom: 35 }}
                        >
                          <CartesianGrid strokeDasharray="1 1" />
                          <XAxis
                            angle={45}
                            textAnchor="start"
                            dataKey="month"
                            stroke="rgba(1, 28, 20, 1)"
                          />
                          <YAxis
                            unit=" mm/h"
                            type="number"
                            domain={["dataMin - 10", "dataMax + 10"]}
                            stroke="rgba(1, 28, 20, 1)"
                          />
                          <Tooltip
                            wrapperStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                            itemStyle={{ color: "rgba(1, 28, 20, 0.74)" }}
                          />
                          <Legend
                            verticalAlign="top"
                            formatter={() =>
                              renderColorfulLegendText(
                                "Average Rain fall - " + startYear + endYear,
                                "rgba(1, 28, 20, 1)"
                              )
                            }
                            height={50}
                          />
                          <Bar
                            name={"Average Rainfall"}
                            dataKey="avgRainfall"
                            fill="#82ca9d"
                            shape={<TriangleBar />}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </HistoryChartCard>
                  )}
                </ForeCastBodyCard>
              </Grid>
            </Grid>

            <Grid container spacing={0} style={{ marginBottom: "5em" }}>
              <Grid item lg={6} md={6} xs={12} sm={6}>
                <HistoryDownloadBox style={{ marginTop: "1em" }}>
                  <Link to={`/dashboard/weather/forecast/${urlCoords}`}>
                    <FarmInfoLink style={{ textDecoration: "underline" }}>
                      GO TO WEATHER FORECAST
                    </FarmInfoLink>
                  </Link>
                </HistoryDownloadBox>
              </Grid>
              <Grid item lg={2} md={2} xs={false} sm={false}></Grid>
              <Grid item lg={4} md={4} xs={6} sm={6}>
                <HistoryDownloadBox>
                  <HistoryBtn onClick={handleDownload}>
                    {downloadBtnState.canDownload ? (
                      <CSVLink
                        filename={
                          "Weather-History-From-" +
                          startDate +
                          "-To-" +
                          endDate +
                          ".csv"
                        }
                        data={csvData}
                        style={{ textDecoration: "none" }}
                      >
                        {downloadBtnState.text}
                      </CSVLink>
                    ) : (
                      <>{downloadBtnState?.text}</>
                    )}
                  </HistoryBtn>
                </HistoryDownloadBox>
              </Grid>
            </Grid>
          </WeatherRightContainer>
        </WeatherContainer>
      </DashboardContainer>
    </>
  );
};

export default HistoryChart;
