import React, { useState } from "react";
import AuthLayout from "./AuthLayout";
import { Row, Col, Input, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import SuccessMessage from "./SuccessMessage";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ResetEmailPassword } from "../../redux/actions/authentication";
import { toast } from "react-toastify";

const ForgotPassword = (props) => {
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  let { ResetEmailPassword } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const gotoHome = () => {
    history.push("/");
  };

  const styles = {
    marginTop: "4rem",
  };

  const resetPasswordLink = async (data) => {
    setloading(true);
    let res = await ResetEmailPassword(data);
    if (res.success) {
      setShowSuccess(true);
      toast("Email Reset Link Sent Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setloading(false);
      toast.error(res.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setloading(false);
  };
  return (
    <>
      <AuthLayout
        style={styles}
        fadeName="fade-left"
        pageTitle="Forgot Password"
      >
        {!showSuccess ? (
          <>
            <div className="auth-title">
              <h2>Recover Password</h2>
              <p>Enter your registered email address to reset your password</p>
            </div>
            <form onSubmit={handleSubmit(resetPasswordLink)} autoComplete="off">
              <Row gutter={24} style={{ marginTop: "4rem" }}>
                <Col xs={24} xl={24} lg={24}>
                  <div className="form-group">
                    <Input
                      placeholder="Email Address"
                      {...register("email", {
                        required: true,
                        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      })}
                    />
                    <small className="text-danger">
                      {errors.email && (
                        <span className="error">
                          Please, enter a valid email address
                        </span>
                      )}
                    </small>
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <Button
                    onClick={handleSubmit(resetPasswordLink)}
                    block
                    type="primary"
                    loading={loading}
                  >
                    {loading ? "Processing Request..." : "SEND LINK"}
                  </Button>
                </Col>
              </Row>
            </form>

            <Row>
              <Col xs={24} xl={24} lg={24}>
                <p className="register">
                  <Link to="/auth/login">
                    <ArrowRightAltIcon /> Return to Login
                  </Link>
                </p>
              </Col>
            </Row>
          </>
        ) : (
          <SuccessMessage
            description="Code has been sent to your mail, Check your inbox to proceed"
            successTitle="SUCCESSFULLY SENT"
            action={gotoHome}
          />
        )}
      </AuthLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_error: state.authReducer.auth_error,
    reset_email: state.authReducer.reset_email,
  };
};

export default connect(mapStateToProps, { ResetEmailPassword })(ForgotPassword);
