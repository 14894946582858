import React, { useState } from "react";
import {
  IconButton,
  Toolbar,
  AppBar,
  CssBaseline,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  ArrowDropDown,
  ExitToApp,
  People,
  Person,
  Home,
} from "@mui/icons-material";
import { Link, useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { ToastContainer, toast } from "react-toastify";
import { Alert, Button } from "antd";

const Header = (props) => {
  const { userdetails, LogoutUser } = props;
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = async () => {
    const res = await LogoutUser();
    if (res.success) {
      toast(`${res.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        history.push("/auth/login");
      }, 500);
    } else {
      toast.error("An unexpected error occurred. Kindly try again, please.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { is_staff, first_name, last_name } = userdetails || {};

  let userAvatar;
  if (first_name !== "" && last_name !== "") {
    // userAvatar = '';
    userAvatar = `${first_name?.[0]?.toUpperCase()}. ${last_name?.[0].toUpperCase()}`;
  } else {
    userAvatar = "";
  }
  // if (userdetails !== undefined) {
  //   userAvatar = `${first_name?.[0]?.toUpperCase()}. ${last_name?.[0].toUpperCase()}`;
  //   // userAvatar = ``;
  // }
  const onClose = (e) => {
    console.log(e, "I was closed.");
  };

  const gotoSubPage = () => {
    history.push("/subscription/plans");
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(props.classes.appBar, {
          [props.classes.appBarShift]: props.open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            className={clsx(props.classes.menuButton, {
              [props.classes.hide]: props.open,
            })}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        {userdetails?.owned_team?.current_subscription === null && (
          <div
            className="SubHeaderContainer"
            data-aos="fade-down"
            data-aos-delay="1000"
            data-aos-duration="800"
          >
            <Alert
              message="Want to enjoy all our services?"
              type="warning"
              closable
              onClose={onClose}
            />
            <Button onClick={gotoSubPage} className="sub-btn">
              Click Here to Subscribe{" "}
            </Button>
          </div>
        )}
        {userdetails?.owned_team === null &&
          userdetails?.user_team === null && (
            <div
              className="SubHeaderContainer"
              data-aos="fade-down"
              data-aos-delay="1000"
              data-aos-duration="800"
            >
              <Alert
                message="This Account does not have a team yet"
                type="error"
                closable
                onClose={onClose}
              />
              <Button className="sub-btn">Contact the administrator </Button>
            </div>
          )}
        {userdetails?.owned_team !== null &&
          userdetails?.owned_team?.current_subscription !== null && (
            <div
              className="SubHeaderContainer"
              data-aos="fade-down"
              data-aos-delay="1000"
              data-aos-duration="800"
            >
              <Alert
                message="Not satisfied with your current plan?"
                type="info"
                closable
                onClose={onClose}
              />
              <Button onClick={gotoSubPage} className="sub-btn">
                Click Here to Upgrade{" "}
              </Button>
            </div>
          )}
        <div className="profile" onClick={handleClick}>
          <div className="profile__avatar">
            <span>{userAvatar}</span>
          </div>
          <div className="profile__name">
            Welcome, <br />
            <strong>
              {first_name} {last_name}
            </strong>
          </div>
          <div className="profile__icon">
            <ArrowDropDown />
          </div>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {is_staff && (
            <MenuItem onClick={handleClose}>
              <Link to="/admin/dashboard/">
                <Home /> Admin
              </Link>
            </MenuItem>
          )}
          <MenuItem onClick={handleClose}>
            <Link to="/dashboard/">
              <Home /> Dashboard
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/dashboard/my-account">
              <Person /> My Profile
            </Link>
          </MenuItem>
          {userdetails?.owned_team === null &&
          userdetails?.user_team !== null ? null : (
            <MenuItem onClick={handleClose}>
              <Link to="/dashboard/manage-roles">
                <People /> Manage Role
              </Link>
            </MenuItem>
          )}
          {userdetails?.owned_team === null &&
          userdetails?.user_team !== null ? null : (
            <MenuItem onClick={handleClose}>
              <Link to="/dashboard/manage-user">
                <Person /> Manage User
              </Link>
            </MenuItem>
          )}
          <MenuItem onClick={handleClose}>
            <div className="logout" onClick={logout}>
              <ExitToApp /> <span>Logout</span>
            </div>
          </MenuItem>
        </Menu>
      </AppBar>
    </>
  );
};

export default Header;
