import styled, { keyframes } from "styled-components";

export const DashboardFooter = styled.footer`
  // height: 5em;
  width: 100%;
  // background: rgba(5, 5, 5, 0.9);
  bottom: 0;
  position: fixed;
  z-index: 1;
`;

export const MainContainer = styled.main`
  width: 100%;
  /* display: flex; */
  padding: 5rem 2rem;
  transition: ease all 0.5s;
  background: #e4efec;
  height: 100%;
`;

export const DashboardHeader = styled.div`
  /* height: 5em; */
  width: 100%;
  /* background: rgba(5, 5, 5, 0.9); */
  position: sticky;
  z-index: 1000;
  top: 3.5em;
  transition: ease all 0.5s;
`;

export const HeaderBelowOptionCard = styled.div`
  width: 10em;
  height: fit-content;
  background: white;
  float: right;
  margin-right: 2em;
  color: white;
  cursor: pointer;
  border-radius: 0em 0em 0.3em 0.3em;
  border: 1px solid rgba(255, 255, 255, 0.2);
  @media screen and (max-width: 760px) {
    margin-right: 1em;
    border-radius: 0em 0em 0.3em 0.3em;
  }
  transition: ease all 0.5s;
  transform: translateY(0);
  &:hover {
    transition: ease all 0.5s;
    transform: translateY(3px);
  }
`;

export const HeaderFieldCardTitle = styled.div`
  color: rgba(0, 106, 75, 0.1);
  text-align: center;
  margin-top: 1em;
  font-size: 0.7rem;
  line-height: 2em;
  height: 4em;
  border: none;
  background: rgb(255, 255, 255);
`;

export const HeaderTopCard = styled.section`
  /* height: 0.7em; */
  /* background: rgba(255, 255, 255, 1); */
  width: 100%;
`;

export const HeaderTopBlackCard = styled.section`
  height: 3.5em;
  /* background: rgba(5, 5, 5, 0.7); */
  background: #f7f7f7;
  width: 100%;
`;

export const HeaderBottomCard = styled.section`
  /* height: 5em; */
  width: 100%;
`;

export const HeaderBelowBottomCard = styled.section`
  height: fit-content;
  width: 100%;
  @media screen and (max-width: 769px) {
    margin-top: 3em;
  }
`;

export const HeaderOptionCard = styled.section`
  width: 3em;
  height: 3em;
  background: white;
  position: absolute;
  right: 3em;
  // top: 1.3em;
  top: 3em;
  margin-right: 1em;
  color: rgba(0, 106, 75, 0.7);
  text-align: center;
  cursor: pointer;
  border: 2px solid rgba(0, 106, 75, 0.3);
  transition: ease all 0.5s;
  transform: translateX(0);
  border-radius: 0.3em 0.3em 0.3em 0.3em;
  @media screen and (max-width: 769px) {
    top: 3.5em;
    right: 1em;
  }
  &:hover {
    transition: ease all 0.5s;
    transform: translateX(5px);
  }
`;

export const HeaderOptionContent = styled.div`
  width: 10em;
  height: 8em;
  background: white;
  border-radius: 0.5em;
`;

export const HeaderOptionItem = styled.div`
  width: 10em;
  height: 4em;
  cursor: pointer;
  // background:rgba(0, 106, 75, 0.05);
  border: 1px solid rgba(0, 106, 75, 0.1);
  &:hover {
    background: rgba(245, 245, 245, 1);
  }
`;

export const HeaderOptionEachItem = styled.p`
  margin-left: 1em;
  width: 80%;
  margin-top: 1em;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  font-size: 0.9em;
  color: rgba(0, 106, 75, 1);
`;

export const HeaderDropAnimate = keyframes`
from {
opacity:0;
height:0em;
}
to{
  opacity:1;
  height:20em;
}`;

export const HeaderDropDowBox = styled.div`
  height: 20em;
  overflow-y: scroll;
  width: 25em;
  animation-name: ${HeaderDropAnimate};
  animation-duration: 1s;
  &::-webkit-scrollbar {
    width: 4px;
    border-left: 1px solid rgba(0, 106, 75, 1);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 106, 75, 1);
  }
`;

export const HeaderDropDown = styled.div`
  width: 3em;
  height: 3em;
  background: red;
`;

export const HeaderFieldEachCard = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(0, 106, 75, 0.8);
  height: 10em;
  border-radius: 0.3em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const HeaderFieldTitle = styled.h6`
  font-size: 0.75em;
  margin-top: 0.5em;
  font-weight: bold;
  margin-left: 0.5em;
`;
export const HeaderFieldText = styled.p`
  margin-top: 0em;
  font-size: 0.75em;
  margin-left: 0.5em;
  margin-bottom: 0.3em;
  // border-bottom:1px solid rgba(255,255,255,0.4);
`;

export const HeaderAddIcon = styled.div`
  margin-bottom: 0.5em;
  margin-top: 0.7em;
`;

export const LayoutSideBar = styled.div`
  width: 90%;
  // background: #1d3354;
  z-index: 10;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  transition-delay: 0.3s;
  // height: 100vh;
  // overflow-x: scroll;
  margin-left: 0.9em;
  margin-top: 1em;
`;

export const LayoutUsername = styled.h6`
  /* text-align: left; */
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
`;

export const Avatar = styled.div`
  width: 50px;
  height: 50px;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  border-radius: 50px;
  margin-right: 10px;
  text-decoration: none;
`;

export const EachLayoutCard = styled.section`
  height: fit-content;
  // height:10vh;
`;

export const dropDownLayout = keyframes`
  from {
    opacity:0
  }
  to{
  opacity:1;
}`;

export const EachLayoutInnerCard = styled.div`
  //   background: white;
  width: 80%;
  //   color: #1d3354;
  color: rgba(255, 255, 255, 0.9);
  margin-left: 1.6em;
  animation-name: ${dropDownLayout};
  animation-duration: 1s;
  /* transform: translateY(0); */
  transition: ease all 0.5s;
`;

export const EachLayoutTitle = styled.h5`
  //   font-family: Avenir Next;
  //   font-style: normal;
  font-weight: bold;
  font-size: 0.75em;
  /* line-height: 2em; */
  color: rgba(0, 106, 75, 1);
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
`;

export const EachLayoutSubtitle = styled.h5`
  margin-left: 1em;
  // font-family: Avenir Next;
  // font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  line-height: 1.8em;
  cursor: pointer;
  color: rgba(1, 28, 20, 0.74);
  margin-bottom: 12px;
  transition: ease all 0.5s;
  transform: translateX(0);
  &:hover {
    transition: ease all 0.5s;
    transform: translateX(3px);
  }
`;

export const EachLayoutDSubtitle = styled.h5`
  margin-left: 1em;
  // font-family: Avenir Next;
  // font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  line-height: 1.8em;
  cursor: pointer;
  color: red;
  margin-bottom: 12px;
  transition: ease all 0.5s;
  transform: translateX(0);
  &:hover {
    transition: ease all 0.5s;
    transform: translateX(3px);
  }
`;

export const LayoutIcons = styled.span`
  margin-right: 0.75em;
  line-height: 4.5em;
  color: rgba(0, 106, 75, 1);
  color: rgba(1, 28, 20, 0.74);
`;

export const FooterTopCard = styled.section`
  // height: 5em;
  width: 100%;
`;

export const FooterBottomCard = styled.section`
  /* height: 1.5em; */
  /* background: rgba(5, 5, 5, 1); */
  /* width: 100%; */
`;

export const FooterOptionCard = styled.section`
  width: 10em;
  height: 5em;
  background: rgba(0, 106, 75, 1);
  float: right;
  // margin-left:85%;
  margin-right: 5em;
  color: white;
  text-align: center;
  cursor: pointer;
  border-radius: 0.3em 0em 0em 0em;
  // box-shadow: 5px 5px 15px rgba(255, 255, 255, 0.1),
  //   -5px -5px 15px rgba(255, 255, 255, 0.2);
  display: none;
  @media screen and (max-width: 769px) {
    margin-right: 4em;
    width: 9em;
  }
`;

export const FooterAddIcon = styled.div`
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  @media screen and (max-width: 769px) {
    margin-top: 0.3em;
  }
`;

export const FooterAddText = styled.h6`
  margin-top: 0.5em;
  font-size: 0.9em;
  font-weight: bold;
  @media screen and (max-width: 769px) {
    margin-top: 0.75em;
    margin-bottom: 0em;
    font-size: 0.8em;
  }
`;

export const DashboardContainer = styled.main`
  /* height: 98vh; */
  width: 100%;
  padding-top: 2rem;
  // background: rgba(230, 230, 230, 1);
  /* background: rgba(31, 40, 71, 0.05); */
  z-index: -10000;

  /* margin-top: 3rem; */
`;

export const ModalContainer = styled.div`
  // height:fit-content;
  background: #212930;
  overflow: scroll;
  width: 100%;
  font-family: Roboto, sans-serif;
  // -webkit-font-smoothing: antialiased;
  &::-webkit-scrollbar {
    width: 0.8em;
    border-left: 1px solid rgba(0, 106, 75, 1);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 106, 75, 1);
  }
  background: white;
  height: 28em;
  width: 30em;
  border: none;
  outline: none;
  border-radius: 0.3em;
  z-index: 10000000;
  @media screen and (max-width: 769px) {
    width: 100%;
    height: 80vh;
  }
`;

export const ModalTitle = styled.h5`
  color: #212930;
  text-align: center;
  margin-top: 0.3em;
  margin-bottom: 0.5em;
  font-weight: normal;
  font-family: Roboto, sans-serif;
`;

export const ModalCancel = styled.div`
  text-align: right;
  margin-top: 1em;
  margin-right: 1em;
  color: rgba(200, 0, 0, 0.8);
  cursor: pointer;
`;

// fields modal ----

export const FieldModalContainer = styled.div`
  width: 100%;
  height: 25em;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
`;

export const FieldModalTitle = styled.h5`
  color: #212930;
  text-align: center;
  margin-top: 0em;
  margin-bottom: 0.5em;
  font-weight: bold;
  font-family: Roboto, sans-serif;
`;

export const FieldModalCard = styled.div`
  height: fit-content;
  width: 100%;
  // text-align:center;
  // background:rgba(230,230,230,1);
  // border-bottom : 1px solid rgba(5, 5, 5, 0.1);
  border-top: 1px solid rgba(5, 5, 5, 0.3);
  margin-bottom: 1em;
  @media screen and (max-width: 769px) {
    // height: 5em;
    // width:17em;
  }
`;

export const FieldEachModalCard = styled.div`
  height: fit-content;
  width: 100%;
  // text-align:center;
  // background:rgba(230,230,230,1);
  // border-bottom : 1px solid rgba(5, 5, 5, 0.1);
  border-top: 1px solid rgba(5, 5, 5, 0.3);
  margin-bottom: 1em;
  @media screen and (max-width: 769px) {
    // height: 5em;
    // width:17em;
  }
  transition: 0.5s background;
  &:hover {
    background: rgba(5, 5, 5, 0.1);
  }
`;

export const FieldModalInnerCard = styled.div`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  cursor: pointer;
`;

export const FieldModalCardLeft = styled.div`
  width: 40%;
  @media screen and (max-wdith: 769px) {
    width: 30%;
  }
`;

export const FieldModalCardRight = styled.div`
  width: 60%;
`;

export const FieldModalCardInner = styled.div`
  width: 90%;
  margin-left: 1em;
  height: 5.5em;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  @media screen and (max-width: 769px) {
    margin-top: 0.5em;
    height: 6em;
  }
`;

export const FieldModalCardImg = styled.div`
  width: 90%;
  height: 5em;
  margin-top: 1.5em;
  // opacity: 0.9;
  @media screen and (max-width: 769px) {
    height: 7;
    margin-top: 1em;
  }
`;

export const FieldModalSearchInput = styled.input`
  width: 100%;
  height: 3em;
  border-radius: 0.3em;
  // margin-bottom: 1em;
  margin-top: 0.5em;
  border: 1px solid rgba(5, 5, 5, 0.3);
  background: rgba(5, 5, 5, 0.005);
  outline: none;
  // padding: 1.7em 0.3em;
  // margin: 8px 0;
  // box-sizing: border-box;
  color: rgba(5, 5, 5, 1);
  &::-webkit-input-placeholder {
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: rgba(5, 5, 5, 0.5);
  }
`;

export const FieldCardName = styled.h6`
  color: rgba(0, 106, 75, 1);
  font-weight: bold;
  font-size: 0.9em;
`;

export const FieldCardText = styled.p`
  color: rgba(5, 5, 5, 0.9);
  // font-weight:bold;
  font-size: 0.9em;
`;

export const FieldCardType = styled.h6`
  color: rgba(5, 5, 5, 0.8);
  font-size: 0.8em;
  margin-top: -0.5em;
`;

export const FieldButton = styled.div`
  border: none;
  height: 2.5em;
  color: #05674a;
  border-radius: 4px;
  font-size: 0.8em;
  margin-top: 0.5em;
  text-decoration: underline rgba(5, 5, 5, 0.9);
`;

export const FieldToggleBox = styled.div`
  height: 3em;
  width: 3em;
  float: right;
  // margin-right: -3.5em;
  margin-top: 1em;
  padding: 0.5em 0.5em;
  display: flex;
  align-self: center;
  align-items: center;
  text-align: center;
  background: white;
  color: #05674a;
  border: 1px solid #05674a;
  border-radius: 50%;
  transition: 0.5s background, 0.5s color;
  &:hover {
    color: white;
    background: #05674a;
    border: 1px solid #05674a;
  }
`;

export const FieldToggleBoxItem = styled.h3`
  font-size: 0.9em;
  height: 2em;
  width: 3em;
`;
// Geomap

export const GeoMapButtonBox = styled.div`
  // height: 22em;
  // width: 3.5em;
  width: 22em;
  height: 3.5em;
  background: white;
  border-radius: 0.5em;
  position: fixed;
  // margin-top: -0.2em;
  // margin-top: 15em;
  bottom: 8em;
  z-index: 1000;
  background: white;
  left: 0;
  margin-left: 6em;
  border: 1px solid rgba(1, 28, 20, 0.3);
  @media screen and (max-width: 769px) {
    margin-right: 4em;
    margin-top: 15em;
  }
  display: none;
`;

export const GeoMapRange = styled.input`
  // transform: rotate(270deg);
  // right:8.3em;
  position: relative;
  // margin-top:10.5em;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: auto;
  width: 20em;
`;

export const GeoMapSwitchBox = styled.div`
  height: 5;
  width: fit-content;
  text-align: center;
  background: white;
  border-radius: 0.5em;
  position: fixed;
  margin-top: 10em;
  z-index: 1000000;
  right: 0;
  margin-right: 10em;
  @media screen and (max-width: 769px) {
    margin-right: 1em;
    margin-top: 15em;
  }
  display: none;
`;

// --- Start the styles for cloud cover ----
export const CoverNotFound = styled.div`
  width: 3.5em;
  height: 3.5em;
  background: white;
  border-radius: 0.5em;
  position: absolute;
  bottom: 3.5em;
  z-index: 1000;
  background: white;
  left: 2em;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  color: red;
  border: 1px solid rgba(1, 28, 20, 0.3);
  // @media screen and (max-width: 769px) {
  //   margin-right: 4em;
  //   margin-top: 15em;
  // }
`;

export const CoverFound = styled.div`
  width: 16em;
  height: fit-content;
  background: rgba(1, 28, 20, 0.3);
  padding: 0.5em 0.5em;
  position: absolute;
  bottom: 2.5em;
  z-index: 1000;
  left: 2em;
  color: white;
  font-weight: bold;
`;

export const CoverDetectRange = styled.input`
  width: 100%;
  margin-top: 0.5em;
`;

export const Cover2NotFound = styled.div`
  background: white;
  color: red;
  width: 3.5em;
  height: 3.5em;
  bottom: 2em;
  border-radius: 0.5em;
  z-index: 1000;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  position: absolute;
  padding: 0.5em 0.5em;
  left: 2em;
`;

export const Cover2Found = styled.div`
  width: 15em;
  height: fit-content;
  background: rgba(1, 28, 20, 0.3);
  padding: 0.5em 0.5em;
  position: absolute;
  bottom: 1em;
  z-index: 1000;
  left: 2em;
  color: white;
  font-weight: bold;
`;
// --- Legend Styles ---

export const LegendCard = styled.section`
  // width: fit-content;
  // height: fit-content;
  // min-height: 5em;
  // background: white;
  // position: fixed;
  // padding: 0.5em 0.5em;
  // bottom: 8em;
  // z-index: 1000;
  // // background:rgba(1, 28, 20, 1);
  // background: white;
  // border-radius:0.3em;
  // color: rgba(1, 28, 20, 1);
  // right: 0;
  // margin-right: 3em;
  // border: 1px solid rgba(1, 28, 20, 0.3);
  width: fit-content;
  height: fit-content;
  min-height: 5em;
  background: white;
  position: absolute;
  padding: 0.5em 0.5em;
  bottom: 3em;
  z-index: 1000;
  // background:rgba(1, 28, 20, 1);
  background: white;
  border-radius: 0.3em;
  color: rgba(1, 28, 20, 1);
  right: 0;
  margin-right: 3em;
  border: 1px solid rgba(1, 28, 20, 0.3);
  @media screen and (max-width: 768px) {
    bottom: 5em;
  }
`;

// Edit Modal

export const EditModalBox = styled.div`
  background: #212930;
  // width: 100%;
  font-family: Roboto, sans-serif;
  z-index: 10000000000000000000000;
  // background: white;
  min-height: 30em;
  height: fit-content;
  width: 60%;
  border: none;
  outline: none;
  border-radius: 0.5em;
  display: flex;
  @media screen and (max-width: 769px) {
    width: 90%;
    height: 80vh;
  }
`;

export const EditModalBoxLeft = styled.section`
  width: 100%;
  height: 30em;
  background: white;
  @media screen and (max-width: 768px) {
    height: 25vh;
  }
`;

export const EditModalBoxRight = styled.section`
  width: 100%;
  height: 28em;
  @media screen and (max-width: 768px) {
    height: 45vh;
  }
`;

export const EditModalInner = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  @media screen and (max-width: 769px) {
    margin-top: 0em;
  }
`;

export const EditModalBoxText = styled.h3`
  color: white;
  font-size: 1.1em;
  text-align: center;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    margin-top: -1em;
  }
`;

export const EditModalInputText = styled.label`
  color: white;
  // font-size: 1.1em;
  // font-weight: bold;
  margin-top: 0.5em;
`;

export const EditModalInput = styled.input`
  width: 100%;
  height: 3em;
  border-radius: 0.3em;
  margin-bottom: 1em;
  margin-top: 0.5em;
  border: 1px solid rgba(255, 255, 255, 0);
  background: rgba(5, 5, 5, 0.5);
  outline: none;
  padding: 1.7em 0.4em;
  margin: 8px 0;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 1);
  font-size: 0.9em;
  &::-webkit-input-placeholder {
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: rgba(255, 255, 255, 1);
  }
`;

export const EditModalSel = styled.select`
  width: 100%;
  height: 3.5em;
  border-radius: 0.3em;
  margin-bottom: 1em;
  margin-top: 0.5em;
  border: 1px solid rgba(255, 255, 255, 0);
  background: rgba(5, 5, 5, 0.5);
  outline: none;
  // padding: 1.7em 0.4em;
  margin: 8px 0;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 1);
  font-size: 0.9em;
  &::-webkit-input-placeholder {
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: rgba(255, 255, 255, 1);
  }
`;

export const EditModalButton = styled.button`
  width: 100%;
  height: 3em;
  background: #212930;
  border: none;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0);
  background: rgba(5, 5, 5, 0.5);
  outline: none;
  background: rgba(0, 106, 75, 1);
  font-weight: bold;
  color: white;
  border-radius: 0.3em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: 0.5s color, 0.5s background;
  &:hover {
    background: rgba(255, 255, 255, 1);
    color: rgba(0, 106, 75, 1);
  }
`;

export const EditAddSeasonBox = styled.div`
  margin-top: 0em;
`;

export const EditAddSeasonIcon = styled.h6`
  font-size: 0.9em;
  font-weight: normal;
  cursor: pointer;
  color: white;
`;

// --- Styles to handle disable mobile view ---

export const MobileOnlyView = styled.div`
  display: none;
  width: 100%;
  background: white;
  // margin-left:3em;
  height: fit-content;
  @media screen and (max-width: 769px) {
    display: block;
  }
`;

export const WarningBox = styled.div`
  width: fit-content;
  text-align: center;
  height: fit-content;
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
`;

export const WarningImage = styled.img`
  width: 20em;
  height: 20em;
`;

export const WarningText = styled.h6`
  // width:90%;
  margin-left: auto;
  margin-right: auto;
  // line-height:2em;
  font-style: normal;
  font-weight: bold;
  color: rgba(5, 5, 5, 0.9);
  color: rgba(200, 0, 0, 0.9);
`;
