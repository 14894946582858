import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  ConfigurationIcon,
  DashboardIcon,
  InventoryIcon,
  LogoutIcon,
  PaymentIcon,
  ProductsIcon,
  RawMaterialsIcon,
  SalesIcon,
  TrackingIcon,
  UsersIcon,
} from "../../../utils/AdminIcons";
import { Cancel } from "@mui/icons-material";
import { connect } from "react-redux";
import { AdminActionLogout } from "../../../redux/actions/adminActions";
import { toast } from "react-toastify";
// import {ValueIcon} from "../../../utils/Icons";

const AgentSidebar = (props) => {
  const {
    isAdminOpen,
    closeDrawer,
    adminDrawerRef,
    AdminActionLogout,
    admindetails,
  } = props;
  const history = useHistory();
  // const gotoProfile = () => {
  //     history.push('/admin/account');
  // };
  const { location } = history;
  const { pathname } = location;

  const logoutAdmin = async () => {
    const res = await AdminActionLogout();
    if (res.success) {
      toast.success(res.message, {
        pauseOnHover: false,
      });
    }
    history.push("/auth/login");
  };
  return (
    <aside
      className={`aside ${isAdminOpen ? "open" : ""}`}
      ref={adminDrawerRef}
    >
      <div
        onClick={closeDrawer}
        className="closedrawer"
        data-aos="fade-left"
        data-aos-delay="1500"
      >
        <Cancel />
      </div>
      <div className="profile">
        <div className="profile__avatar admin">
          <span>{admindetails?.first_name?.[0] || "T"}</span>
        </div>
        <h4>
          {admindetails?.last_name || "Tester Admin"} {admindetails?.first_name}
        </h4>
        <p>Admin</p>
        {/*<Button className="admin" onClick={gotoProfile} block>*/}
        {/*    View Profile*/}
        {/*</Button>*/}
      </div>
      <div className="sidemenu">
        <ul>
          <li className={pathname === "/admin/dashboard" ? "activelink" : ""}>
            <Link to="/admin/dashboard">
              <DashboardIcon />
              <span>Dashboard</span>
            </Link>
          </li>
          <li
            className={pathname === "/admin/configuration" ? "activelink" : ""}
          >
            <Link to="/admin/configuration">
              <ConfigurationIcon />
              <span>Configurations</span>
            </Link>
          </li>
          <li className={pathname === "/admin/products" ? "activelink" : ""}>
            <Link to="/admin/products">
              <ProductsIcon />
              <span>Products</span>
            </Link>
          </li>
          <li
            className={pathname === "/admin/raw-materials" ? "activelink" : ""}
          >
            <Link to="/admin/raw-materials">
              <RawMaterialsIcon />
              <span>Raw Materials</span>
            </Link>
          </li>
          <li className={pathname === "/admin/inventory" ? "activelink" : ""}>
            <Link to="/admin/inventory">
              <InventoryIcon />
              <span>Inventory</span>
            </Link>
          </li>
          <li className={pathname === "/admin/sales" ? "activelink" : ""}>
            <Link to="/admin/sales">
              <SalesIcon />
              <span>Sales</span>
            </Link>
          </li>
          {/*<li className={pathname === '/admin/services/configured' ? 'activelink' : ''}>*/}
          {/*    <Link to="/admin/services">*/}
          {/*        <ValueIcon />*/}
          {/*        <span>Configured Services</span>*/}
          {/*    </Link>*/}
          {/*</li>*/}
          <li
            className={pathname === "/admin/manage-users" ? "activelink" : ""}
          >
            <Link to="/admin/manage-users">
              <UsersIcon />
              <span>Users</span>
            </Link>
          </li>
          <li className={pathname === "/admin/all-teams" ? "activelink" : ""}>
            <Link to="/admin/all-teams">
              <UsersIcon />
              <span>Team Mgt</span>
            </Link>
          </li>
          <li
            className={
              pathname === "/admin/account-subscription" ? "activelink" : ""
            }
          >
            <Link to="/admin/account-subscription">
              <TrackingIcon />
              <span>Subscriptions</span>
            </Link>
          </li>
          <li
            className={pathname === "/admin/all-payments" ? "activelink" : ""}
          >
            <Link to="/admin/all-payments">
              <PaymentIcon />
              <span>Payments</span>
            </Link>
          </li>
          <li className={pathname === "/admin/tracking" ? "activelink" : ""}>
            <Link to="/admin/tracking">
              <TrackingIcon />
              <span>Tracking</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="lowermenu">
        <ul>
          <li onClick={logoutAdmin}>
            <LogoutIcon />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </aside>
  );
};

const mapStateToProps = (state) => {
  return {
    admindetails: state.authReducer.user_details,
  };
};

export default connect(mapStateToProps, { AdminActionLogout })(AgentSidebar);
