import React, { useEffect, useState } from "react";
import { ServiceSection } from "../../AdminStyle.styled";
import { Row, Col, Button, Table, Modal, Tag } from "antd";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  CreateRawMaterial,
  UpdateRawMaterial,
  DeleteRawMaterial,
  GetAllRawMaterials,
  GetOneConfiguredServices,
} from "../../../../../redux/actions/adminActions";
import { Delete, Edit, Save, Add } from "@mui/icons-material";
import CreateRawMaterialModal from "./CreateRawMaterialsModal";
import { formatDateObjectHandler } from "../../../../../utils";

const RawMaterials = (props) => {
  const {
    CreateRawMaterial,
    UpdateRawMaterial,
    allRawMaterials,
    GetAllRawMaterials,
    DeleteRawMaterial,
  } = props;

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formMode, setFormMode] = useState("CREATE_MODE");
  const [rawMaterialRecord, setRawMaterialRecord] = useState(null);

  const editRecord = async (record) => {
    setRawMaterialRecord(record);
    setVisible(true);
    setFormMode("UPDATE_MODE");
  };

  const deleteRecord = (record) => {
    Modal.confirm({
      title: "Do you want to delete this record?",
      content: "Click yes to proceed",
      okText: "Delete",
      loading: deleteLoading,
      okType: "danger",
      async onOk() {
        setDeleteLoading(true);
        const res = await DeleteRawMaterial(record.id);
        if (res.success) {
          setDeleteLoading(false);
          toast.success(res.message, {
            pauseOnHover: false,
          });
        } else {
          toast.info(res.message, {
            pauseOnHover: true,
          });
        }
      },
      onCancel() {
        setDeleteLoading(false);
        return false;
      },
    });
  };
  const CreateService = () => {
    setVisible(true);
    setFormMode("CREATE_MODE");
    setRawMaterialRecord(null);
  };

  const closeModal = () => {
    setVisible(false);
    setFormMode("CREATE_MODE");
    setRawMaterialRecord(null);
  };

  const recordColumn = [
    {
      title: "Raw Material ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      align: "center",
      render: (_text, record) => (
        <>
          {formatDateObjectHandler(record.date_created, "Do MMM YYYY")} <br />
        </>
      ),
    },
    {
      title: "action",
      dataIndex: "action",
      align: "center",
      render: (_text, record) => (
        <>
          <div className="actiontable">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <button className="eid" onClick={() => editRecord(record)}>
              <Edit />
            </button>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <button className="del" onClick={() => deleteRecord(record)}>
              <Delete />
            </button>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    const getRawMaterialsRecords = async () => {
      setLoading(true);
      const res = await GetAllRawMaterials();
      setLoading(false);
      if (!res.success) {
        setLoading(false);
        toast.error(`Error`);
      }
    };
    getRawMaterialsRecords();
  }, [GetAllRawMaterials]);
  return (
    <ServiceSection>
      <Row>
        <Col xs={24}>
          <div style={{ textAlign: "right", marginBottom: "15px" }}>
            <Button
              onClick={CreateService}
              icon={<Add style={{ marginRight: "8px" }} />}
              type="primary"
            >
              {" "}
              Create Raw Material
            </Button>
          </div>
        </Col>
      </Row>

      <Table
        rowKey="id"
        style={{ overflowX: "auto" }}
        loading={loading}
        columns={recordColumn}
        dataSource={allRawMaterials}
      />

      <CreateRawMaterialModal
        visible={visible}
        closeModal={closeModal}
        formMode={formMode}
        one_raw_material={rawMaterialRecord}
        CreateRawMaterial={CreateRawMaterial}
        UpdateRawMaterial={UpdateRawMaterial}
      />
    </ServiceSection>
  );
};

const mapStateToProps = (state) => {
  return {
    allRawMaterials: state.adminReducer.allRawMaterials,
    one_service: state.adminReducer.one_service,
  };
};

export default connect(mapStateToProps, {
  CreateRawMaterial,
  UpdateRawMaterial,
  GetAllRawMaterials,
  DeleteRawMaterial,
  GetOneConfiguredServices,
})(RawMaterials);
