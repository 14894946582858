import React from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";

import {
  BodyFirstSection,
  FirstSectionText,
  FirstSectionTitle,
  MainBody,
  SecondSection,
  ThirdSection,
  ThirdSectionCard,
  ThirdSectionCardInner,
  ThirdSectionInner,
  ThirdWhiteCardImg,
  TryItCard,
  FourthSection,
  CompaniesSection,
  FourthSectionCard,
  FourthSectionCount,
  FourthSectionName,
  FourthSectionText,
  FifthSection,
  FifthSectionTitle,
  FifthSectionImage,
  SixthSection,
  FirstSectionGroup,
  SecondSectionTitle,
  SecondSectionText,
  SecondSectionButton,
  SixthSectionButton,
  SixthSectionNews,
  SixthSectionForm,
  SixthSectionFormdiv,
  SixthSectionButtonNews,
} from "../../styles/home/BodyStyles";
import { Grid } from "@mui/material";

import secondField from "../../assets/img/home/main/Secondsection.png";
import secondFieldBg from "../../assets/img/home/main/Secondsectionbg.png";
import farm from "../../assets/img/home/main/farm.jpg";
import satelliteImagary from "../../assets/img/home/main/80cm.jpg";
import cropMonitoring from "../../assets/img/home/main/crop.png";
import weather from "../../assets/img/home/main/weather.png";
import bro from "../../assets/img/home/main/bro.png";
import news from "../../assets/img/home/main/news.png";
import mapImage from "../../assets/img/home/main/map.png";
import group from "../../assets/img/home/main/agroo.png";
import global from "../../assets/img/home/main/globe.png";
import Objects from "../../assets/img/home/main/Objects.png";
import emojione from "../../assets/img/home/main/emojione-v1_satellite.png";
import SimpleModal from "./Modal";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
  },
}));

const HomeBody = () => {
  AOS.init();

  const classes = useStyles();
  return (
    <>
      <MainBody>
        <BodyFirstSection
          id="home"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos="fade-up"
        >
          <Grid container spacing={2}>
            <Grid item lg={4}>
              <FirstSectionTitle>
                Agroprever: Protecting Nigerian Farmers, One Solution at a Time.
              </FirstSectionTitle>
              <FirstSectionText>
                Get the best geospatial solutions for your business with a wide
                range of industry coverage and proficiency
              </FirstSectionText>
              <SimpleModal />
            </Grid>
            <Grid item lg={8}>
              <FirstSectionGroup>
                <img src={group} alt="group" width="100%" />
              </FirstSectionGroup>
            </Grid>
          </Grid>
        </BodyFirstSection>

        <SecondSection id="aboutus">
          <Grid container spacing={0}>
            <Grid
              item
              lg={6}
              xs={12}
              md={12}
              sm={12}
              style={{ display: "absolute" }}
            >
              <img
                src={secondField}
                alt="secondfield"
                style={{ zIndex: 2000 }}
                width="100%"
              />
              {/* <img
                src={secondFieldBg}
                alt="secondfieldbg"
                style={{ marginLeft: "-15%", zIndex: -2000 }}
              /> */}
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
              md={12}
              sm={12}
              style={{ margin: "auto" }}
            >
              <SecondSectionTitle>
                Explore The Best Geospatial Solutions For Your Farm
              </SecondSectionTitle>
              <SecondSectionText>
                Unearth solutions to various industries all over the globe
              </SecondSectionText>
              <SecondSectionButton>Learn More</SecondSectionButton>
            </Grid>
          </Grid>
        </SecondSection>

        <ThirdSection id="products">
          <ThirdSectionInner>
            <h5 style={{ color: "#C4FCEC", fontSize: "25px" }}>OUR PRODUCTS</h5>
            <Grid container spacing={0}>
              <Grid item lg={6} md={6} xs={12} sm={6}>
                <ThirdSectionCard
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos="zoom-out-right"
                >
                  <ThirdSectionCardInner>
                    <ThirdWhiteCardImg src={cropMonitoring} />
                    <TryItCard>Crop Plan Development</TryItCard>
                  </ThirdSectionCardInner>
                </ThirdSectionCard>
              </Grid>

              <Grid item lg={6} md={6} xs={12} sm={6}>
                <ThirdSectionCard
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos="zoom-in"
                >
                  <ThirdSectionCardInner>
                    <ThirdWhiteCardImg src={satelliteImagary} />
                    <TryItCard>Satellite Monitoring</TryItCard>
                  </ThirdSectionCardInner>
                </ThirdSectionCard>
              </Grid>

              <Grid item lg={6} md={6} xs={12} sm={6}>
                <ThirdSectionCard
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos="zoom-out-right"
                >
                  <ThirdSectionCardInner>
                    <ThirdWhiteCardImg src={farm} />
                    <TryItCard>Farm Investment</TryItCard>
                  </ThirdSectionCardInner>
                </ThirdSectionCard>
              </Grid>
              <Grid item lg={6} md={6} xs={12} sm={6}>
                <ThirdSectionCard
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos="zoom-out-right"
                >
                  <ThirdSectionCardInner>
                    <ThirdWhiteCardImg src={weather} />
                    <TryItCard>Weather Monitoring</TryItCard>
                  </ThirdSectionCardInner>
                </ThirdSectionCard>
              </Grid>
            </Grid>
          </ThirdSectionInner>
        </ThirdSection>

        <FifthSection style={{ backgroundImage: `url(${mapImage})` }}>
          <FifthSectionTitle>OUR TECHNOLOGY</FifthSectionTitle>
          <FifthSectionImage
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            data-aos="zoom-out-right"
          />
          <section style={{ padding: "2% 20%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ padding: "0 2%" }}>
                <img src={global} alt="global" />
              </div>
              <div style={{ padding: "0 2%", margin: "auto" }}>
                <h3
                  style={{
                    color: "white",
                    fontWeight: 900,
                    fontSize: "20px",
                    lineHeight: "26px",
                  }}
                >
                  FARM MANAGEMENT
                </h3>
                <SecondSectionText>
                  With our advanced management system, you can manage all your
                  farms at once, invite other users and farmers with a
                  customized access level, manage your crops, and keep track of
                  your farm size, location, and conditions.
                </SecondSectionText>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ padding: "0 2%", margin: "auto 0 auto 20%" }}>
                <h3
                  style={{
                    color: "white",
                    fontWeight: 900,
                    fontSize: "20px",
                    lineHeight: "26px",
                  }}
                >
                  FARM FINANCIAL ANALYSIS
                </h3>
                <SecondSectionText>
                  Farmlands provide a truly diversified return of investment.
                  With an farm conditions monitoring and investment evaluation
                  reports, we support financial houses and agricultural loan
                  providers make wiser decisions on investing on your farm land.
                </SecondSectionText>
              </div>
              <div style={{ padding: "0 2%" }}>
                <img src={Objects} alt="global" />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ padding: "0 2%" }}>
                <img src={emojione} alt="global" />
              </div>
              <div style={{ padding: "0 2%", margin: "auto" }}>
                <h3
                  style={{
                    color: "white",
                    fontWeight: 900,
                    fontSize: "20px",
                    lineHeight: "26px",
                  }}
                >
                  FARM MONITORING
                </h3>
                <SecondSectionText>
                  Take advantage of our satellite monitoring, soil conditions
                  reports, moisture reports and weather forecasts to know the
                  conditions of your farm at any time and from any place.
                </SecondSectionText>
              </div>
            </div>
          </section>
        </FifthSection>

        <FourthSection>
          <FifthSectionTitle>OUR STATISTICS</FifthSectionTitle>
          <CompaniesSection style={{ marginTop: "2em" }}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} xs={12} sm={4}>
                <FourthSectionCard
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos="zoom-out-right"
                >
                  <FourthSectionCount>500+</FourthSectionCount>
                  <FourthSectionName>Customers</FourthSectionName>
                  <FourthSectionText>
                    Analyzing their AOI with our platform and relaying on our
                    farm management features
                  </FourthSectionText>
                </FourthSectionCard>
              </Grid>

              <Grid item lg={4} md={4} xs={12} sm={4}>
                <FourthSectionCard
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos="zoom-in"
                >
                  <FourthSectionCount>30000+</FourthSectionCount>
                  <FourthSectionName>Reports</FourthSectionName>
                  <FourthSectionText>
                    Being exported weekly based on tailored AOI vegetative
                    conditions and weather conditions
                  </FourthSectionText>
                </FourthSectionCard>
              </Grid>

              <Grid item lg={4} md={4} xs={12} sm={4}>
                <FourthSectionCard
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos="zoom-out-right"
                >
                  <FourthSectionCount>10+</FourthSectionCount>
                  <FourthSectionName>Years</FourthSectionName>
                  <FourthSectionText>
                    Record of archive AOI records and 5+ years of historical
                    weather archive data
                  </FourthSectionText>
                </FourthSectionCard>
              </Grid>
            </Grid>
          </CompaniesSection>
        </FourthSection>

        <SixthSection id="contactus">
          <Grid container spacing={0}>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <img src={bro} alt="secondfield" />
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12} style={{ margin: "auto" }}>
              <SecondSectionTitle>Talk to our EXPERTS</SecondSectionTitle>
              <SecondSectionText>
                We’re here to help! Don’t hesitate to ask any questions or
                schedule a demo.
              </SecondSectionText>
              <SixthSectionButton>TALK TO EXPERTS</SixthSectionButton>
            </Grid>
          </Grid>
          <section style={{ marginTop: "10%" }}>
            <SixthSectionNews>
              <Grid container spacing={0}>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  md={12}
                  sm={12}
                  style={{ margin: "auto" }}
                >
                  <SecondSectionTitle>Get our updates</SecondSectionTitle>
                  <SecondSectionText>
                    Follow realtime news, updates, and innovations <br /> from
                    us that can help your business
                  </SecondSectionText>
                  <SixthSectionFormdiv>
                    <SixthSectionForm type="text" placeholder="Enter Email" />
                    <SixthSectionButtonNews>Subscribe</SixthSectionButtonNews>
                  </SixthSectionFormdiv>
                </Grid>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  md={6}
                  sm={12}
                  style={{ textAlign: "right" }}
                >
                  <img src={news} alt="secondfield" />
                </Grid>
              </Grid>
            </SixthSectionNews>
          </section>
        </SixthSection>
      </MainBody>
    </>
  );
};

export default HomeBody;
