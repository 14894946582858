import React, { useState } from "react";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { toast, ToastContainer } from "react-toastify";
import { CSVLink } from "react-csv";

import { DownloadBox } from "../../styles/satelliteField/SatelliteFieldStyle";
import getIndexData from "../../adapters/map/getIndexData";

import { pureArrayForCsv, wmsKey } from "../util/Util";

// Download button to download chart data
const DownloadButton = (props) => {
  const [data, setData] = useState([]);
  const [canDownload, setCanDownload] = useState(false);
  const [stillFetching, setStillFetching] = useState(false);

  const handleDownload = () => {
    setCanDownload(false);

    if (stillFetching) {
      return toast.warning("Please wait....a current request is still on", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // check if the coords is not empty and a layer has been selected
    if (props?.selectedRealCoords?.length > 0 && props?.layer !== null) {
      setStillFetching(true);
      props?.setIsMapLoading(true);

      toast("Please wait....", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // query the FIS for the chart data
      let geometry = `POLYGON ((${props?.selectedRealCoords
        .map((coord) => coord.join(" "))
        .join(", ")}))`;

      let fisUrl = `https://services.sentinel-hub.com/ogc/fis/${wmsKey}?LAYER=${props?.layer}&CRS=CRS:84&TIME=${props?.startDate}/${props?.endDate}&MAXCC=${props?.cloudCover}&WIDTH=512&HEIGHT=512&GEOMETRY=${geometry}`;

      let getRespChart = getIndexData(fisUrl);

      getRespChart.then(({ isError, data }) => {
        if (isError) {
          toast.error("No download data for the options passed", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setStillFetching(false);
          props?.setIsMapLoading(false);
        } else {
          if (Object.keys(data).length > 0) {
            let pureData = pureArrayForCsv(data["C0"]);
            // let csvData = arrayToCsv(pureData);

            setData(pureData);
            setCanDownload(true);
            setStillFetching(false);
            props?.setIsMapLoading(false);

            toast.success(
              "CSV generated successfully. Kindly click again to download the file.",
              {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          } else {
            setStillFetching(false);
            props?.setIsMapLoading(false);

            toast.error(
              "No chart data for the index selected within the date range",
              {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        }
      });
    } else {
      toast.warning(
        "Please select a layer and ensure a shape has been selected too.",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const headers = [
    { label: "date", key: "date" },
    { label: "min", key: "min" },
    { label: "max", key: "max" },
    { label: "mean", key: "mean" },
    { label: "stDev", key: "stDev" },
  ];

  const csvReport = {
    data: data,
    headers: headers,
    filename: `${props?.layer}_index_data_${props?.startDate}_to_${props?.endDate}.csv`,
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {canDownload ? (
        <CSVLink {...csvReport}>
          <DownloadBox onClick={() => setCanDownload(false)}>
            <AttachFileIcon
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
          </DownloadBox>
        </CSVLink>
      ) : (
        <DownloadBox onClick={handleDownload}>
          <CloudDownloadIcon
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />
        </DownloadBox>
      )}
    </>
  );
};

export default DownloadButton;
