import React, { useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import { Tabs } from "antd";
import { ConfigurationContainer } from "./AdminStyle.styled";
import RawMaterials from "./config/Inventory/RawMaterials/RawMaterials";
import Produces from "./config/Inventory/Produces/Produces";

const { TabPane } = Tabs;

const AdminInventory = () => {
  const [activeKey, setActiveKey] = useState("1");

  const nextTab = () => {
    if (activeKey === "3") {
      return;
    }
    setActiveKey(String(parseInt(activeKey) + 1));
  };
  const prevTab = () => {
    if (activeKey === "1") {
      return;
    }
    setActiveKey(String(parseInt(activeKey) - 1));
  };

  function callback() {
    setActiveKey(activeKey);
  }

  return (
    <DefaultLayout browserTitle="Inventory">
      <ConfigurationContainer data-aos="fade-right">
        <Tabs onChange={callback} defaultActiveKey="1">
          <TabPane tab="Raw Material Inventory" key="1">
            <RawMaterials />
          </TabPane>
          <TabPane tab="Product Inventory" key="2">
            <Produces />
          </TabPane>
        </Tabs>
      </ConfigurationContainer>
    </DefaultLayout>
  );
};

export default AdminInventory;
