import React from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import { ConfigurationContainer } from "./AdminStyle.styled";
import Products from "./config/products/Products";

const AdminProducts = () => {
  return (
    <DefaultLayout browserTitle="Inventory">
      <ConfigurationContainer data-aos="fade-right">
        <h3>Product Management</h3>
        <Products />
      </ConfigurationContainer>
    </DefaultLayout>
  );
};

export default AdminProducts;
