import constants from "../constants";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, action) => {
  switch (action.type) {
    case constants.ADMIN_TOKEN:
      return {
        ...state,
        admin_token: action.payload,
      };
    case constants.ADMIN_USER:
      return {
        ...state,
        admin_user: action.payload,
      };
    case constants.SYSTEM_CONFIG_RECORD:
      return {
        ...state,
        system_config_record: action.payload || [],
      };
    case constants.ALL_SUBSCRIPTION_RECORD:
      return {
        ...state,
        allSubscriptions: action.payload || [],
      };
    case constants.GET_ONE_SUBSCRIPTION:
      return {
        ...state,
        one_sub_record: action.payload,
      };
    case constants.ALL_SERVICES_RECORD:
      return {
        ...state,
        allServices: action.payload || [],
      };
    case constants.GET_ONE_SERVICE:
      return {
        ...state,
        one_service: action.payload,
      };
    case constants.ALL_PRODUCT_RECORD:
      return {
        ...state,
        allProducts: action.payload || [],
      };
    case constants.GET_ONE_PRODUCT:
      return {
        ...state,
        one_product: action.payload,
      };
    case constants.ALL_RAW_MATERIALS_RECORD:
      return {
        ...state,
        allRawMaterials: action.payload || [],
      };
    case constants.GET_RAW_MATERIAL:
      return {
        ...state,
        one_raw_material: action.payload,
      };
    case constants.ALL_PRODUCT_INVENTORY_RECORD:
      return {
        ...state,
        allProductInventory: action.payload || [],
      };
    case constants.GET_PRODUCT_INVENTORY:
      return {
        ...state,
        one_product_inventory: action.payload,
      };
    case constants.ALL_RAW_MATERIALS_RECORD_INVENTORY:
      return {
        ...state,
        allRawMaterialsInventory: action.payload || [],
      };
    case constants.GET_RAW_MATERIAL_INVENTORY:
      return {
        ...state,
        one_raw_material_inventory: action.payload,
      };
    case constants.ALL_SALES_RECORD:
      return {
        ...state,
        allSales: action.payload || [],
      };
    case constants.GET_SALE_RECORD:
      return {
        ...state,
        one_sale_record: action.payload,
      };
    case constants.PRODUCT_INVENTORY_CHART:
      return {
        ...state,
        product_inventory_chart: action.payload,
      };
    case constants.RAW_MATERIALS_INVENTORY_CHART:
      return {
        ...state,
        raw_material_inventory_chart: action.payload,
      };
    case constants.SALES_AMOUNT_CHART:
      return {
        ...state,
        sales_amount_chart: action.payload,
      };
    case constants.SALES_QUANTITY_CHART:
      return {
        ...state,
        sales_quantity_chart: action.payload,
      };
    default:
      return state;
  }
};
