import React, { useContext } from "react";
import { DashboardContainer } from "../../styles/util/UtilStyle";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  //   EachCard,
  ForeCastCard,
  TableItemValue,
  ForeCastLeft,
  ForeCastLeftInnerDate,
  ForeCastLeftInnerDayNo,
  ForeCastLeftInnerDayType,
  ForeCastLeftInnerImg,
  ForeCastLeftInnerLeft,
  ForeCastLeftInnerLeftBox,
  ForeCastLeftInnerMiddleBox,
  ForeCastLeftInnerRight,
  ForeCastRight,
  ForeCastLeftInnerRightLeftBox,
  ForeCastLeftInnerRightRightBox,
  WeatherContainer,
  //   WeatherLeftContainer,
  WeatherRightContainer,
  ForeCastType,
  ForeCastVal,
  ForeCastText,
  ForeCastTopCardText,
  ForeCastTopCard,
  ForeCastBodyCard,
  ForeCastBody2Card,
  EachForeCast2InnerCard,
  EachForesCast2InnerIcon,
  EachForesCast2InnerText,
  EachForeCast2Inner,
  TableItemTitle,
  ForeCastIcon,
  HistoryDownloadBox,
} from "../../styles/weather/WeatherStyle";
import SkeletonCard from "../../adapters/util/SkeletonCard";
import windIcon from "../../assets/icon/weather/Vector.png";
import preIcon from "../../assets/icon/weather/Vector2.png";
import { FarmInfoLink } from "../../styles/farmManagement/FarmSummaryStyle";
import { Link } from "react-router-dom";
import { AppContext } from "../../appRouter";
import { formatDate } from "../util/Util";

const Weather = (props) => {
  const { selectedFieldId } = useContext(AppContext);

  return (
    <>
      <DashboardContainer style={{ height: "fit-content" }}>
        <WeatherContainer
          data-aos="fade-up"
          data-aos-delay="2s"
          data-aos-duration="1s"
        >
          {/* <WeatherLeftContainer></WeatherLeftContainer> */}
          <WeatherRightContainer>
            <Grid container spacing={2} style={{ marginTop: "0em" }}>
              <Grid item lg={12} md={12} xs={12} sm={12} key="first">
                <h4 style={{ marginTop: "2em", marginBottom: "1em" }}>
                  Weather Forecast
                </h4>
                {props?.stillFetching ? (
                  <SkeletonCard height={"10vh"} />
                ) : (
                  <ForeCastCard>
                    <ForeCastLeft>
                      <ForeCastLeftInnerLeft>
                        <ForeCastLeftInnerLeftBox>
                          <ForeCastLeftInnerDate>
                            {formatDate(props?.weatherData?.date[0])}
                          </ForeCastLeftInnerDate>
                          <ForeCastLeftInnerImg
                            src={props?.weatherData?.weatherUrlIcon[0]}
                          />
                          {/* </ForeCastLeftInnerImg> */}
                        </ForeCastLeftInnerLeftBox>

                        <ForeCastLeftInnerMiddleBox>
                          <ForeCastLeftInnerDayType>
                            {props?.weatherData?.indicator[0]}
                          </ForeCastLeftInnerDayType>
                          <ForeCastLeftInnerDayNo>
                            {props?.weatherData?.date[0]?.substr(8, 9)}
                            <div className="deg" />
                          </ForeCastLeftInnerDayNo>
                        </ForeCastLeftInnerMiddleBox>
                      </ForeCastLeftInnerLeft>

                      <ForeCastLeftInnerRight>
                        <ForeCastLeftInnerRightLeftBox>
                          <ForeCastType>Wind</ForeCastType>
                          <ForeCastType>Humidity</ForeCastType>
                          <ForeCastType>Clouds</ForeCastType>
                          <ForeCastType>Precipitation</ForeCastType>
                        </ForeCastLeftInnerRightLeftBox>

                        <ForeCastLeftInnerRightRightBox>
                          <ForeCastVal>
                            {props?.weatherData?.windSpeed[0] + " m/s"}
                          </ForeCastVal>
                          <ForeCastVal>
                            {props?.weatherData?.humidity[0] + " %"}
                          </ForeCastVal>
                          <ForeCastVal>
                            {props?.weatherData?.cloud_over[0] + " %"}
                          </ForeCastVal>
                          <ForeCastVal>
                            {props?.weatherData?.precipitation[0] + " mm"}
                          </ForeCastVal>
                        </ForeCastLeftInnerRightRightBox>
                      </ForeCastLeftInnerRight>
                    </ForeCastLeft>

                    <ForeCastRight>
                      <ForeCastText>
                        The source of weather data and weather forecast is
                        Awhere service, which analyzes data from 2 million
                        virtual weather stations around the world, combining
                        them with radar and satellite data. Weather data is
                        provided by a grid of 9x9 kilometers.
                      </ForeCastText>
                    </ForeCastRight>
                  </ForeCastCard>
                )}
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                style={{ marginTop: "2em" }}
                key="second"
              >
                <ForeCastTopCard
                  style={{ background: "rgb(235, 236, 236, 0.7)" }}
                >
                  <ForeCastTopCardText>Forecast</ForeCastTopCardText>
                </ForeCastTopCard>

                <ForeCastBodyCard>
                  {props?.stillFetching ? (
                    <SkeletonCard height={"50vh"} />
                  ) : (
                    <TableContainer style={{ width: "100%" }}>
                      <Table style={{ width: "100%" }}>
                        <TableHead>
                          <TableRow>
                            {/* {Object.entries(props?.weatherData)?.map(
                                (eachForeCast) => (
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "center",
                                    }}
                                  >
                                    {eachForeCast?.date}
                                  </TableCell>
                                )
                              )} */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.entries(props?.weatherData)?.map(
                            (eachRow, outterPos) => (
                              <TableRow key={outterPos}>
                                {eachRow[1].map((eachData, pos) => (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    key={pos}
                                    style={{ textAlign: "center" }}
                                  >
                                    {eachRow[0] === "weatherUrlIcon" && (
                                      <ForeCastIcon src={eachData} />
                                    )}
                                    {eachRow[0] === "maxMinTemp" && (
                                      <>
                                        <TableItemTitle>Max/Min</TableItemTitle>
                                        <TableItemValue>
                                          {eachData}
                                        </TableItemValue>
                                      </>
                                    )}
                                    {eachRow[0] === "Indicator" && (
                                      <TableItemTitle>Indicates</TableItemTitle>
                                    )}
                                    {eachRow[0] === "windSpeed" && (
                                      <>
                                        <TableItemTitle>Wind</TableItemTitle>
                                        <TableItemValue>
                                          {eachData + "/"}
                                        </TableItemValue>
                                      </>
                                    )}
                                    {eachRow[0] === "cloud_over" && (
                                      <>
                                        <TableItemTitle>Clouds</TableItemTitle>
                                        <TableItemValue>
                                          {eachData + " %"}
                                        </TableItemValue>
                                      </>
                                    )}
                                    {eachRow[0] === "precipitation" && (
                                      <>
                                        <TableItemTitle>
                                          Precipitation
                                        </TableItemTitle>
                                        <TableItemValue>
                                          {eachData + " mm"}
                                        </TableItemValue>
                                      </>
                                    )}
                                    {eachRow[0] === "humidity" && (
                                      <>
                                        <TableItemTitle>
                                          Humidity
                                        </TableItemTitle>
                                        <TableItemValue>
                                          {eachData + " %"}
                                        </TableItemValue>
                                      </>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </ForeCastBodyCard>
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginBottom: "2em" }}>
              <Grid item lg={6} md={6} xs={12} sm={12} key="1">
                <ForeCastTopCard>
                  <ForeCastTopCardText>Precipitation (0mm)</ForeCastTopCardText>
                </ForeCastTopCard>

                <ForeCastBody2Card>
                  {props?.stillFetching ? (
                    <SkeletonCard height={"10vh"} />
                  ) : (
                    <EachForeCast2Inner>
                      {props?.weatherData?.precipitation?.map(
                        (eachData, pos) => (
                          <EachForeCast2InnerCard key={pos}>
                            <EachForesCast2InnerIcon src={preIcon} />

                            <EachForesCast2InnerText>
                              {eachData + " mm"}
                            </EachForesCast2InnerText>
                          </EachForeCast2InnerCard>
                        )
                      )}
                    </EachForeCast2Inner>
                  )}
                </ForeCastBody2Card>
              </Grid>
              <Grid item lg={6} md={6} xs={12} sm={12} key="2">
                <ForeCastTopCard>
                  <ForeCastTopCardText>Wind (1-8m/s)</ForeCastTopCardText>
                </ForeCastTopCard>

                <ForeCastBody2Card>
                  {props?.stillFetching ? (
                    <SkeletonCard height={"10vh"} />
                  ) : (
                    <EachForeCast2Inner>
                      {props?.weatherData?.windSpeed?.map((eachData, pos) => (
                        <EachForeCast2InnerCard key={pos}>
                          <EachForesCast2InnerIcon src={windIcon} />
                          <EachForesCast2InnerText>
                            {eachData + " m/s"}
                          </EachForesCast2InnerText>
                        </EachForeCast2InnerCard>
                      ))}
                    </EachForeCast2Inner>
                  )}
                </ForeCastBody2Card>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item lg={4} md={4} xs={6} sm={6} key="weatherView">
                <HistoryDownloadBox style={{ marginTop: "1em" }}>
                  <Link to={`/dashboard/weather/history/${props?.urlCoords}`}>
                    <FarmInfoLink style={{ textDecoration: "underline" }}>
                      GO TO WEATHER HISTORY
                    </FarmInfoLink>
                  </Link>
                </HistoryDownloadBox>
              </Grid>

              <Grid item lg={4} md={4} xs={6} sm={6} key="fieldView">
                <HistoryDownloadBox
                  style={{ marginTop: "1em", textAlign: "right" }}
                >
                  <Link to={`/dashboard/farmsummary/info/${selectedFieldId}`}>
                    <FarmInfoLink style={{ textDecoration: "underline" }}>
                      VIEW THIS FIELD INFO
                    </FarmInfoLink>
                  </Link>
                </HistoryDownloadBox>
              </Grid>
            </Grid>
          </WeatherRightContainer>
        </WeatherContainer>
      </DashboardContainer>
    </>
  );
};

export default Weather;
