import styled from "styled-components";

export const Dashhead = styled.h3`
  font-family: "Avenir Heavy", sans-serif;
  margin-bottom: 2rem;
`;

export const DashboardCardHolder = styled.div`
  background: var(--white-color);
  padding: 15px 2rem;
  transition: ease all 0.5s;
  border-radius: 5px;
  margin-bottom: 15px;
  .iconHolder {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.background || "rgba(153, 231, 255, 0.1)"};
    span {
      color: var(--black-color);
    }
  }
  .content {
    transition: ease all 0.5s;
    p {
      font-size: 1.1rem;
      color: #333333;
      margin: 0;
      word-break: break-word;
    }
    strong {
      font-size: 1.9rem;
      font-family: "Avenir Heavy", sans-serif;
    }
  }
`;

export const ConfigurationContainer = styled.div`
  background: var(--white-color);
  padding: 15px 2rem;
  transition: ease all 0.5s;
  border-radius: 5px;
  margin-bottom: 15px;
  .button-control {
    transition: ease all 0.5s;
    margin-top: 1rem;
    .btn {
      padding: 10px 2rem;
      display: flex;
      align-items: center;
      color: var(--white-color);
      border: none;
    }
    .prev,
    .next {
      background: #eaf4f7;
      color: var(--green-color);
    }
    .save {
      background: var(--green-color);
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--green-color);
  }
  .ant-tabs-tab:hover {
    color: var(--green-color);
  }
`;

export const NotificationHolder = styled.div`
  position: relative;
  transition: ease all 0.5s;
  .header {
    background: var(--gray-color);
    font-size: 0.8rem;
    padding: 1rem 10px;
  }
  .form-details {
    padding: 15px 10px;
    font-family: "Avenir Light", sans-serif;
    &.underline_config {
      border-bottom: 1px solid var(--fade-color);
    }
    .labeltext {
      text-transform: capitalize;
    }
    .form-group {
      transition: ease all 0.5s;
      padding: 10px 0;
      margin-bottom: 10px;
    }
  }
  .loading_roles {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 70%;
    &.inside {
      margin: 20px 0;
      padding: 30px 50px;
      text-align: center;
    }
  }
`;

export const ServiceSection = styled.div`
  position: relative;
  transition: ease all 0.5s;
  .category {
    padding: 10px;
    background: var(--gray-color);
  }
  .content {
    transition: ease all 0.5s;
    margin-top: 1rem;
    position: relative;
    .formholder {
      position: relative;
      display: flex;
      &:after {
        position: absolute;
        content: "";
        width: 80%;
        background: var(--fade-color);
        height: 1px;
        right: 0;
        bottom: 10px;
      }
    }
    &__inner {
      padding: 15px 1rem;
      transition: ease all 0.5s;
      span {
        padding-right: 15px;
        text-transform: capitalize;
        cursor: pointer;
      }
    }
  }
  .actiontable {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    svg {
      font-size: 14px;
    }
    button {
      border: none;
      background: transparent;
      outline: none;
    }
    .eid {
      svg {
        fill: green;
        stroke: green;
      }
    }
    .del {
      svg {
        fill: red;
        stroke: red;
      }
    }
  }
`;

export const ManageUserContainer = styled.div`
  background: var(--white-color);
  padding: 15px 2rem;
  transition: ease all 0.5s;
  border-radius: 5px;
  margin-bottom: 15px;
  h3 {
    font-family: "Avenir Heavy", sans-serif;
    margin-bottom: 2rem;
  }
  label {
    display: flex;
  }
  .sthbtn {
    margin-top: 10px;
    display: flex;
    transition: ease all 0.5s;
    justify-content: flex-end;
  }
`;

export const TrackingContainer = styled.div`
  background: var(--white-color);
  padding: 15px 2rem;
  transition: ease all 0.5s;
  border-radius: 5px;
  margin-bottom: 15px;
`;

export const UserDetailContainer = styled.div`
  background: var(--white-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  padding: 2rem 3rem;
  transition: ease all 0.5s;
  border-radius: 4px;
  .title {
    font-family: "Avenir Heavy", sans-serif;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .details {
    position: relative;
    &__top {
      font-family: "Avenir Light", sans-serif;
      margin-top: 2rem;
      position: relative;
      cursor: pointer;
      span {
        font-weight: bold;
      }
      p {
        font-weight: bolder;
        font-family: "Avenir Heavy", sans-serif;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
      }
      &:after {
        position: absolute;
        content: "";
        width: 80%;
        background: #dddddd;
        height: 1px;
        right: 0;
        bottom: 10px;
      }
    }
    &__progress {
      font-family: "Avenir Light", sans-serif;
      margin-top: 2rem;
      span {
        font-weight: lighter;
      }
      &:after {
        position: absolute;
        content: "";
        width: 80%;
        background: #dddddd;
        height: 1px;
        right: 0;
        bottom: 10px;
      }
    }
    &__inner {
      border-bottom: 1px solid #f2f2f2;
      padding: 1rem 0;
      color: #333;
      font-family: "Avenir Light", sans-serif;
    }
  }
  .progress_action {
    transition: ease all 0.5s;
    margin-top: 1.5rem;
    .label {
      font-family: "Avenir Light", sans-serif;
      display: flex;
    }
    button {
      height: 30px;
      display: flex;
      justify-content: center;
      width: 10%;
      margin: auto;
      align-items: center;
    }
    .ant-progress-success-bg,
    .ant-progress-bg {
      background-color: var(--green-color);
    }
  }
  @media (max-width: 499px) {
    .details {
      &__top {
        &:after {
          position: absolute;
          content: "";
          width: 50%;
          background: var(--fade-color);
          height: 1px;
          right: 0;
          bottom: 10px;
        }
      }
    }
    .progress_action {
      button {
        width: 30%;
      }
    }
  }
`;
