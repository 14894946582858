/* eslint-disable import/no-anonymous-default-export */
export default {
  ACCESS_TOKEN: "ACCESS_TOKEN",
  ALL_USERS: "ALL_USERS",
  AUTH_ERROR: "AUTH_ERROR",
  USER_DEATIALS: "USER_DEATIALS",
  TEAM_ROLES: "TEAM_ROLES",
  ALL_FIELDS: "ALL_FIELDS",
  TEAM_MEMBERS: "TEAM_MEMBERS",
  ALL_CROPS: "ALL_CROPS",
  ALL_CROPPLAN: "ALL_CROPPLAN",
  DELETE_ROLE: "DELETE_ROLE",
  RESET_PASSWORD_EMAIL: "RESET_PASSWORD_EMAIL",
  MY_ROLES: "MY_ROLES",
  ALL_FARM_ACTIVITIES: "ALL_FARM_ACTIVITIES",
  SINGLE_FARM_ACTIVITIES: "SINGLE_FARM_ACTIVITIES",
  USER_FARM_TASK: "USER_FARM_TASK",
  GET_ALL_PERMISSION: "GET_ALL_PERMISSION",
  ADMIN_TOKEN: "ADMIN_TOKEN",
  ADMIN_USER: "ADMIN_USER",
  SYSTEM_CONFIG_RECORD: "SYSTEM_CONFIG_RECORD",
  ALL_SUBSCRIPTION_RECORD: "ALL_SUBSCRIPTION_RECORD",
  GET_ONE_SUBSCRIPTION: "GET_ONE_SUBSCRIPTION",
  ALL_SERVICES_RECORD: "ALL_SERVICES_RECORD",
  GET_ONE_SERVICE: "GET_ONE_SERVICE",
  ALL_PAYMENT_RECORD: "ALL_PAYMENT_RECORD",
  ALL_ACCOUNT_USERS: "ALL_ACCOUNT_USERS",
  ONE_ACCOUNT_USER: "ONE_ACCOUNT_USER",
  ALL_ACCOUNT_TEAMS: "ALL_ACCOUNT_TEAMS",
  ALL_TRACKING_RECORDS: "ALL_TRACKING_RECORDS",
  ALL_ACCOUNT_SUBSCRIPTIONS: "ALL_ACCOUNT_SUBSCRIPTIONS",
  ONE_ACCOUNT_SUBSCRIPTIONS: "ONE_ACCOUNT_SUBSCRIPTIONS",
  ALL_SUBSCRIPTIONS: "ALL_SUBSCRIPTIONS",
  GET_SINGLE_SUBSCRIPTIONS: "GET_SINGLE_SUBSCRIPTIONS",
  GET_USER_SUBSCRIPTIONS: "GET_USER_SUBSCRIPTIONS",
  CREATE_PAYMENT: "CREATE_PAYMENT",
  GET_MONITOR_FIELD: "GET_MONITOR_FIELD",
  ALL_PRODUCT_RECORD: "ALL_PRODUCT_RECORD",
  GET_ONE_PRODUCT: "GET_ONE_PRODUCT",
  ALL_RAW_MATERIALS_RECORD: "ALL_RAW_MATERIALS_RECORD",
  GET_RAW_MATERIAL: "GET_RAW_MATERIAL",
  ALL_PRODUCT_INVENTORY_RECORD: "ALL_PRODUCT_INVENTORY_RECORD",
  GET_PRODUCT_INVENTORY: "GET_PRODUCT_INVENTORY",
  ALL_RAW_MATERIALS_RECORD_INVENTORY: "ALL_RAW_MATERIALS_RECORD_INVENTORY",
  GET_RAW_MATERIAL_INVENTORY: "GET_RAW_MATERIAL_INVENTORY",
  ALL_SALES_RECORD: "ALL_SALES_RECORD",
  GET_SALE_RECORD: "GET_SALE_RECORD",
  PRODUCT_INVENTORY_CHART: "PRODUCT_INVENTORY_CHART",
  RAW_MATERIALS_INVENTORY_CHART: "RAW_MATERIALS_INVENTORY_CHART",
  SALES_AMOUNT_CHART: "SALES_AMOUNT_CHART",
  SALES_QUANTITY_CHART: "SALES_QUANTITY_CHART",
};
