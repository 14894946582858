import React, { useEffect, useState } from "react";
import { ServiceSection } from "../../../AdminStyle.styled";
import { Row, Col, Button, Table, Modal, Tag } from "antd";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  DeleteRawMaterialInventory,
  GetRawMaterialsInventory,
  GetOneConfiguredServices,
  UpdateRawMaterialInventory,
  CreateRawMaterialInventory,
  GetAllRawMaterials,
} from "../../../../../../redux/actions/adminActions";
import { Delete, Edit, Add } from "@mui/icons-material";
import CreateRawMaterialModal from "./CreateRawMaterialsModal";
import { formatDateObjectHandler, formatNumber } from "../../../../../../utils";

const RawMaterials = (props) => {
  const {
    CreateRawMaterialInventory,
    UpdateRawMaterialInventory,
    GetRawMaterialsInventory,
    DeleteRawMaterialInventory,
    allRawMaterialsInventory,
    GetAllRawMaterials,
    allRawMaterials,
  } = props;

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formMode, setFormMode] = useState("CREATE_MODE");
  const [rawMaterialsRecord, setRawMaterialsRecord] = useState(null);

  const editRecord = async (record) => {
    setRawMaterialsRecord(record);
    setVisible(true);
    setFormMode("UPDATE_MODE");
  };

  const deleteRecord = (record) => {
    Modal.confirm({
      title: "Do you want to delete this record?",
      content: "Click yes to proceed",
      okText: "Delete",
      loading: deleteLoading,
      okType: "danger",
      async onOk() {
        setDeleteLoading(true);
        const res = await DeleteRawMaterialInventory(record.id);
        if (res.success) {
          setDeleteLoading(false);
          toast.success(res.message, {
            pauseOnHover: false,
          });
        } else {
          toast.info(res.message, {
            pauseOnHover: true,
          });
        }
      },
      onCancel() {
        setDeleteLoading(false);
        return false;
      },
    });
  };
  const CreateService = () => {
    setVisible(true);
    setFormMode("CREATE_MODE");
    setRawMaterialsRecord(null);
  };

  const closeModal = () => {
    setVisible(false);
    setFormMode("CREATE_MODE");
    setRawMaterialsRecord(null);
  };
  const recordColumn = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Raw Material",
      dataIndex: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      align: "center",
      render: (_text, record) => <>{formatNumber(record?.quantity)}</>,
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      align: "center",
      render: (_text, record) => (
        <>
          {formatDateObjectHandler(record.date_created, "Do MMM YYYY")} <br />
        </>
      ),
    },
    {
      title: "action",
      dataIndex: "action",
      align: "center",
      render: (_text, record) => (
        <div className="actiontable">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <button className="eid" onClick={() => editRecord(record)}>
            <Edit />
          </button>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <button className="del" onClick={() => deleteRecord(record)}>
            <Delete />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const getRawMaterialsRecords = async () => {
      setLoading(true);
      const res = await GetRawMaterialsInventory();
      setLoading(false);
      if (!res.success) {
        setLoading(false);
        toast.error(`Error`);
      }
    };
    getRawMaterialsRecords();
  }, [GetRawMaterialsInventory]);
  return (
    <ServiceSection>
      <Row>
        <Col xs={24}>
          <div style={{ textAlign: "right", marginBottom: "15px" }}>
            <Button
              onClick={CreateService}
              icon={<Add style={{ marginRight: "8px" }} />}
              type="primary"
            >
              {" "}
              Create Inventory
            </Button>
          </div>
        </Col>
      </Row>

      <Table
        rowKey="id"
        style={{ overflowX: "auto" }}
        loading={loading}
        columns={recordColumn}
        dataSource={allRawMaterialsInventory}
      />

      <CreateRawMaterialModal
        visible={visible}
        closeModal={closeModal}
        formMode={formMode}
        one_raw_material_inventory={rawMaterialsRecord}
        CreateRawMaterialInventory={CreateRawMaterialInventory}
        UpdateRawMaterialInventory={UpdateRawMaterialInventory}
        GetAllRawMaterials={GetAllRawMaterials}
        allRawMaterials={allRawMaterials}
      />
    </ServiceSection>
  );
};

const mapStateToProps = (state) => {
  return {
    allRawMaterialsInventory: state.adminReducer.allRawMaterialsInventory,
    allRawMaterials: state.adminReducer.allRawMaterials,
    one_service: state.adminReducer.one_service,
  };
};

export default connect(mapStateToProps, {
  UpdateRawMaterialInventory,
  CreateRawMaterialInventory,
  GetRawMaterialsInventory,
  DeleteRawMaterialInventory,
  GetOneConfiguredServices,
  GetAllRawMaterials,
})(RawMaterials);
