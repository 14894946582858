import React from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudIcon from "@mui/icons-material/Cloud";
import { useContext } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { AppContext } from "../../appRouter";
import {
  MapControlEachDate,
  MapDate,
  MapDateArrowIcon,
  MapNav,
} from "../../styles/dashboard/DashboardHomeStyle";

import { cloudLimit } from "../util/Util";
// Start The date slider here ---
const MenuItem = ({ text, cloud }) => {
  return (
    <MapControlEachDate>
      {cloud > cloudLimit ? (
        <div
          style={{ marginBottom: "-0.7em" }}
          title={"This date has no cloud"}
        >
          <CloudOffIcon />
        </div>
      ) : (
        <div style={{ marginBottom: "-0.7em" }}>
          <CloudIcon />
        </div>
      )}
      <MapDate>{text}</MapDate>
    </MapControlEachDate>
  );
};

export const Menu = (list) =>
  list.map((el, pos) => {
    return (
      <MenuItem
        text={el?.properties?.date}
        cloud={el?.properties?.cloudCoverPercentage}
        key={pos}
        selected={selected}
      />
    );
  });
// .reverse();

const Arrow = (pos) => {
  return (
    <MapNav>
      <MapDateArrowIcon>
        {pos === "left" ? (
          <ChevronLeftIcon
            style={{
              marginTop: "0.3em",
              fontSize: "2.5em",
              cursor: "pointer",
              marginRight: "0.5em",
            }}
          />
        ) : (
          <ChevronRightIcon
            style={{
              marginTop: "0.3em",
              fontSize: "2.5em",
              cursor: "pointer",
              marginLeft: "0.5em",
            }}
          />
        )}
      </MapDateArrowIcon>
    </MapNav>
  );
};

const ArrowLeft = Arrow("left");
const ArrowRight = Arrow("right");

const selected = "item1";

// --- End the date slider here ---

const MapDates = (props) => {
  const {
    handleIsCloudPresent,
    handleSelDate,
    handleSelSplitDate1,
    handleSelSplitDate2,
  } = useContext(AppContext);

  let dates = props?.dates;

  let menuItems = Menu(dates);

  const handleDate = (e) => {
    if (props?.setIsCloudPresent) {
      props.setIsCloudPresent(true);
    }
    handleIsCloudPresent(true);

    // let selectedDetails = props?.dates[e];
    // pick the date and date behind
    let newDate = "";
    let clickedCloudCover = "";

    try {
      newDate = props?.dates[e].properties?.date;
    } catch (e) {
      newDate = "";
    }

    // test in case if enddate is not valid or user picsk the last date
    try {
      clickedCloudCover = props?.dates[e]?.properties?.cloudCoverPercentage;
    } catch (e) {
      clickedCloudCover = cloudLimit + 1;
    }

    props?.setChangeDates(false);

    if (props?.selDateType === "full-screen") {
      handleSelDate(newDate);
    } else if (props?.selDateType === "left-screen") {
      handleSelSplitDate1(newDate);
    } else if (props?.selDateType === "right-screen") {
      handleSelSplitDate2(newDate);
    }

    // props?.handleStartDate(newDate);
    if (clickedCloudCover > cloudLimit) {
      props?.handleCloudCover(cloudLimit + 1);
      setTimeout(() => {
        handleIsCloudPresent(false);
        if (props?.setIsCloudPresent) {
          props.setIsCloudPresent(false);
        }
      }, 500);
    } else {
      props?.handleCloudCover(clickedCloudCover);
      handleIsCloudPresent(true);
      if (props?.setIsCloudPresent) {
        props.setIsCloudPresent(true);
      }
    }
  };

  return (
    <>
      <ScrollMenu
        wheel={true}
        data={menuItems}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        // selected={props?.startDate}
        // onSelect={(e) => props?.handleStartDate(e)}
        onSelect={handleDate}
      />
    </>
  );
};

export default MapDates;
