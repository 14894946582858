import React, { useContext, useEffect } from "react";

// import LoadingOverlay from 'react-loading-overlay';

import { GeoMapContainer } from "../../styles/geoMap/geoMapStyle";

import { AppContext } from "../../appRouter";
import "../../App.css";
import getAvailableDates from "../../adapters/map/getAvailableDates";

import MapEngine from "./MapEngine";
import { mapDatesHandler } from "../util/Util";

const GeoMap = (props) => {
  const {
    selectedCentroid,
    index,
    selectedCoords,
    selectedRealCoords,
    startDate,
    endDate,
    cloudCover,
  } = useContext(AppContext);

  useEffect(() => {
    props?.setIsMapLoading(true);

    if (props?.changeDates) {
      let getResp = getAvailableDates(startDate, endDate, selectedCentroid);

      getResp.then(({ isError, data }) => {
        if (isError) {
          props?.setIsMapLoading(false);
          props?.setDates([]);
        } else {
          props?.setIsMapLoading(false);
          let filteredDates = mapDatesHandler(data?.features);
          props?.setDates(filteredDates);
        }
      });
    } else {
      props?.setIsMapLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCoords,
    selectedRealCoords,
    startDate,
    endDate,
    props?.changeDates,
  ]);
  console.log(props?.indexing);
  return (
    <>
      {/* <LoadingOverlay
                active={props?.isMapLoading}
                spinner
                text='Please wait while we are loading the map...'
            > */}
      <GeoMapContainer>
        <MapEngine
          height={props?.height}
          refresh={props?.setIsMapLoading}
          selectedCentroid={selectedCentroid}
          selectedCoords={selectedCoords}
          selectedRealCoords={selectedRealCoords}
          cloudCover={cloudCover}
          startDate={startDate}
          endDate={endDate}
          selectedLayer={index}
          setIsMapLoading={props?.setIsMapLoading}
          // showSplitButton={props?.indexing}

          // refresh={refresh1}
        />
      </GeoMapContainer>
      {/* </LoadingOverlay> */}
    </>
  );
};

export default GeoMap;
