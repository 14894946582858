import React, { useEffect, useState } from "react";
import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
  LineElement,
  CategoryScale,
  Title,
  LinearScale,
  PointElement,
  Filler, // 1. Import Filler plugin
} from "chart.js";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  GetAllProducts,
  GetAllRawMaterials,
  GetProductInventoryChart,
  GetRawMaterialsInventoryChart,
} from "../../../../redux/actions/adminActions";
import { toast } from "react-toastify";
import { Col, Row, Select } from "antd";
import "./chart.css";
import moment from "moment";

const { Option } = Select;

const DashboardChart = (props) => {
  const [product, setProduct] = useState(null);
  const [productYear, setProductYear] = useState(null);
  const [rawMaterial, setRawMaterial] = useState(null);
  const [rawMaterialYear, setRawMaterialYear] = useState(null);

  const {
    GetAllProducts,
    GetAllRawMaterials,
    allProducts,
    allRawMaterials,
    GetProductInventoryChart,
    product_inventory_chart,
    GetRawMaterialsInventoryChart,
    raw_material_inventory_chart,
  } = props;

  // Chart
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler,
    Title
  );
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const getProductRecords = async () => {
    const res = await GetAllProducts();
    if (!res.success) {
      toast.error(`Error`);
    }
  };

  const getRawMaterialsRecords = async () => {
    const res = await GetAllRawMaterials();
    if (!res.success) {
      toast.error(`Error`);
    }
  };
  useEffect(() => {
    getProductRecords();
    getRawMaterialsRecords();
  }, []);

  const getProductInventoryRecords = async () => {
    const res = await GetProductInventoryChart(productYear, product);
    if (!res.success) {
      toast.error(`Error`);
    }
  };
  const getRawMaterialInventoryRecords = async () => {
    const res = await GetRawMaterialsInventoryChart(
      rawMaterialYear,
      rawMaterial
    );
    if (!res.success) {
      toast.error(`Error`);
    }
  };
  useEffect(() => {
    if (product || productYear) {
      getProductInventoryRecords();
    }
    if (rawMaterial || rawMaterialYear) {
      getRawMaterialInventoryRecords();
    }
  }, [product, productYear, rawMaterial, rawMaterialYear]);

  const product_data = {
    labels: product_inventory_chart
      ? Object?.keys(product_inventory_chart)
      : [],
    datasets: [
      {
        label: "Products",
        data: product_inventory_chart
          ? Object?.values(product_inventory_chart)
          : [],
        // backgroundColor: "#0A4C3E",
        borderColor: "#0A4C3E",
        color: "#777777",
        pointRadius: 0,
        pointBackgroundColor: "",
        pointBorderWidth: 0,
        fill: true, // Fill the area under the line
        tension: 0.4,
      },
    ],
  };
  const raw_materials_data = {
    labels: raw_material_inventory_chart
      ? Object?.keys(raw_material_inventory_chart)
      : [],
    datasets: [
      {
        label: "Raw Material",
        data: raw_material_inventory_chart
          ? Object?.values(raw_material_inventory_chart)
          : [],
        // backgroundColor: "#0A4C3E",
        borderColor: "#0A4C3E",
        color: "#777777",
        pointRadius: 0,
        pointBackgroundColor: "",
        pointBorderWidth: 0,
        fill: true, // Fill the area under the line
        tension: 0.4,
      },
    ],
  };

  const checkOnchange = (value, type) => {
    if (type === "product") {
      setProduct(value);
    }
    if (type === "product-year") {
      setProductYear(value);
    }
    if (type === "raw-material") {
      setRawMaterial(value);
    }
    if (type === "raw-material-year") {
      setRawMaterialYear(value);
    }
  };
  const productsChildren = allProducts?.map((obj) => {
    return (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    );
  });

  const rawMaterialsChildren = allRawMaterials?.map((obj) => {
    return (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    );
  });
  const years = ["2024", JSON.stringify(moment().year())];
  const yearChildren = years?.map((obj) => {
    return (
      <Option key={obj} value={obj}>
        {obj}
      </Option>
    );
  });

  useEffect(() => {
    if (allProducts) {
      setProduct(allProducts[0]?.id);
      setProductYear(JSON.stringify(moment().year()));
    }
    if (allProducts) {
      setRawMaterial(allRawMaterials[0]?.id);
      setRawMaterialYear(JSON.stringify(moment().year()));
    }
  }, [allProducts, allRawMaterials]);
  return (
    <div className="chart-container">
      <div className="chart-item">
        <Row gutter={24}>
          <Col xs={24} xl={12} lg={12}>
            <h6>Product Inventory</h6>
          </Col>
          <Col xs={24} xl={12} lg={12}>
            <Row gutter={24}>
              <Col xs={24} xl={12} lg={12}>
                <Select
                  name="product"
                  placeholder="Select a product"
                  size="medium"
                  value={product}
                  onChange={(event) => checkOnchange(event, "product")}
                  className="select"
                >
                  {productsChildren}
                </Select>
              </Col>
              <Col xs={24} xl={12} lg={12}>
                <Select
                  name="product-year"
                  placeholder="Select year"
                  size="medium"
                  value={productYear}
                  onChange={(event) => checkOnchange(event, "product-year")}
                  className="select"
                >
                  {yearChildren}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ height: "200px", marginTop: "20px" }}>
          <Line options={options} data={product_data} />
        </div>
      </div>
      <div className="chart-item">
        <Row gutter={24}>
          <Col xs={24} xl={12} lg={12}>
            <h6>Raw Material Inventory</h6>
          </Col>
          <Col xs={24} xl={12} lg={12}>
            <Row gutter={24}>
              <Col xs={24} xl={12} lg={12}>
                <Select
                  name="raw-material"
                  placeholder="Select a raw material"
                  size="medium"
                  value={rawMaterial}
                  onChange={(event) => checkOnchange(event, "raw-material")}
                  className="select"
                >
                  {rawMaterialsChildren}
                </Select>
              </Col>
              <Col xs={24} xl={12} lg={12}>
                <Select
                  name="raw-material-year"
                  placeholder="Select year"
                  size="medium"
                  value={rawMaterialYear}
                  onChange={(event) =>
                    checkOnchange(event, "raw-material-year")
                  }
                  className="select"
                >
                  {yearChildren}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ height: "200px", marginTop: "20px" }}>
          <Line options={options} data={raw_materials_data} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allProducts: state.adminReducer.allProducts,
    allRawMaterials: state.adminReducer.allRawMaterials,
    product_inventory_chart: state.adminReducer.product_inventory_chart,
    raw_material_inventory_chart:
      state.adminReducer.raw_material_inventory_chart,
  };
};

export default connect(mapStateToProps, {
  GetAllProducts,
  GetAllRawMaterials,
  GetProductInventoryChart,
  GetRawMaterialsInventoryChart,
})(DashboardChart);
