import React from "react";

import { useHistory } from "react-router-dom";

import DirectionsIcon from "@mui/icons-material/Directions";
import {
  MobileOnlyView,
  WarningBox,
  WarningImage,
  WarningText,
} from "../../styles/util/UtilStyle";

import warningImage from "../../assets/img/util/warning.svg";
import { NextBox } from "../../styles/satelliteField/SatelliteFieldStyle";

export const DisableMobileView = () => {
  const history = useHistory();

  return (
    <>
      <MobileOnlyView>
        <WarningBox>
          <WarningImage src={warningImage} />
          <WarningText>Ops! Please use a bigger screen.</WarningText>

          <NextBox onClick={() => history.push("/dashboard/satellite-view/")}>
            <DirectionsIcon /> Satellite View
          </NextBox>
        </WarningBox>
      </MobileOnlyView>
    </>
  );
};
