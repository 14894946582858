import React from "react";
import { Dropdown, Menu } from "antd";
import { CheckCircle, CancelRounded } from "@mui/icons-material";
import { MoreOutlined } from "@ant-design/icons";

export const SubscriptionMenu = ({ record, ...props }) => {
  const menu = (
    <Menu className="tableaction team">
      <Menu.Item key="0" onClick={() => props.editRecord(record)}>
        <CheckCircle /> Edit
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => props.confirmSubscriptionDelete(record)}
      >
        <CancelRounded /> Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className="ant-dropdown-link">
        <MoreOutlined />
      </a>
    </Dropdown>
  );
};
