import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Modal, Row, Select, Switch } from "antd";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

const { Option } = Select;

const CreateRawMaterialModal = (props) => {
  const {
    visible,
    formMode,
    closeModal,
    one_raw_material_inventory,
    UpdateRawMaterialInventory,
    CreateRawMaterialInventory,
    GetAllRawMaterials,
    allRawMaterials,
  } = props;
  const [loading, setLoading] = useState(false);
  const [raw_material, setRaw_materials] = useState(null);
  const [quantity, setQuantity] = useState("");

  const checkMode = formMode === "CREATE_MODE";
  const { register, handleSubmit, reset, control } = useForm();

  useEffect(() => {
    const getRawMaterialsRecords = async () => {
      const res = await GetAllRawMaterials();
      if (!res.success) {
        setLoading(false);
        toast.error(`Error`);
      }
    };
    getRawMaterialsRecords();
  }, [GetAllRawMaterials]);

  const closeRawMaterial = () => {
    setRaw_materials(null);
    setQuantity("");
    closeModal();
  };

  const checkOnchange = (value) => {
    setRaw_materials(value);
  };
  useEffect(() => {
    if (one_raw_material_inventory) {
      setRaw_materials(one_raw_material_inventory?.raw_material);
      setQuantity(one_raw_material_inventory?.quantity);
    }
  }, [one_raw_material_inventory]);
  const createRawMaterialInventory = async () => {
    setLoading(true);
    if (raw_material === "") {
      toast.error(`Raw material cannot be empty`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (quantity === "") {
      toast.error(`Quantity must be greater than zero`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (formMode === "CREATE_MODE") {
      const createObj = {
        quantity,
        raw_material,
      };
      const res = await CreateRawMaterialInventory(createObj);
      if (res.success) {
        setLoading(false);
        toast.success("Raw Materials Inventory Created Successfully", {
          pauseOnHover: false,
        });
        closeRawMaterial();
        requestAnimationFrame(() => {
          closeRawMaterial();
          setLoading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setLoading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setLoading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
      setLoading(false);
    } else if (formMode === "UPDATE_MODE") {
      const updateObj = {
        id: one_raw_material_inventory?.id,
        quantity,
        raw_material,
      };
      const res = await UpdateRawMaterialInventory(updateObj);
      if (res.success) {
        setLoading(false);
        toast.success("Raw Materials Inventory Updated Successfully", {
          pauseOnHover: false,
        });
        closeRawMaterial();
        requestAnimationFrame(() => {
          closeRawMaterial();
          setLoading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setLoading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setLoading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
    }
  };
  const children = allRawMaterials?.map((obj) => {
    return (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    );
  });
  return (
    <>
      <Modal
        visible={visible}
        title={
          <p style={{ fontWeight: "bold" }}>
            {checkMode
              ? "Create Raw Material Inventory"
              : `Update Raw Material Inventory`}
          </p>
        }
        footer={null}
        onCancel={closeRawMaterial}
        maskClosable={false}
        width={"400px"}
      >
        <form
          onSubmit={handleSubmit(createRawMaterialInventory)}
          autoComplete="off"
        >
          <div className="content">
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Raw Material</label>
                  <Controller
                    name="raw_material"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        // allowClear
                        {...register("raw_material")}
                        name="raw_material"
                        placeholder="Select a raw material"
                        style={{ width: "100%" }}
                        size="large"
                        value={raw_material}
                        onChange={checkOnchange}
                      >
                        {children}
                      </Select>
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Quantity</label>
                  <input
                    name="quantity"
                    className="ant-input ant-input-lg"
                    placeholder="Type in Quantity"
                    value={quantity ? Number(quantity).toLocaleString() : ""}
                    onChange={(event) => {
                      const value = event.target.value.replace(/,/gi, "");

                      if (isNaN(value)) return;

                      setQuantity(value);
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <Divider />
          <Row style={{ margin: "2rem 0" }}>
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                loading={loading}
                type="primary"
                onClick={handleSubmit(createRawMaterialInventory)}
                style={{ marginRight: "10px" }}
              >
                {formMode === "CREATE_MODE" ? "Save" : "Update"} Inventory
              </Button>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default CreateRawMaterialModal;
