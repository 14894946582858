import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Modal, Row, Select, Switch } from "antd";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { formatNumber } from "../../../../../utils";

const { Option } = Select;

const CreateSalesModal = (props) => {
  const {
    visible,
    formMode,
    closeModal,
    one_sale,
    CreateSales,
    UpdateSales,
    GetAllProducts,
    allProducts,
  } = props;
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [buyer_name, setBuyerName] = useState("");
  const [buyer_phone, setBuyerPhone] = useState("");
  const [unit_price, setUnitPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [quantity, setQuantity] = useState("");

  const checkMode = formMode === "CREATE_MODE";
  const { handleSubmit, control } = useForm();

  const getProductRecords = async () => {
    const res = await GetAllProducts();
    if (!res.success) {
      setLoading(false);
      toast.error(`Error`);
    }
  };
  useEffect(() => {
    getProductRecords();
  }, [GetAllProducts]);

  const closeSalesModal = () => {
    setProduct(null);
    setBuyerName("");
    setBuyerPhone("");
    setUnitPrice("");
    setAmount("");
    setQuantity("");
    closeModal();
  };
  const createRawMaterialInventory = async () => {
    setLoading(true);
    if (buyer_name === "") {
      toast.error(`Name cannot be empty`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (buyer_phone === "") {
      toast.error(`Phone number cannot be empty`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (quantity === "") {
      toast.error(`Quantity must be greater than zero`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (amount === "") {
      toast.error(`Total Price must be greater than zero`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (product === "") {
      toast.error(`Product cannot be empty`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (formMode === "CREATE_MODE") {
      const createObj = {
        buyer_name,
        buyer_phone,
        amount,
        quantity,
        unit_price,
        product,
      };
      const res = await CreateSales(createObj);
      if (res.success) {
        setLoading(false);
        toast.success("Sales record Created Successfully", {
          pauseOnHover: false,
        });
        closeSalesModal();
        requestAnimationFrame(() => {
          setLoading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setLoading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setLoading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
      setLoading(false);
    } else if (formMode === "UPDATE_MODE") {
      const updateObj = {
        id: one_sale?.id,
        buyer_name,
        buyer_phone,
        amount,
        quantity,
        unit_price,
        product,
      };
      const res = await UpdateSales(updateObj);
      if (res.success) {
        setLoading(false);
        toast.success("Sales Record Updated Successfully", {
          pauseOnHover: false,
        });
        closeSalesModal();
        requestAnimationFrame(() => {
          setLoading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setLoading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setLoading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
    }
  };

  const checkOnchange = (value) => {
    const getPrice = allProducts.find((item) => item?.id === value).price;
    setUnitPrice(getPrice);
    setProduct(value);
  };
  const productsChildren = allProducts?.map((obj) => {
    return (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    );
  });
  useEffect(() => {
    if (one_sale) {
      setProduct(one_sale?.product);
      setAmount(one_sale?.amount);
      setBuyerName(one_sale?.buyer_name);
      setBuyerPhone(one_sale?.buyer_phone);
      setUnitPrice(one_sale?.unit_price);
      setQuantity(one_sale?.quantity);
    }
  }, [one_sale]);

  useEffect(() => {
    if (one_sale) {
      setAmount(one_sale?.amount);
    } else {
      setAmount(unit_price * Number(quantity));
    }
  }, [unit_price, quantity]);

  const updatePhoneNumber = (event) => {
    const regex = /^[0-9]{0,13}$/;
    let num = event.target.value.replaceAll(" ", "");
    if (regex.test(num)) {
      let tempCardNumber = (
        num.slice(0, 3).replace(/(.{3})/g, "$1 ") +
        num.slice(3, 6).replace(/(.{3})/g, "$1 ") +
        num.slice(6, 10).replace(/(.{4})/g, "$1 ") +
        num.slice(10, 14).replace(/(.{4})/g, "$1 ")
      ).trim();
      setBuyerPhone(tempCardNumber);
    }
  };
  return (
    <>
      <Modal
        visible={visible}
        title={
          <p style={{ fontWeight: "bold" }}>
            {checkMode ? "Add New Purchase" : `Update New Purchase`}
          </p>
        }
        footer={null}
        onCancel={closeSalesModal}
        maskClosable={false}
        width={"500px"}
      >
        <form
          onSubmit={handleSubmit(createRawMaterialInventory)}
          autoComplete="off"
        >
          <div className="content">
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Buyer's Name</label>
                  <input
                    name="buyer_name"
                    className="ant-input ant-input-lg"
                    placeholder="Type in Name"
                    value={buyer_name}
                    onChange={(event) => setBuyerName(event?.target?.value)}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Buyer's Phone Number</label>
                  <input
                    name="buyer_phone"
                    className="ant-input ant-input-lg"
                    placeholder="Type in Number"
                    defaultValue={checkMode ? null : one_sale?.phone}
                    value={buyer_phone}
                    onChange={updatePhoneNumber}
                  />
                </Col>
              </Row>
            </div>{" "}
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Product</label>
                  <Controller
                    name="product"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        allowClear
                        name="product"
                        placeholder="Select a product"
                        style={{ width: "100%" }}
                        size="large"
                        value={product}
                        onChange={checkOnchange}
                        // defaultValue={checkMode ? null : product}
                      >
                        {productsChildren}
                      </Select>
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Product Price</label>
                  <input
                    name="unit_price"
                    className="ant-input ant-input-lg"
                    disabled
                    value={unit_price ? formatNumber(unit_price) : ""}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Quantity</label>
                  <input
                    name="quantity"
                    className="ant-input ant-input-lg"
                    placeholder="Type in Quantity"
                    type="number"
                    defaultValue={checkMode ? null : one_sale?.quantity}
                    value={quantity}
                    onChange={(event) => setQuantity(event?.target?.value)}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Total Price</label>
                  <input
                    name="total_price"
                    className="ant-input ant-input-lg"
                    value={
                      typeof amount === "string"
                        ? Number(amount).toLocaleString()
                        : formatNumber(JSON.stringify(amount))
                    }
                    // onChange={(event) => setAmount(event?.target?.value)}
                    onChange={(event) => {
                      const value = event.target.value.replace(/,/gi, "");

                      if (isNaN(value)) return;

                      setAmount(value);
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <Divider />
          <Row style={{ margin: "2rem 0" }}>
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                loading={loading}
                type="primary"
                onClick={handleSubmit(createRawMaterialInventory)}
                style={{ marginRight: "10px" }}
              >
                {formMode === "CREATE_MODE" ? "Save" : "Update"} Purchase
              </Button>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default CreateSalesModal;
