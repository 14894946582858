import React, { useState } from "react";

import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";

import {
  DashboardHeader,
  HeaderAddIcon,
  HeaderOptionCard,
  HeaderBottomCard,
  HeaderTopCard,
  HeaderOptionContent,
  HeaderOptionItem,
  HeaderOptionEachItem,
} from "../../styles/util/UtilStyle";
import FieldsModal from "./FieldsModal";
import { getUserFields } from "../../redux/actions/fieldManagement";
import UploadModal from "../dashboardHome/UploadModal";

import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import MenuIcon from "@mui/icons-material/Menu";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Header = (props) => {
  const [formBoxes, setFormBoxes] = useState({ open1: false, open2: false });
  const { access_token: access_key } = useSelector(
    (state) => state.authReducer
  );
  const [isFetching, setIsFetching] = useState(true);
  const [arrayOfFields, setArrayOfFields] = useState([]);

  const handleClickOpen = (pos) => {
    setFormBoxes({ open1: true, open2: false });
  };

  const handleClose = () => {
    setFormBoxes({ open1: false, open2: false });
  };

  const handleClickOpen2 = (pos) => {
    // empty the arrayOfFields upon every re-opening so it could refresh
    setArrayOfFields([]);
    setIsFetching(true);
    setFormBoxes({ open1: false, open2: true });

    async function fetchData() {
      let { isError, data } = await getUserFields(access_key);
      if (isError) {
        setIsFetching(true);
      } else {
        setArrayOfFields(data);
        setIsFetching(false);
      }
    }
    fetchData();
  };

  const handleClose2 = () => {
    setFormBoxes({ open1: false, open2: false });
  };

  return (
    <>
      <DashboardHeader>
        <HeaderTopCard></HeaderTopCard>
        <HeaderBottomCard>
          <HeaderOptionCard>
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  <HeaderAddIcon {...bindTrigger(popupState)}>
                    <MenuIcon />
                  </HeaderAddIcon>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <HeaderOptionContent>
                      <HeaderOptionItem onClick={handleClickOpen}>
                        <HeaderOptionEachItem>
                          <Add
                            style={{
                              color: "rgba(0, 106, 75, 1)",
                              borderRadius: "0.5em",
                              marginRight: "0.2em",
                            }}
                          />{" "}
                          Add Field
                        </HeaderOptionEachItem>
                      </HeaderOptionItem>

                      <HeaderOptionItem onClick={handleClickOpen2}>
                        <HeaderOptionEachItem>
                          <VisibilityIcon
                            style={{
                              color: "rgba(0, 106, 75, 1)",
                              borderRadius: "0.5em",
                              marginRight: "0.2em",
                            }}
                          />{" "}
                          View Fields
                        </HeaderOptionEachItem>
                      </HeaderOptionItem>
                    </HeaderOptionContent>
                  </Popover>
                </div>
              )}
            </PopupState>
          </HeaderOptionCard>
        </HeaderBottomCard>

        <FieldsModal
          isFetching={isFetching}
          setPath={props?.setPath}
          setCentroid={props?.setCentroid}
          open={formBoxes.open2}
          close={handleClose2}
          setFormBoxes={setFormBoxes}
          fieldsArray={arrayOfFields}
        />

        <UploadModal
          open={formBoxes.open1}
          setFormBoxes={setFormBoxes}
          close={handleClose}
        />
      </DashboardHeader>
    </>
  );
};

export default Header;
