import React from "react";

import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CloudIcon from "@mui/icons-material/Cloud";
import EventIcon from "@mui/icons-material/Event";

import {
  Cover2Found,
  Cover2NotFound,
  CoverDetectRange,
} from "../../styles/util/UtilStyle";
import { formatDate } from "../util/Util";

function NoCloud(props) {
  return (
    <>
      {!props?.isCloudPresent ? (
        <Cover2NotFound>
          <NotInterestedIcon
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />
        </Cover2NotFound>
      ) : (
        <Cover2Found>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontSize: "0.9em" }}>
              Clouds <CloudIcon style={{ fontSize: "1em" }} />
            </span>
            <span style={{ fontSize: "0.9em" }}>
              {props?.selFieldDetails?.size
                ? props?.selFieldDetails?.size +
                  " ha / " +
                  props?.cloudCover +
                  " %"
                : "No Field Selected"}
            </span>
          </div>
          <div>
            <CoverDetectRange type="range" disabled value={props?.cloudCover} />
          </div>
          <div>
            {props?.selectedDate ? (
              <>
                <EventIcon style={{ fontSize: "1em" }} />{" "}
                {formatDate(props?.selectedDate)}
              </>
            ) : (
              "No date selected"
            )}
          </div>
        </Cover2Found>
      )}
    </>
  );
}

export default NoCloud;
