import React, { createContext, useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import HomePage from "./pages/home/HomePage";
import DashboardHomePage from "./pages/dashBoard/DashboardHomePage";

//User Management
import { ResetPasswordPage, LoginPage, RegisterPage } from "./pages/auth";
import UserAccount from "./pages/user/UserAccount";
import ForgotPassword from "./components/auth/ForgotPassword";
import UserManagement from "./pages/user/UserManagement";
import ManageRole from "./pages/user/ManageRole";

// Weather Forecaste & Update
import { HistoryChartPage, WeatherPage } from "./pages/weather";

// Broken Link Pipe page here
import { BrokenLinkPage } from "./pages/errorHandling";

// Farm Activity
import { FarmSummaryInfoPage, FarmSummaryPage } from "./pages/farmSummary";
import ConfigureFarm from "./pages/farmactivity/ConfigureFarm";
import AllFarmActivityPage from "./pages/farmactivity/AllFarmActivityPage";
import SingleFarmDetails from "./pages/farmactivity/SingleFarmDetails";
import MyTaskCalendar from "./components/farmActivity/MyTaskCalendar";

// Crop Plan Development
import { CropPlanPage } from "./pages/cropPlan";

// Satellite Field Monitoring
import { SatelliteViewPage, SplitViewPage } from "./pages/satelliteField";
import { backDate, cloudLimit } from "./components/util/Util";

//Admin Pages
import LoginAdmin from "./pages/admin/LoginAdmin";
import AdminDashboardPage from "./pages/admin/AdminDashboardPage";
import Configuration from "./pages/admin/Configuration";
import ManageUser from "./pages/admin/ManageUser";
import Tracking from "./pages/admin/Tracking";
import AdminUserDetailsPage from "./pages/admin/AdminUserDetailsPage";
import PaymentRecord from "./pages/admin/PaymentRecords";
import AllTeamRecords from "./pages/admin/AllTeamRecords";
import SubscriptionRecords from "./pages/admin/SubscriptionRecords";
import UserSubscriptionPlans from "./components/Subscription/UserSubscriptionPlans";
import { content } from "./content";
import TestTransfer from "./pages/TestTransfer";
import Inventory from "./pages/admin/Inventory";
import Sales from "./pages/admin/Sales";
import Products from "./pages/admin/Products";
import RawMaterials from "./pages/admin/RawMaterials";

const UserRoute = ({ component: Component, ...rest }) => {
  // const { isLoggedIn } = useContext(AppContext);
  // extract the access key
  const { access_token: access_key } = useSelector(
    (state) => state.authReducer
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        access_key !== undefined ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  // const access_key = isUserLoggedIn();
  const { access_token: access_key } = useSelector(
    (state) => state.authReducer
  );
  // console.log('admin token', token);
  return (
    <Route
      {...rest}
      render={() =>
        !access_key ? <Redirect to="/auth/login" /> : <Component />
      }
    />
  );
};

export const AppContext = createContext({
  selectedCentroid: [],
  selectedCoords: [],
  selectedRealCoords: [],
  selectedFieldId: null,
  selFieldDetails: {},
  startDate: "",
  endDate: "",
  selectedDate: "",
  selSplitDate1: "",
  selSplitDate2: "",
  index: "",
  cloudCover: cloudLimit,
  isCloudPresent: true,
  isLoggedIn: false,
  handleIsLoggedIn: (_) => {},
  handleSelectedCentroid: (_) => {},
  handleSelectedCoords: (_) => {},
  handleSelectedRealCoords: (_) => {},
  handleStartDate: (_) => {},
  handleEndDate: (_) => {},
  handleIndex: (_) => {},
  handleSelDate: (_) => {},
  handleSelSplitDate1: (_) => {},
  handleSelSplitDate2: (_) => {},
  handleCloudCover: (_) => {},
  handleIsCloudPresent: (_) => {},
  handleSelFieldDetails: (_) => {},
  clearData: () => {},
});

const AppRoute = (props) => {
  const [selectedCentroid, setSelectedCentroid] = useState([8.6775, 9.0778]);

  const [selectedCoords, setSelectedCoords] = useState(
    JSON.parse(sessionStorage.getItem("selectedCoords")) !== null
      ? JSON.parse(sessionStorage.getItem("selectedCoords"))
      : []
  );

  const [selectedRealCoords, setSelectedRealCoords] = useState(
    JSON.parse(sessionStorage.getItem("selectedRealCoords")) !== null
      ? JSON.parse(sessionStorage.getItem("selectedRealCoords"))
      : []
  );

  const [selectedFieldId, setSelectedFieldId] = useState(
    JSON.parse(sessionStorage.getItem("selectedFieldId")) !== null
      ? JSON.parse(sessionStorage.getItem("selectedFieldId"))
      : ""
  );

  const [startDate, setStartDate] = useState(backDate(3));
  const [endDate, setEndDate] = useState(backDate(0));

  // --- For Dates clicked ---
  const [selectedDate, setSelectedDate] = useState("");
  const [selSplitDate1, setSelSplitDate1] = useState("");
  const [selSplitDate2, setSelSplitDate2] = useState("");

  const [index, setIndex] = useState("");
  const [cloudCover, setCloudCover] = useState(cloudLimit);
  const [isCloudPresent, setIsCloudPresent] = useState(true);

  const [selFieldDetails, setSelFieldDetails] = useState(
    JSON.parse(sessionStorage.getItem("selFieldDetails")) !== null
      ? JSON.parse(sessionStorage.getItem("selFieldDetails"))
      : {}
  );

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSelectedCentroid = (centroid) => {
    // swap X, Y to Y, X --> Lat Long

    if (centroid?.length >= 1) {
      let swappedCentroid = [centroid[1], centroid[0]];
      setSelectedCentroid(swappedCentroid);

      // keep the selected centroid on sessionstorage
      sessionStorage.setItem(
        "selectedCentroid",
        JSON.stringify(swappedCentroid)
      );
    } else {
      sessionStorage.setItem(
        "selectedCentroid",
        JSON.stringify(selectedCentroid)
      );
    }
  };

  const handleSelectedRealCoords = (coords) => {
    let refactoredCoords = [];

    if (coords?.length >= 2) {
      coords.map((eachCoord) => {
        if (eachCoord?.length >= 2) {
          let p = [eachCoord[0], eachCoord[1]];
          refactoredCoords.push(p);
        }
        return 0;
      });
      setSelectedRealCoords(refactoredCoords);
      sessionStorage.setItem(
        "selectedRealCoords",
        JSON.stringify(refactoredCoords)
      );
    } else {
      setSelectedRealCoords(selectedRealCoords);
      sessionStorage.setItem(
        "selectedRealCoords",
        JSON.stringify(refactoredCoords)
      );
    }
  };

  const handleSelectedCoords = (coords) => {
    if (coords?.length >= 2) {
      // swap the coordinate
      let swappedCoords = [];
      coords.map((eachPath) => {
        // check if each inner array is a pair or not
        if (eachPath.length >= 2) {
          let p = [eachPath[1], eachPath[0]];
          swappedCoords.push(p);
        }
        return 0;
      });
      setSelectedCoords(swappedCoords);
      sessionStorage.setItem("selectedCoords", JSON.stringify(swappedCoords));
    } else {
      // setSelectedCoords(selectedCoords);
      sessionStorage.setItem("selectedCoords", JSON.stringify(selectedCoords));
    }
  };

  // set the field Id for the whole app
  const handleSelectedFieldId = (fieldId) => {
    setSelectedFieldId(fieldId);
    sessionStorage.setItem("selectedFieldId", fieldId);
  };

  const handleIsLoggedIn = (state) => {
    setIsLoggedIn(state);
  };

  // handler to set the global start date
  const handleStartDate = (state) => {
    setStartDate(state);
  };

  const handleEndDate = (state) => {
    setEndDate(state);
  };

  const handleSelDate = (state) => {
    setSelectedDate(state);
  };

  const handleSelSplitDate1 = (state) => {
    setSelSplitDate1(state);
  };

  const handleSelSplitDate2 = (state) => {
    setSelSplitDate2(state);
  };

  const handleIndex = (state) => {
    setIndex(state);
  };

  const handleCloudCover = (state) => {
    setCloudCover(state);
  };

  const handleIsCloudPresent = (state) => {
    setIsCloudPresent(state);
  };

  const handleSelFieldDetails = (state) => {
    setSelFieldDetails(state);
    sessionStorage.setItem("selFieldDetails", JSON.stringify(state));
  };

  const clearData = () => {
    setSelectedCentroid([8.6775, 9.0778]);
    setSelectedCoords([]);
    setSelectedRealCoords([]);
    setSelectedFieldId(null);
    setStartDate(backDate(3));
    setEndDate(backDate(0));
    setIndex(null);
    setCloudCover(cloudLimit);
    setIsCloudPresent(true);
    setIsLoggedIn(false);
    setSelFieldDetails({});
    window.sessionStorage.clear();
  };

  useEffect(() => {
    content();
  }, []);

  return (
    <AppContext.Provider
      value={{
        selectedDate,
        handleSelDate,
        startDate,
        handleStartDate,
        endDate,
        handleEndDate,
        selSplitDate1,
        handleSelSplitDate1,
        selSplitDate2,
        handleSelSplitDate2,
        index,
        handleIndex,
        cloudCover,
        handleCloudCover,
        selectedFieldId,
        setSelectedFieldId,
        handleSelectedFieldId,
        selectedCentroid,
        handleSelectedCentroid,
        selectedCoords,
        handleSelectedCoords,
        selectedRealCoords,
        handleSelectedRealCoords,
        isLoggedIn,
        handleIsLoggedIn,
        isCloudPresent,
        handleIsCloudPresent,
        selFieldDetails,
        handleSelFieldDetails,
        clearData,
      }}
    >
      <BrowserRouter>
        <Switch>
          {/* A D M I N - R O U T E  */}
          <Route path="/admin/login" exact component={LoginAdmin} />
          <AdminRoute
            path="/admin/dashboard"
            exact
            component={AdminDashboardPage}
          />
          <AdminRoute
            path="/admin/configuration"
            exact
            component={Configuration}
          />
          <AdminRoute path="/admin/products" exact component={Products} />
          <AdminRoute
            path="/admin/raw-materials"
            exact
            component={RawMaterials}
          />
          <AdminRoute path="/admin/inventory" exact component={Inventory} />
          <AdminRoute path="/admin/sales" exact component={Sales} />
          <AdminRoute path="/admin/manage-users" exact component={ManageUser} />
          <AdminRoute
            path="/admin/all-payments"
            exact
            component={PaymentRecord}
          />
          <AdminRoute
            path="/admin/account-subscription"
            exact
            component={SubscriptionRecords}
          />
          <AdminRoute
            path="/admin/all-teams"
            exact
            component={AllTeamRecords}
          />
          <AdminRoute
            path="/admin/manage-users/:id"
            exact
            component={AdminUserDetailsPage}
          />
          <AdminRoute path="/admin/tracking" exact component={Tracking} />

          <UserRoute
            path={"/dashboard"}
            exact={true}
            component={DashboardHomePage}
          />
          <UserRoute
            path={"/dashboard/weather/forecast/*"}
            exact
            component={WeatherPage}
          />
          <UserRoute
            path={"/dashboard/weather/history/*"}
            exact={true}
            component={HistoryChartPage}
          />
          <UserRoute
            path={"/dashboard/farmsummary/"}
            exact={true}
            component={FarmSummaryPage}
          />
          <UserRoute
            path={"/dashboard/farmsummary/info/*"}
            exact={true}
            component={FarmSummaryInfoPage}
          />

          <UserRoute
            path="/dashboard/manage-user"
            exact={true}
            component={UserManagement}
          />
          <UserRoute
            path="/dashboard/manage-roles"
            exact={true}
            component={ManageRole}
          />
          <UserRoute
            path="/dashboard/my-account"
            exact
            component={UserAccount}
          />

          {/* Farm Activities Routes Here */}
          <UserRoute
            path="/dashboard/farmactivity/configure"
            exact
            component={ConfigureFarm}
          />
          <UserRoute
            path="/dashboard/farmactivity/all-activities"
            exact
            component={AllFarmActivityPage}
          />
          <UserRoute
            path="/dashboard/farmactivity/all-activities/:id"
            exact
            component={SingleFarmDetails}
          />
          <UserRoute
            path="/dashboard/farmactivity/my-tasks"
            exact
            component={MyTaskCalendar}
          />

          <UserRoute
            path="/subscription/plans"
            exact
            component={UserSubscriptionPlans}
          />
          {/* Crop Plan Development Routes Here */}
          <UserRoute
            path={"/dashboard/crop-plan/*"}
            exact={true}
            component={CropPlanPage}
          />

          {/* --- Satellite Field Monitoring Routes Here --- */}
          <UserRoute
            path={"/dashboard/satellite-view/"}
            exact={true}
            component={SatelliteViewPage}
          />
          <UserRoute
            // path={indexing?"/dashboard/split-view/":'/dashboard'}
            path={"/dashboard/split-view/"}
            exact={true}
            component={SplitViewPage}
          />

          {/* <UserRoute path={"/all-fields"} exact={true} Component={LeadershipBoard} /> */}
          {/* <UserRoute path={"/field/:id"} exact={true} Component={Field} /> */}
          <Route path="/" exact={true}>
            <Redirect to="/home" />
          </Route>

          <Route path="/home" exact={true} component={HomePage} />

          <Route path="/auth/fakereg" exact component={RegisterPage} />
          <Route path="/auth/login" exact component={LoginPage} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/test-transfer" exact component={TestTransfer} />
          <Route
            path="/reset-password/:token"
            exact
            component={ResetPasswordPage}
          />

          <Route path="*" exact component={BrokenLinkPage} />
        </Switch>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default AppRoute;
