import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import AOS from "aos";
import { useHistory } from "react-router-dom";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import { LogoutUser } from "../../redux/actions/authentication";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

const DashboardLayout = (props) => {
  let { LogoutUser } = props;
  let history = useHistory();
  const drawerWidth = 240;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  console.log({ theme: theme.mixins });

  const useStyles = makeStyles(() => ({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      // I added the height
      height: "4.5em",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 5,
      // width: theme.spacing(5) + 5,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(5) + 7,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      marginLeft: theme.spacing(5),
      [theme.breakpoints.up("sm")]: {
        // marginLeft: '3em'
        marginLeft: theme.spacing(5),
      },
      background: "#eff9f6",
      padding:
        history.location.pathname === "/dashboard/" ||
        history.location.pathname === "/dashboard" ||
        history.location.pathname === "/dashboard/satellite-view/" ||
        history.location.pathname === "/dashboard/satellite-view" ||
        history.location.pathname === "/dashboard/split-view/" ||
        history.location.pathname === "/dashboard/split-view"
          ? theme.spacing(0, 0, 0)
          : theme.spacing(7, 3, 3),
    },
  }));
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const { access_token: access_key, user_details: userdetails } = useSelector(
    (state) => state.authReducer
  );

  useEffect(() => {
    AOS.init({ duration: 600 });
  }, []);
  console.log({ open });
  useEffect(() => {
    if (!access_key && access_key === null) {
      history.push("/auth/login");
      toast.error(`Please login to continue`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  }, [access_key, history]);

  return (
    <>
      {!access_key ? (
        <div>Redirecting...</div>
      ) : (
        <div className={classes.root}>
          <Helmet>
            <title>{props?.pageTitle} | GIS</title>
          </Helmet>
          <Header
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            classes={classes}
            userdetails={userdetails}
            allFields={props.allFields}
            isHome={props.isHome}
            LogoutUser={LogoutUser}
          />
          <Sidebar
            open={open}
            classes={classes}
            theme={theme}
            handleDrawerClose={handleDrawerClose}
          />
        </div>
      )}

      <main className={classes.content}>{props.children}</main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    team_roles: state.userManagementReducer.team_roles,
    team_members: state.userManagementReducer.team_members,
    // ownTeam_id: state.authReducer.user_details?.owned_team?.id,
  };
};

export default connect(mapStateToProps, { LogoutUser })(DashboardLayout);
