import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@mui/styles";
import { Modal, Backdrop, DialogContent } from "@mui/material";

import {
  ModalCancel,
  ModalContainer,
  ModalTitle,
} from "../../styles/util/UtilStyle";
import AddFieldForm from "./addFieldForm";
import { Cancel } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    outline: "none",
  },
}));

const UploadModal = (props) => {
  const classes = useStyles();

  return (
    <>
      {/* Start of the toastify component */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* --- End of the Toastify Component --- */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props?.open}
        onClose={props?.close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          zIndex: "100000000",
        }}
      >
        <ModalContainer>
          <ModalCancel>
            <Cancel onClick={() => props?.setFormBoxes({ open1: false })} />
          </ModalCancel>
          <DialogContent>
            <ModalTitle>Select the field adding option</ModalTitle>

            <AddFieldForm
              setFormBoxes={props?.setFormBoxes}
              setPath={props?.setPath}
              setCentroid={props?.setCentroid}
            ></AddFieldForm>
          </DialogContent>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default UploadModal;
