import React, { useEffect, useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import { Col, Row, Card, Divider, Table } from "antd";
import { connect } from "react-redux";
import DashboardCard from "./DashboardCard";
import { GroupOutlined } from "@mui/icons-material";
import { Dashhead } from "./AdminStyle.styled";
import { toast } from "react-toastify";
import { formatDateObjectHandler, GetFromNowDate } from "../../../utils";
import { GetAllTrackingRecord } from "../../../redux/actions/adminOtherServices";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import InventoryChart from "./chartcomponents/InventoryChart";
import SalesChart from "./chartcomponents/SalesChart";
// import { toast } from 'react-toastify';

const AdminDashboard = (props) => {
  const {
    dashboardData,
    allTracking,
    GetAllTrackingRecord,
    allUsers,
    allServices,
    allSubscriptions,
    allTeams,
  } = props;
  const [loadingdata, setloadingdata] = useState(false);
  useEffect(() => {
    const getAllTrackedOrders = async () => {
      setloadingdata(true);
      const res = await GetAllTrackingRecord();
      if (res.success) {
        setloadingdata(false);
      } else {
        setloadingdata(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
    };
    getAllTrackedOrders();
  }, [GetAllTrackingRecord]);

  const formatNewRecord = allTracking?.map((rec) => {
    return {
      ...rec,
      team_member: rec?.team_member?.member?.email,
      first_name: rec?.team_member?.member?.first_name,
      last_name: rec?.team_member?.member?.last_name,
      service: rec?.service.name,
      service_name: rec?.service?.service,
      date_created: rec?.date_created,
    };
  });

  const trackingColumn = [
    {
      title: "Team Member",
      dataIndex: "team_member",
      width: "250px",
      render: (_text, record) => (
        <>
          <div>Name: {record.first_name + " " + record.last_name}</div>
          <span>
            <a href={`mailto:${record.team_member}`}>{record.team_member}</a>
          </span>
        </>
      ),
    },
    {
      title: "Service Details",
      dataIndex: "service_name",
      render: (_text, record) => (
        <>
          <div>{record.service}</div>
          <span>{record.service_name}</span>
        </>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      align: "center",
      render: (_text, record) => (
        <>
          {formatDateObjectHandler(record.date_created, "DD-MM-YYYY")} <br />
          <small style={{ color: "green" }}>
            {GetFromNowDate(
              record.date_created,
              "YYYY-MM-DDTHH:mm:ss",
              "fromNow"
            )}
          </small>
        </>
      ),
    },
  ];

  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <>
      <DefaultLayout browserTitle="Admin Dashboard">
        <div data-aos="fade-right">
          <Row>
            <Col xs={24}>
              <Dashhead>Dashboard</Dashhead>
            </Col>
          </Row>
          <Row gutter={28} justify="space-between">
            <Col xs={24} xl={6} lg={6}>
              <DashboardCard
                icon={<GroupOutlined />}
                title="Total Users"
                count={allUsers?.length}
              />
            </Col>
            <Col xs={24} xl={6} lg={6}>
              <DashboardCard
                icon={<GroupOutlined />}
                title="Total Services"
                count={allServices?.length}
                background={"rgba(123, 97, 255, 0.1)"}
              />
            </Col>
            <Col xs={24} xl={6} lg={6}>
              <DashboardCard
                icon={<GroupOutlined />}
                title="Total Subscriptions"
                count={allSubscriptions?.length}
                background={"rgba(123, 97, 255, 0.1)"}
              />
            </Col>
            <Col xs={24} xl={6} lg={6}>
              <DashboardCard
                icon={<GroupOutlined />}
                title="Total Teams"
                count={allTeams?.length}
                background={"rgba(123, 97, 255, 0.1)"}
              />
            </Col>
          </Row>
          <Row>
            <InventoryChart />
          </Row>
          <Row>
            <SalesChart />
          </Row>
          <Row gutter={28}>
            <Col xs={24} xl={24} lg={24}>
              <Card>
                <h5>Recent Tracking Activities</h5>
                <Divider />
                <Table
                  loading={loadingdata}
                  rowKey="id"
                  bordered
                  columns={trackingColumn}
                  dataSource={formatNewRecord}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </DefaultLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allTracking: state.adminOtherReducer.allTracking,
    allUsers: state.adminOtherReducer.allUsers,
    allServices: state.adminReducer.allServices,
    allSubscriptions: state.adminReducer.allSubscriptions,
    allTeams: state.adminOtherReducer.allTeams?.results,
  };
};
export default connect(mapStateToProps, { GetAllTrackingRecord })(
  AdminDashboard
);
