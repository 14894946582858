import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Modal, Row, Select, Switch } from "antd";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

const { Option } = Select;

const CreateServiceModal = (props) => {
  const {
    visible,
    formMode,
    one_service,
    ConfigureOneServices,
    UpdateServices,
    closeModal,
  } = props;
  const [loading, setloading] = useState(false);
  const [serviceObject, setServiceObject] = useState({});
  const [serviceName, setServiceName] = useState("---");
  const [service, setService] = useState(null);

  const checkMode = formMode === "CREATE_MODE";
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setServiceObject(one_service);
  }, [one_service]);

  const fakeServices = [
    {
      id: 12,
      title: "NDVI Service",
      name: "Index",
    },
    {
      id: 23,
      title: "Weather",
      name: "weather",
    },
    {
      id: 34,
      title: "Users",
      name: "users",
    },
    {
      id: 44,
      title: "Farm Area",
      name: "farm_area",
    },
  ];

  const closeService = () => {
    closeModal();
    reset();
  };

  const performServiceConfiguration = async (data) => {
    // console.log(data, 'in per');
    setloading(true);
    const { amount_per_unit, name } = data;
    if (amount_per_unit === "0") {
      toast.error(`Amount must be greater than zero`, {
        pauseOnHover: true,
      });
      setloading(false);
      return;
    }
    if (!Number(amount_per_unit)) {
      toast.error("Amount contain invalid values", {
        pauseOnHover: true,
      });
      setloading(false);
      return;
    }
    if (service === null) {
      toast.error("Service cannot be empty", {
        pauseOnHover: true,
      });
      setloading(false);
      return;
    }
    if (formMode === "CREATE_MODE") {
      const createObj = {
        // ...serviceObj,
        service,
        name,
        amount_per_unit: parseFloat(amount_per_unit),
        notification: !!data.notification,
      };
      const res = await ConfigureOneServices(createObj);
      if (res.success) {
        setloading(false);
        toast.success("Service Created Successfully", {
          pauseOnHover: false,
        });
        closeService();
        requestAnimationFrame(() => {
          reset();
          setloading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setloading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setloading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
    } else if (formMode === "UPDATE_MODE") {
      const updateObj = {
        id: one_service?.id,
        ...data,
        service,
        amount_per_unit: parseFloat(amount_per_unit),
      };
      const res = await UpdateServices(updateObj);
      if (res.success) {
        setloading(false);
        toast.success("Service Updated Successfully", {
          pauseOnHover: false,
        });
        closeService();
        requestAnimationFrame(() => {
          reset();
          setloading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setloading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setloading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
    }
  };

  const checkOnchange = (value) => {
    setService(value);
    if (value === "Index") {
      setServiceName("Index");
    } else if (value === "weather") {
      setServiceName("Unit");
    } else if (value === "users") {
      setServiceName("User");
    } else if (value === "farm_area") {
      setServiceName("Area");
    } else {
      setServiceName("---");
    }
  };

  const children = fakeServices?.map((obj) => {
    return (
      <Option key={obj.id} value={obj.name}>
        {obj.title}
      </Option>
    );
  });

  return (
    <>
      <Modal
        visible={visible}
        title={`${
          checkMode
            ? "Create New Service"
            : `Update Service: ${one_service?.name}`
        }`}
        footer={null}
        onCancel={closeService}
        maskClosable={false}
        width={"400px"}
      >
        <form
          onSubmit={handleSubmit(performServiceConfiguration)}
          autoComplete="off"
        >
          <div className="content">
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Services</label>
                  <Controller
                    name="service"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        allowClear
                        name="service"
                        placeholder="Select a service"
                        style={{ width: "100%" }}
                        size="large"
                        onChange={checkOnchange}
                        defaultValue={one_service?.service}
                      >
                        {children}
                      </Select>
                    )}
                  />
                  {/*{errors.service && (*/}
                  {/*    <span className="error">Service is required</span>*/}
                  {/*)}*/}
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Name</label>
                  <input
                    {...register("name")}
                    name="name"
                    className="ant-input ant-input-lg"
                    defaultValue={checkMode ? null : one_service?.name}
                    placeholder="Type in Name"
                  />
                  {errors.name && (
                    <span className="error">Name is required</span>
                  )}
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label style={{ display: "flex" }}>
                    Notification Required
                  </label>
                  <Controller
                    control={control}
                    name="notification"
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        defaultChecked={one_service?.notification}
                        checked={value}
                        name="notification"
                        onChange={onChange}
                        // value={value}
                      />
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Amount Per {serviceName}</label>
                  <Row gutter={10}>
                    <Col xs={6} xl={6} lg={6}>
                      <Select
                        size="large"
                        defaultValue="NGN"
                        style={{ width: "100%" }}
                        disabled
                      >
                        <Option value="NGN">NGN</Option>
                      </Select>
                    </Col>
                    <Col xs={18} xl={18} lg={18}>
                      <input
                        {...register("amount_per_unit", { required: true })}
                        className="ant-input ant-input-lg"
                        name="amount_per_unit"
                        defaultValue={one_service?.amount_per_unit}
                        placeholder={`Enter Amount Per ${serviceName}`}
                      />
                      {/*<Controller
                                                name="amount_per_unit"
                                                control={control}
                                                required={{ require: true }}
                                                render={({ field: { onChange, value } }) => (
                                                    <Input
                                                        size="large"
                                                        name="amount_per_unit"
                                                        defaultValue={one_service?.amount_per_unit}
                                                        placeholder="Enter Amount Per Hectare"
                                                        onChange={onChange}
                                                        value={formMode === 'CREATE' ? value : one_service?.amount_per_unit}
                                                    />
                                                )}
                                            />*/}
                    </Col>
                  </Row>
                  {errors.amount_per_unit && (
                    <span className="error">Amount is required</span>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <Divider />
          <Row style={{ margin: "2rem 0" }}>
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                loading={loading}
                type="primary"
                onClick={handleSubmit(performServiceConfiguration)}
                style={{ marginRight: "10px" }}
              >
                {formMode === "CREATE_MODE" ? "Save" : "Update"} Configuration
              </Button>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default CreateServiceModal;
