import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Modal, Row } from "antd";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const CreateProductModal = (props) => {
  const {
    visible,
    formMode,
    closeModal,
    one_product,
    CreateOneProduct,
    UpdateOneProduct,
  } = props;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");

  const checkMode = formMode === "CREATE_MODE";
  const { handleSubmit } = useForm();

  const closeProduct = () => {
    setName("");
    setPrice("");
    closeModal();
  };
  const createProduct = async () => {
    setLoading(true);
    if (name === "") {
      toast.error(`Product name cannot be empty`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (price === "") {
      toast.error(`Product price cannot be empty`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (formMode === "CREATE_MODE") {
      const createObj = {
        name,
        price,
      };
      const res = await CreateOneProduct(createObj);
      if (res.success) {
        setLoading(false);
        toast.success("Product Created Successfully", {
          pauseOnHover: false,
        });
        requestAnimationFrame(() => {
          closeProduct();
          setLoading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setLoading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setLoading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
      setLoading(false);
    } else if (formMode === "UPDATE_MODE") {
      const updateObj = {
        id: one_product?.id,
        name,
        price,
      };
      const res = await UpdateOneProduct(updateObj);
      if (res.success) {
        setLoading(false);
        toast.success("Product Updated Successfully", {
          pauseOnHover: false,
        });
        requestAnimationFrame(() => {
          closeProduct();
          setLoading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setLoading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setLoading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
    }
  };
  useEffect(() => {
    if (one_product) {
      setName(one_product?.name);
      setPrice(one_product?.price);
    }
  }, [one_product]);
  return (
    <>
      <Modal
        visible={visible}
        title={
          <p style={{ fontWeight: "bold" }}>
            {checkMode ? "Create Product" : `Update Product`}
          </p>
        }
        footer={null}
        onCancel={closeProduct}
        maskClosable={false}
        width={"400px"}
      >
        <form onSubmit={handleSubmit(createProduct)} autoComplete="off">
          <div className="content">
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Product Name</label>
                  <input
                    name="name"
                    className="ant-input ant-input-lg"
                    placeholder="Type in Name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Product Price</label>
                  <input
                    // {...register("price")}
                    name="price"
                    className="ant-input ant-input-lg"
                    placeholder="Type in Name"
                    // defaultValue={checkMode ? null : one_product?.price}
                    value={price ? Number(price).toLocaleString() : ""}
                    onChange={(event) => {
                      const value = event.target.value.replace(/,/gi, "");

                      if (isNaN(value)) return;

                      setPrice(value);
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <Divider />
          <Row style={{ margin: "2rem 0" }}>
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                loading={loading}
                type="primary"
                // type='submit'
                onClick={handleSubmit(createProduct)}
                style={{ marginRight: "10px" }}
              >
                {formMode === "CREATE_MODE" ? "Save" : "Update"} Product
              </Button>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default CreateProductModal;
