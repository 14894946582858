import React from "react";
import { Dropdown, Menu } from "antd";
import { CheckCircle, CancelRounded } from "@mui/icons-material";
import { MoreOutlined } from "@ant-design/icons";

export const TeamMenu = ({ record, ...props }) => {
  const menu = (
    <Menu className="tableaction team">
      {/*<Menu.Item key="0" onClick={() => props.getOneUser(record)}>*/}
      {/*    <Visibility /> View*/}
      {/*</Menu.Item>*/}
      {!record.admin_approved ? (
        <>
          <Menu.Item key="0" onClick={() => props.approveTeam(record)}>
            <CheckCircle /> Approve
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item key="1" onClick={() => props.revokeApproval(record)}>
            <CancelRounded /> Revoke
          </Menu.Item>
        </>
      )}
      {/*<Menu.Item key="2" onClick={() => props.assignService(record)}>*/}
      {/*    <Edit /> Edit*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item key="3" onClick={() => props.deleteOneUser(record)}>*/}
      {/*    <Delete /> Delete*/}
      {/*</Menu.Item>*/}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className="ant-dropdown-link">
        <MoreOutlined />
      </a>
    </Dropdown>
  );
};
