import React, { useEffect, useState } from "react";

import { Fade, Grid, Modal, Backdrop } from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

// import LoadingOverlay from "react-loading-overlay";

import SkeletonCard from "../../adapters/util/SkeletonCard";
import {
  PrevieBodyTitle,
  PreviewBody,
  PreviewCancelButton,
  PreviewCard,
  PreviewContentList,
  PreviewContentOrdering,
  PreviewContentTable,
  PreviewContentTableTd,
  PreviewContentTableTh,
  PreviewContentTableTr,
  PreviewContentText,
  PreviewContentTitle,
  PreviewDoc,
  PreviewDocButton,
  PreviewDocInner,
  PreviewPageSection,
  PreviewTitle,
} from "../../styles/farmManagement/FarmSummaryStyle";
import { getFieldReport } from "../../redux/actions/fieldManagement";
import GetMap from "../geoMap/GetMap";
import { IndexMapRender } from "./IndexMapRender";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    outline: "none",
  },
}));

const RenderTheMap = (props) => {
  return (
    <>
      {props?.index === "BASE" ? (
        <GetMap
          centroid={props?.selectedCentroid}
          polygonPath={props?.selectedCoords}
          zoomVal={10}
        />
      ) : (
        <IndexMapRender
          height={"20em"}
          cloudCover={props?.cloudCover}
          dateRange={`${props?.startDate}/${props?.endDate}`}
          selectedCentroid={props?.selectedCentroid}
          selectedCoords={props?.selectedCoords}
          index={props?.index}
          zoomVal={9}
        />
      )}
    </>
  );
};

const PreviewDownload = (props) => {
  const classes = useStyles();

  const { access_token: access_key } = useSelector(
    (state) => state.authReducer
  );
  const [previewData, setPreviewData] = useState([]);
  const [stillFetching, setStillFetching] = useState(true);

  let dataObject = {
    start_date: props?.startDate,
    end_date: props?.endDate,
  };

  useEffect(() => {
    async function fetchData() {
      let { isError, data } = await getFieldReport(
        dataObject,
        access_key,
        props?.fieldId
      );

      if (isError) {
        setStillFetching(true);
        toast.error("Ops! We could not generate a report", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setPreviewData(data[0]);
        setStillFetching(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.open]);

  const downloadNow = () => {
    window.print();
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props?.open}
        onClose={props?.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
        style={{ border: "none !important" }}
      >
        <Fade in={props?.open}>
          <PreviewBody>
            <PrevieBodyTitle>PREVIEW OF THE FILE GENERATED</PrevieBodyTitle>
            <PreviewDoc>
              <PreviewDocInner>
                {stillFetching ? (
                  // <LoadingOverlay
                  //   active={stillFetching}
                  //   spinner
                  //   text="Please wait while we are generating the report..."
                  // >
                  <SkeletonCard height="65vh" width="100%" />
                ) : (
                  // </LoadingOverlay>
                  <>
                    <PreviewTitle>
                      <b>{previewData?.name}</b> FARMLANDS IN{" "}
                      <b>
                        {previewData?.location + " , " + previewData?.state}
                      </b>{" "}
                      (Farm Index Number : {previewData?.id})
                    </PreviewTitle>

                    <Grid
                      container
                      spacing={3}
                      style={{ marginTop: "2em" }}
                      key="images"
                    >
                      <Grid item lg={6} md={6} xs={12} sm={6} key="1">
                        <PreviewCard>
                          <RenderTheMap
                            index={props?.index}
                            selectedCentroid={props?.selectedCentroid}
                            selectedCoords={props?.selectedCoords}
                            startDate={props?.startDate}
                            endDate={props?.endDate}
                            cloudCover={props?.cloudCover}
                          />
                          {/* <PrevieCardImg src={image1} alt='not seen' /> */}
                        </PreviewCard>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sm={6} key="2">
                        <PreviewCard>
                          <RenderTheMap
                            index={props?.index}
                            selectedCentroid={props?.selectedCentroid}
                            selectedCoords={props?.selectedCoords}
                            startDate={props?.startDate}
                            endDate={props?.endDate}
                            cloudCover={props?.cloudCover}
                          />
                        </PreviewCard>
                      </Grid>
                    </Grid>

                    <PreviewPageSection key="report1">
                      <PreviewContentTitle key="reportTitle">
                        Farm/Field Performance Report
                      </PreviewContentTitle>
                      <PreviewContentText key="reportText">
                        This report shows a Performance Summary Report- PSR for{" "}
                        <b>{previewData?.name}</b> located in{" "}
                        <b>{previewData?.location}</b> of{" "}
                        <b>{previewData?.state}</b> State. The area extent of
                        the farmland is <b>{previewData?.size}</b> hectare(s)
                      </PreviewContentText>
                      <PreviewContentTitle key="ag1">
                        Agronomic Information
                      </PreviewContentTitle>
                      {previewData?.crop_season?.length === 0 ? (
                        <>
                          <PreviewContentText key="ag2">
                            None Yet!
                          </PreviewContentText>
                        </>
                      ) : (
                        <>
                          {previewData?.crop_season.map(
                            (eachCropSeason, pos) => (
                              <PreviewContentText key={pos}>
                                {" "}
                                - This Crop reported planted is{" "}
                                <b>{eachCropSeason?.crop?.name}</b> of{" "}
                                <b>{eachCropSeason?.crop?.description}</b>{" "}
                                variety/specie during the{" "}
                                <b>{eachCropSeason?.season}</b> planting season.
                                During this period, from{" "}
                                <b>{eachCropSeason?.sowing_date}</b> to{" "}
                                <b>{eachCropSeason?.harvest_date}</b>,
                              </PreviewContentText>
                            )
                          )}
                        </>
                      )}
                    </PreviewPageSection>

                    <PreviewPageSection key="report2">
                      <PreviewContentTitle key="1">
                        Performance measures
                      </PreviewContentTitle>
                      <PreviewContentText key="2">
                        Overall performance for the period is{" "}
                        {previewData?.performance_percentage} %
                      </PreviewContentText>
                      <PreviewContentText key="3">
                        <i>
                          *Causes of changes in plant health could be as a
                          result of the following to mention a few
                        </i>
                      </PreviewContentText>
                      <PreviewContentOrdering>
                        <PreviewContentList key="1">
                          Change in water content as a result of local water
                          source e.g., river, stream, borehole or
                          quantity/duration of rainfall.
                        </PreviewContentList>
                        <PreviewContentList key="2">
                          Proper fertilizer application on crop
                        </PreviewContentList>
                        <PreviewContentList key="3">
                          Soil nutrient
                        </PreviewContentList>
                        <PreviewContentList key="4">
                          Weed invasion
                        </PreviewContentList>
                        <PreviewContentList key="5">
                          Pest invasion
                        </PreviewContentList>
                      </PreviewContentOrdering>

                      <PreviewContentTable>
                        <thead>
                          <PreviewContentTableTr key="1">
                            <PreviewContentTableTh key="01">
                              Report Title
                            </PreviewContentTableTh>
                            <PreviewContentTableTh key="02">
                              Report Value
                            </PreviewContentTableTh>
                            <PreviewContentTableTh key="03">
                              Report Title
                            </PreviewContentTableTh>
                            <PreviewContentTableTh key="04">
                              Report Value
                            </PreviewContentTableTh>
                          </PreviewContentTableTr>
                        </thead>
                        <tbody>
                          <PreviewContentTableTr key="2">
                            <PreviewContentTableTd key="11">
                              Anchor
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="12">
                              {previewData?.anchor
                                ? previewData?.anchor
                                : "None"}
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="13">
                              Variety
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="14">
                              {previewData?.crop_season[0]?.crop?.description
                                ? previewData?.crop_season[0]?.crop?.description
                                : "None"}
                            </PreviewContentTableTd>
                          </PreviewContentTableTr>
                          <PreviewContentTableTr key="3">
                            <PreviewContentTableTd key="21">
                              Farm Index Number
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="22">
                              {previewData?.id}
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="23">
                              Planting Season
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="24">
                              {previewData?.crop_season[0]?.season
                                ? previewData?.crop_season[0]?.season
                                : "None"}
                            </PreviewContentTableTd>
                          </PreviewContentTableTr>
                          <PreviewContentTableTr key="4">
                            <PreviewContentTableTd key="31">
                              LGA
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="32">
                              {"" + previewData?.location}
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="33">
                              Starting Date
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="34">
                              {previewData?.crop_season[0]?.sowing_date
                                ? previewData?.crop_season[0]?.sowing_date
                                : "None"}
                            </PreviewContentTableTd>
                          </PreviewContentTableTr>
                          <PreviewContentTableTr key="5">
                            <PreviewContentTableTd key="41">
                              State
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="42">
                              {"" + previewData?.state}
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="43">
                              Ending Date
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="44">
                              {previewData?.crop_season[0]?.harvest_date
                                ? previewData?.crop_season[0]?.harvest_date
                                : "None"}
                            </PreviewContentTableTd>
                          </PreviewContentTableTr>
                          <PreviewContentTableTr key="6">
                            <PreviewContentTableTd key="51">
                              Area
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="52">
                              {previewData?.size}
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="53">
                              Average
                            </PreviewContentTableTd>
                            <PreviewContentTableTd>
                              {previewData?.ndvi_average
                                ? previewData?.ndvi_average
                                : "None"}
                            </PreviewContentTableTd>
                          </PreviewContentTableTr>
                          <PreviewContentTableTr key="7">
                            <PreviewContentTableTd key="61">
                              Crop
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="62">
                              {previewData?.crop_season[0]?.crop?.name
                                ? previewData?.crop_season[0]?.crop?.name
                                : "None"}
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="63">
                              Percentage Performance
                            </PreviewContentTableTd>
                            <PreviewContentTableTd key="64">
                              {previewData?.performance_percentage} %
                            </PreviewContentTableTd>
                          </PreviewContentTableTr>
                        </tbody>
                      </PreviewContentTable>
                    </PreviewPageSection>

                    <PreviewPageSection key="cancelDownload">
                      <Grid container spacing={3} style={{ marginTop: "2em" }}>
                        <Grid item lg={6} md={6} xs={6} sm={6} key="cancel">
                          <PreviewCancelButton onClick={props?.handleClose}>
                            Cancel
                          </PreviewCancelButton>
                        </Grid>
                        <Grid item lg={6} md={6} xs={6} sm={6} key="download">
                          <PreviewDocButton onClick={downloadNow}>
                            Download File
                          </PreviewDocButton>
                        </Grid>
                      </Grid>
                    </PreviewPageSection>
                  </>
                )}
              </PreviewDocInner>
            </PreviewDoc>
          </PreviewBody>
        </Fade>
      </Modal>
    </>
  );
};

export default PreviewDownload;
