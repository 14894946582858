import Skeleton from "@mui/material/Skeleton";

import { Box, Card, CardActionArea, Paper, Grid } from "@mui/material";

const SkeletonCard = (props) => {
  return (
    <>
      <Grid item xs={props.xs} lg={props.lg} md={props.md}>
        <Paper style={{ width: props.width, marginTop: props.top }}>
          <Card>
            <CardActionArea>
              <Skeleton
                variant="rect"
                style={{
                  height: props.height,
                }}
              />

              <Box pt={2}>
                <Skeleton />
                <Skeleton width="100%" />
              </Box>
            </CardActionArea>
          </Card>
        </Paper>
      </Grid>
    </>
  );
};

export default SkeletonCard;
