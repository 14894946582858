import React, { useState } from "react";

import { BasemapLayer } from "react-esri-leaflet";
import { MapContainer, WMSTileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import L from "leaflet";

// import LoadingOverlay from "react-loading-overlay";

import { useEffect } from "react";
import SwitchButton from "./SwitchButton";
import GetMapInfo from "../geoMap/GetMapInfo";
import getAvailableDates from "../../adapters/map/getAvailableDates";
import DownloadButton from "./DownloadButton";

import { cloudLimit, mapDatesHandler, wmsKey } from "../util/Util";
import NoCloud from "./NoCloud";
import { useContext } from "react";
import { AppContext } from "../../appRouter";

const RenderMap = (props) => {
  let basePolygon = React.useRef();

  const { selectedFieldId } = useContext(AppContext);

  let geometry = "";

  if (props?.selectedCoords.length > 0) {
    geometry = `POLYGON ((${props?.selectedRealCoords
      .map((coord) => coord.join(" "))
      .join(", ")}))`;
  }

  const [isMapLoading, setIsMapLoading] = useState(true);

  const [tooltipData, setTooltipData] = useState({
    position: [],
    text: "Click any point to read value",
  });
  const [cloudCover, setCloudCover] = useState(props?.cloudCover);

  const [layerKey, setLayerKey] = useState(true);
  const [curLayer, setCurLayer] = useState(props?.layer);
  // const [map, setMap] = useState(null);

  useEffect(() => {
    if (props?.refresh) {
      setIsMapLoading(props?.refresh);
      setCloudCover(props?.cloudCover);
      setCurLayer(props?.layer);
      let key =
        props?.cloudCover + props?.startDate + props?.endDate + props?.layer;
      setLayerKey(key);
    }
  }, [
    props?.cloudCover,
    props?.refresh,
    props?.startDate,
    props?.endDate,
    props?.layer,
  ]);

  useEffect(() => {
    setIsMapLoading(true);

    if (props?.changeDates) {
      let getResp = getAvailableDates(
        props?.startDate,
        props?.endDate,
        props?.selectedCentroid
      );

      getResp.then(({ isError, data }) => {
        if (isError) {
          setIsMapLoading(false);
          props?.setDates([]);
        } else {
          setIsMapLoading(false);
          let filteredDates = mapDatesHandler(data?.features);
          props?.setDates(filteredDates);
        }
      });
    } else {
      setIsMapLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props?.startDate,
    props?.endDate,
    props?.selectedRealCoords,
    props?.changeDates,
  ]);

  return (
    <>
      {/* <LoadingOverlay
        active={isMapLoading}
        spinner
        text="Please wait while we are loading the map."
      > */}
      <MapContainer
        // whenCreated={setMap}
        attributionControl={false}
        scrollWheelZoom={false}
        center={[props?.selectedCentroid[1], props?.selectedCentroid[0]]}
        zoom={10}
        zoomControl={true}
        style={{
          height: "75vh",
          width: "100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BasemapLayer name="ImageryFirefly" />

        {/* ---- Check if layer has been selected and cloudcover doesn't go beyond limit and as well the field has been selected too */}
        {curLayer && props?.cloudCover <= cloudLimit && selectedFieldId && (
          <WMSTileLayer
            // version="1.3.0"
            key={layerKey}
            layers={curLayer}
            format="image/png"
            crs={L.CRS.EPSG4326}
            attribution='&copy; <a href="http://www.sentinel-hub.com/" target="_blank">Sentinel Hub</a>'
            url={`https://services.sentinel-hub.com/ogc/wms/${wmsKey}?REQUEST=GetMap`}
            geometry={geometry}
            maxcc={props?.cloudCover}
            minZoom="0"
            maxZoom="500"
            zoom={12}
            preset={curLayer}
            width="100"
            height="100"
            time={`${props?.selectedDate}`}
            transparent="true"
          />
        )}

        <GetMapInfo
          basePolygon={basePolygon}
          setIsMapLoading={setIsMapLoading}
          setTooltipData={setTooltipData}
          tooltipData={tooltipData}
          selectedCoords={props?.selectedCoords}
          cloudCover={cloudCover}
          setCurLayer={setCurLayer}
          curLayer={curLayer}
          time={`${props?.selectedDate}`}
        />

        {props?.redrType === "dashboard" ? (
          <SwitchButton redr="/dashboard/" redrType={props?.redrType} />
        ) : (
          <SwitchButton
            redr="/dashboard/satellite-view/"
            redrType={props?.redrType}
          />
        )}

        <DownloadButton
          selectedCoords={props?.selectedCoords}
          selectedRealCoords={props.selectedRealCoords}
          cloudCover={props?.cloudCover}
          selectedCentroid={props?.selectedCentroid}
          startDate={props?.startDate}
          endDate={props?.endDate}
          layer={curLayer}
          setIsMapLoading={setIsMapLoading}
        />

        <NoCloud {...props} />
      </MapContainer>
      {/* </LoadingOverlay> */}
    </>
  );
};

const SingleView = (props) => {
  return (
    <>
      <RenderMap {...props} />
    </>
  );
};
export default SingleView;
