import React, { useContext, useState, useEffect } from "react";

import GetAppIcon from "@mui/icons-material/GetApp";

import { Divider, FormControl, Grid, InputLabel, Select } from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  FarmSummaryMap,
  FarmSummaryDownloadBtn,
  FarmSummaryEachNav,
  FarmSummaryNav,
  FarmSummaryNavInner,
  FarmInfoCard,
  FarmSideBarSecondLeft,
  FarmSideBarSecondTitle,
  FarmSideBarSecondSubtitle,
  FarmSideBarSecondText,
  FarmSideBarSecondRight,
  FarmCropRotation,
  FarmInfoLink,
  FarmSideBarFourthCardTitle,
  FarmSideBarFourthCardTItle,
  FarmSideBarFourthCardText,
  FarmDataNotFound,
  FarmTasksContainer,
  FarmResponsiveCard,
} from "../../styles/farmManagement/FarmSummaryStyle";

import DashboardLayout from "../Layout/DashboardLayout";

import SkeletonCard from "../../adapters/util/SkeletonCard";
// import { Line } from 'react-chartjs-2';

import GetMap from "../geoMap/GetMap";

import PreviewDownload from "./PreviewDownload";
import ShowMoreModal from "./ShowMoreModal";

import { IndexMapRender } from "./IndexMapRender";

import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  Bar,
  BarChart,
} from "recharts";
import { Link } from "react-router-dom";

import { AppContext } from "../../appRouter";
import {
  HistoryCalendarBox,
  HistoryCalendarPickDate,
  HistoryCalendarTitle,
} from "../../styles/weather/WeatherStyle";
import { backDate, convertDate } from "../util/Util";
import TodayWeather from "./TodayWeather";

const RenderTheMap = (props) => {
  return (
    <>
      {props?.index === "BASE" ? (
        <GetMap
          centroid={props?.centroid}
          polygonPath={props?.coords}
          zoomVal={10}
        />
      ) : (
        <>
          {props?.index === "TRUE-COLOR" ? (
            <IndexMapRender
              cloudCover={""}
              dateRange={""}
              selectedCentroid={props?.centroid}
              selectedCoords={props?.coords}
              index={"TRUE-COLOR"}
              height={"25em"}
              zoomVal={9}
            />
          ) : (
            <IndexMapRender
              cloudCover={props?.cloudCover}
              dateRange={`${props?.startDate}/${props?.endDate}`}
              selectedCentroid={props?.centroid}
              selectedCoords={props?.coords}
              index={props?.index}
              height={"25em"}
              zoomVal={9}
            />
          )}
        </>
      )}
    </>
  );
};

const FarmSummaryInfo = (props) => {
  const {
    startDate,
    endDate,
    cloudCover,
    handleSelectedFieldId,
    handleStartDate,
    handleEndDate,
    handleSelectedCentroid,
    handleSelectedCoords,
    handleSelectedRealCoords,
  } = useContext(AppContext);

  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");

  const [open, setOpen] = useState(false);
  const [openShowMore, setOpenShowMore] = useState(false);
  const [selCrop, setSelCrop] = useState([]);
  const [selIndex, setSelIndex] = useState("BASE");

  const handleSelIndex = (e) => {
    setSelIndex(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowMore = (cropInfo) => {
    setOpenShowMore(true);
    setSelCrop(cropInfo);
  };

  const handleCloseMore = () => {
    setOpenShowMore(false);
    setSelCrop([]);
  };

  const handleOpen = () => {
    if (startDate !== "" && endDate !== "") {
      setOpen(true);
    } else {
      toast.warning("Please select a valid date range!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // --- set the global centroid of the selected field here just the same way the global field id was set
  useEffect(() => {
    handleSelectedCentroid(props?.centroid);
    handleSelectedRealCoords(props?.coords);
    handleSelectedCoords(props?.coords);
    handleSelectedFieldId(props?.fieldId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.centroid]);

  const data = props?.chartData;
  // --- End implementing the Weather part here ---

  const [indexList, setIndexList] = useState([
    { id: 1, name: "Choose Index" },
    { id: 2, name: "NDMI", value: "NDMI" },
    { id: 3, name: "NDWI", value: "NDWI" },
  ]);

  const handleIndexType = (e) => {
    if (e === "Vegetative") {
      setIndexList([
        { id: 1, name: "Choose Index", value: "None" },
        { id: 11, name: "NDVI", value: "NDVI" },
        { id: 22, name: "LAI", value: "LAI" },
        { id: 33, name: "SAVI", value: "SAVI" },
      ]);
    } else {
      setIndexList([
        { id: 1, name: "Choose Index", value: "None" },
        { id: 14, name: "NDMI", value: "NDMI" },
        { id: 12, name: "NDWI", value: "NDWI" },
      ]);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <DashboardLayout pageTitle="Farm Field Info">
        <h4 style={{ marginTop: "2em" }}>
          Farm Field Details For {props?.fieldData?.name}
        </h4>

        <FarmSummaryNav style={{ marginTop: "2em", minHeight: "7em" }}>
          <FarmSummaryNavInner
            style={{
              marginLeft: "1em",
              marginRight: "1em",
              marginBottom: "1em",
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={2} md={2} xs={6} sm={3}>
                <FarmSummaryEachNav>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: "100%",
                      color: "#05674a",
                      border: "1px solid rgba(255,255,255,0.3)",
                      borderRadius: "0.3em",
                    }}
                  >
                    <InputLabel
                      htmlFor="outlined-age-native-simple"
                      style={{ color: "#05674a" }}
                    >
                      Index Type
                    </InputLabel>
                    <Select
                      native
                      defaultValue={"Moisture"}
                      label="Index Type"
                      onChange={(e) => handleIndexType(e.target.value)}
                      style={{
                        border: "1px solid rgba(255,255,255,1) !important",
                        color: "#05674a",
                        height: "3em",
                      }}
                    >
                      <option
                        defaultValue={"Moisture"}
                        style={{ color: "#05674a" }}
                      >
                        Moisture
                      </option>
                      <option
                        defaultValue={"Vegetative"}
                        style={{ color: "#05674a" }}
                      >
                        Vegetative
                      </option>
                    </Select>
                  </FormControl>
                </FarmSummaryEachNav>
              </Grid>

              <Grid item lg={2} md={2} xs={6} sm={3}>
                <FarmSummaryEachNav>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: "100%",
                      color: "white",
                      border: "1px solid rgba(255,255,255,0.3)",
                      borderRadius: "0.3em",
                    }}
                  >
                    <InputLabel
                      htmlFor="outlined-age-native-simple"
                      style={{ color: "#05674a", marginTop: "0.5em" }}
                    >
                      Index
                    </InputLabel>
                    <Select
                      native
                      defaultValue={"BASE"}
                      label="Index"
                      style={{
                        border: "1px solid rgba(255,255,255,1) !important",
                        color: "#05674a",
                        height: "3em",
                      }}
                      onChange={handleSelIndex}
                    >
                      {indexList?.map((eachItem) => (
                        <option
                          key={eachItem?.name}
                          value={eachItem?.value}
                          style={{ color: "#05674a" }}
                        >
                          {eachItem?.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </FarmSummaryEachNav>
              </Grid>

              <Grid item lg={3} md={3} xs={6} sm={6}>
                <HistoryCalendarBox style={{ marginTop: "1em" }}>
                  <HistoryCalendarTitle>Starting From:</HistoryCalendarTitle>
                  <HistoryCalendarPickDate
                    value={startDate}
                    type="date"
                    max={endDate}
                    onInput={(e) => handleStartDate(e.target.value)}
                  />
                </HistoryCalendarBox>
              </Grid>

              <Grid item lg={3} md={3} xs={6} sm={6}>
                <HistoryCalendarBox style={{ marginTop: "1em" }}>
                  <HistoryCalendarTitle>Ending To:</HistoryCalendarTitle>
                  <HistoryCalendarPickDate
                    value={endDate}
                    type="date"
                    max={backDate(0)}
                    onInput={(e) => handleEndDate(e.target.value)}
                  />
                </HistoryCalendarBox>
              </Grid>

              <Grid item lg={2} md={2} xs={12} sm={6}>
                <FarmSummaryEachNav>
                  <FarmSummaryDownloadBtn onClick={handleOpen}>
                    PDF <GetAppIcon />
                  </FarmSummaryDownloadBtn>
                </FarmSummaryEachNav>
              </Grid>
            </Grid>
          </FarmSummaryNavInner>
        </FarmSummaryNav>

        <Grid container spacing={4} style={{ marginTop: "2em" }}>
          <Grid item>
            <h6
              style={{
                marginTop: "2em",
                marginBottom: "2em",
                color: "#05674a",
              }}
            >
              {selIndex} MAP
            </h6>

            <FarmSummaryNav>
              <FarmSummaryNavInner style={{ marginBottom: "0em" }}>
                <FarmInfoCard style={{ marginTop: "0em" }}>
                  {/* <FarmSummaryTitle>{selIndex} MAP</FarmSummaryTitle> */}
                  <FarmSummaryMap>
                    {props?.stillFetching1 ? (
                      <SkeletonCard height={"25em"} />
                    ) : (
                      <>
                        <RenderTheMap
                          centroid={props?.centroid}
                          coords={props?.coords}
                          index={selIndex}
                          startDate={startDate}
                          endDate={endDate}
                          cloudCover={cloudCover}
                        />
                      </>
                    )}
                  </FarmSummaryMap>
                </FarmInfoCard>
              </FarmSummaryNavInner>
            </FarmSummaryNav>
          </Grid>
          <Grid item lg={6}>
            <h6
              style={{
                marginTop: "2em",
                marginBottom: "2em",
                color: "#05674a",
              }}
            >
              WEATHER FORECAST
            </h6>

            <FarmSummaryNav>
              <FarmSummaryNavInner style={{ marginBottom: "0em" }}>
                <FarmInfoCard style={{ marginTop: "0em" }}>
                  {/* <FarmSummaryTitle>WEATHER CHART</FarmSummaryTitle> */}
                  {props?.stillFetching1 ? (
                    <SkeletonCard height={"25em"} />
                  ) : (
                    <FarmSummaryMap style={{ background: "white" }}>
                      <ResponsiveContainer width={"100%"} height="100%">
                        <BarChart
                          data={data}
                          margin={{ top: 15, right: 30, bottom: 35 }}
                        >
                          <CartesianGrid strokeDasharray="1 1" />
                          <XAxis
                            tickFormatter={convertDate}
                            angle={45}
                            textAnchor="start"
                            dataKey="date"
                            stroke="rgba(1, 28, 20, 1)"
                          />
                          <YAxis stroke="rgba(1, 28, 20, 1)" />
                          <Tooltip
                            cursor={{ fill: "transparent" }}
                            wrapperStyle={{ color: "rgba(1, 28, 20, 1)" }}
                            itemStyle={{ color: "rgba(1, 28, 20, 1)" }}
                          />
                          <Legend verticalAlign="top" height={50} />

                          <Bar
                            name="Precipitation"
                            fillOpacity={1}
                            type="monotone"
                            dataKey="precipitation"
                            stroke="#2F7A64"
                            fill="#2F7A64"
                          />
                          <Bar
                            name="Cloud over"
                            fillOpacity={1}
                            type="monotone"
                            dataKey="cloud_over"
                            stroke="#6CADEA"
                            fill="#6CADEA"
                          />
                          <Bar
                            name="Wind Speed"
                            fillOpacity={1}
                            type="monotone"
                            dataKey="windspeed"
                            stroke="rgba(1, 28, 20, 1)"
                            fill="rgba(1, 28, 20, 1)"
                          />
                          <Bar
                            name="Max Temp"
                            fillOpacity={1}
                            type="monotone"
                            dataKey="max_temperature"
                            stroke="#82ca9d"
                            fill="#82ca9d"
                          />
                          <Bar
                            name="Humidity"
                            fillOpacity={1}
                            type="monotone"
                            dataKey="Humidity"
                            stroke="rgba(200,0,0,1)"
                            fill="rgba(200,0,0,1)"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </FarmSummaryMap>
                  )}
                </FarmInfoCard>
              </FarmSummaryNavInner>
            </FarmSummaryNav>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item md={6}>
            <h6
              style={{
                marginTop: "2em",
                marginBottom: "2em",
                color: "#05674a",
              }}
            >
              CROP SEASONS
            </h6>
            <FarmSummaryNav>
              <FarmSummaryNavInner>
                <FarmInfoCard style={{ marginTop: "0em", marginBottom: "2em" }}>
                  {/* <FarmSummaryTitle>CROP SEASONS</FarmSummaryTitle> */}
                  {props?.stillFetching1 ? (
                    <SkeletonCard height={"25em"} />
                  ) : (
                    <>
                      {props?.cropSeasons?.length === 0 ? (
                        <>
                          <FarmDataNotFound>
                            No Crop Added Yet!
                          </FarmDataNotFound>
                        </>
                      ) : (
                        <FarmResponsiveCard>
                          {props?.cropSeasons?.map((eachCrop, index) => (
                            <FarmCropRotation key={index}>
                              <FarmSideBarSecondLeft>
                                <FarmSideBarSecondTitle>
                                  Crop Seasons
                                </FarmSideBarSecondTitle>
                                <FarmSideBarSecondSubtitle>
                                  Season {eachCrop?.season}
                                </FarmSideBarSecondSubtitle>
                                <FarmSideBarSecondText>
                                  {eachCrop?.crop?.name}
                                </FarmSideBarSecondText>
                              </FarmSideBarSecondLeft>

                              <FarmSideBarSecondRight>
                                <FarmInfoLink
                                  onClick={() => handleShowMore(eachCrop)}
                                >
                                  View More
                                </FarmInfoLink>
                                <FarmSideBarSecondSubtitle>
                                  Sowing Date
                                </FarmSideBarSecondSubtitle>
                                <FarmSideBarSecondText>
                                  {eachCrop?.sowing_date}
                                </FarmSideBarSecondText>
                              </FarmSideBarSecondRight>
                            </FarmCropRotation>
                          ))}
                        </FarmResponsiveCard>
                      )}
                    </>
                  )}
                </FarmInfoCard>
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} xs={6} sm={6}>
                    <Link
                      to={`/dashboard/crop-plan/config/farm/${props?.fieldData?.id}`}
                    >
                      <FarmInfoLink style={{ textDecoration: "underline" }}>
                        MANAGE CROPS
                      </FarmInfoLink>
                    </Link>
                  </Grid>
                  <Grid item lg={6} md={6} xs={6} sm={6}></Grid>
                </Grid>
              </FarmSummaryNavInner>
            </FarmSummaryNav>
          </Grid>

          <Grid item xs={12}>
            <h6
              style={{
                marginTop: "2em",
                marginBottom: "2em",
                color: "#05674a",
              }}
            >
              WEATHER TODAY
            </h6>

            <FarmSummaryNav>
              <FarmSummaryNavInner>
                {/* ---Import the weather card here ----- */}
                <TodayWeather {...props} />
                <Grid container spacing={4}>
                  <Grid item lg={6} md={6} xs={6} sm={6}>
                    <Link
                      to={`/dashboard/weather/forecast/${props?.fieldData?.centroid}`}
                    >
                      <FarmInfoLink style={{ textDecoration: "underline" }}>
                        VIEW FORECAST
                      </FarmInfoLink>
                    </Link>
                  </Grid>
                  <Grid item lg={6} md={6} xs={6} sm={6}></Grid>
                </Grid>
              </FarmSummaryNavInner>
            </FarmSummaryNav>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12}>
            <h6
              style={{
                marginTop: "2em",
                marginBottom: "2em",
                color: "#05674a",
              }}
            >
              TASKS
            </h6>

            <FarmSummaryNav>
              <FarmSummaryNavInner>
                <FarmInfoCard style={{ marginTop: "0em", marginBottom: "2em" }}>
                  <FarmSideBarFourthCardTitle>
                    Farm Activities
                  </FarmSideBarFourthCardTitle>
                  <Divider />
                  {props?.stillFetching1 ? (
                    <SkeletonCard height={"25em"} />
                  ) : (
                    <>
                      {props?.activityData?.length === 0 ? (
                        <FarmResponsiveCard>
                          <FarmDataNotFound>No Tasks Yet!</FarmDataNotFound>
                        </FarmResponsiveCard>
                      ) : (
                        <FarmResponsiveCard>
                          {props?.activityData.map((eachTask) => (
                            <FarmTasksContainer
                              key={eachTask?.id}
                              style={{ marginTop: "1em" }}
                            >
                              <Grid container spacing={0}>
                                <Grid
                                  item
                                  lg={6}
                                  md={6}
                                  xs={6}
                                  sm={6}
                                  key={"example"}
                                >
                                  <FarmSideBarFourthCardTItle
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "1.2em",
                                    }}
                                  >
                                    {eachTask?.name}
                                  </FarmSideBarFourthCardTItle>
                                  <FarmSideBarFourthCardText
                                    style={{ lineHeight: "2em" }}
                                  >
                                    {eachTask?.description}
                                  </FarmSideBarFourthCardText>
                                </Grid>

                                <Grid
                                  item
                                  lg={6}
                                  md={6}
                                  xs={6}
                                  sm={6}
                                  key={"exampleDate"}
                                  style={{ textAlign: "right" }}
                                >
                                  <FarmSideBarFourthCardTItle
                                    style={{
                                      color: "",
                                      fontWeight: "bold",
                                      marginRight: "0.5em",
                                    }}
                                  >
                                    {eachTask?.start_date +
                                      " - " +
                                      eachTask?.end_date}
                                  </FarmSideBarFourthCardTItle>
                                </Grid>
                              </Grid>
                              <hr />
                            </FarmTasksContainer>
                          ))}
                        </FarmResponsiveCard>
                      )}
                    </>
                  )}
                </FarmInfoCard>

                <FarmInfoCard style={{ marginBottom: "1em" }}>
                  <FarmSummaryMap>
                    <RenderTheMap
                      centroid={props?.centroid}
                      coords={props?.coords}
                      index={"TRUE-COLOR"}
                      startDate={startDate}
                      endDate={endDate}
                      cloudCover={cloudCover}
                    />
                  </FarmSummaryMap>
                </FarmInfoCard>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xs={6} sm={6}>
                    <Link
                      to={`/dashboard/farmactivity/all-activities/${props?.fieldData?.id}`}
                    >
                      <FarmInfoLink style={{ textDecoration: "underline" }}>
                        VIEW ALL TASKS
                      </FarmInfoLink>
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={6}
                    sm={6}
                    style={{ float: "right", textAlign: "center" }}
                  >
                    <Link to={`/dashboard/satellite-view/`}>
                      <FarmInfoLink style={{ textDecoration: "underline" }}>
                        VIEW FULL SATELLITE
                      </FarmInfoLink>
                    </Link>
                  </Grid>
                </Grid>
              </FarmSummaryNavInner>
            </FarmSummaryNav>
          </Grid>
        </Grid>

        {/* <FarmInfoModal stillFetchingWeather={props?.stillFetching1} weatherData={props?.weatherData} /> */}

        <PreviewDownload
          handleClose={handleClose}
          open={open}
          cloudCover={cloudCover}
          index={selIndex}
          endDate={endDate}
          startDate={startDate}
          selectedCoords={props?.coords}
          selectedCentroid={props?.centroid}
          fieldId={props?.fieldId}
        />
        <ShowMoreModal
          handleClose={handleCloseMore}
          open={openShowMore}
          cropInfo={selCrop}
        />
      </DashboardLayout>
    </>
  );
};

export default FarmSummaryInfo;
