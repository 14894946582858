import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Modal, Row, Select, Switch } from "antd";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Add, Delete } from "@mui/icons-material";

const { Option } = Select;

const ProducesModal = (props) => {
  const {
    visible,
    formMode,
    closeModal,
    one_product_inventory,
    CreateProductInventory,
    UpdateProductInventory,
    GetAllProducts,
    allProducts,
    GetAllRawMaterials,
    allRawMaterials,
  } = props;
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [raw_materials, setRaw_materials] = useState([
    { uuid: 1, id: "", quantity: "" },
  ]);

  const checkMode = formMode === "CREATE_MODE";
  const { handleSubmit, reset, control } = useForm();

  const getProductRecords = async () => {
    const res = await GetAllProducts();
    if (!res.success) {
      setLoading(false);
      toast.error(`Error`);
    }
  };
  const getRawMaterialsRecords = async () => {
    const res = await GetAllRawMaterials();
    if (!res.success) {
      setLoading(false);
      toast.error(`Error`);
    }
  };
  useEffect(() => {
    getProductRecords();
    getRawMaterialsRecords();
  }, [GetAllProducts, GetAllRawMaterials]);

  const closeProducesModal = () => {
    reset();
    setProduct(null);
    setQuantity("");
    setRaw_materials([{ uuid: 1, id: "", quantity: "" }]);
    closeModal();
  };

  useEffect(() => {
    if (one_product_inventory) {
      setProduct(one_product_inventory?.product);
      setRaw_materials(one_product_inventory?.raw_materials);
      setQuantity(one_product_inventory.quantity);
    }
  }, [one_product_inventory]);
  const ProducesInventory = async () => {
    setLoading(true);
    const temp = raw_materials.map((item) => {
      if (!item.id) {
        return true;
      } else if (item.quantity === "") {
        return true;
      } else {
        return false;
      }
    });
    const modified_raw_materials = raw_materials?.map((item) => {
      return {
        id: item?.id,
        quantity: item?.quantity,
      };
    });
    if (product === "") {
      toast.error(`Product cannot be empty`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (quantity === "") {
      toast.error(`Quantity must be greater than zero`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (temp?.includes(true)) {
      toast.error(`Raw materials cannot be empty`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (formMode === "CREATE_MODE") {
      const createObj = {
        product,
        quantity,
        raw_materials: modified_raw_materials,
      };
      const res = await CreateProductInventory(createObj);
      if (res.success) {
        setLoading(false);
        toast.success("Product Inventory Created Successfully", {
          pauseOnHover: false,
        });
        closeProducesModal();
        requestAnimationFrame(() => {
          closeProducesModal();
          setLoading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setLoading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setLoading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
      setLoading(false);
    } else if (formMode === "UPDATE_MODE") {
      const updateObj = {
        id: one_product_inventory?.id,
        product,
        quantity,
        raw_materials: modified_raw_materials,
      };
      const res = await UpdateProductInventory(updateObj);
      if (res.success) {
        setLoading(false);
        toast.success("Product Inventory Updated Successfully", {
          pauseOnHover: false,
        });
        closeProducesModal();
        requestAnimationFrame(() => {
          closeProducesModal();
          setLoading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setLoading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setLoading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
    }
  };
  const checkOnchange = (value, record, name) => {
    if (name === "id") {
      const temp = raw_materials.map((item) => {
        if (item.uuid === record.uuid) {
          return { ...item, [name]: value };
        } else {
          return item;
        }
      });
      setRaw_materials(temp);
    } else if (name === "quantity") {
      const temp = raw_materials.map((item) => {
        if (item.uuid === record.uuid) {
          return { ...item, [value.target.name]: value.target.value };
        } else {
          return item;
        }
      });
      setRaw_materials(temp);
    } else {
      setProduct(value);
    }
  };

  const rawMaterialsChildren = allRawMaterials?.map((obj) => {
    return (
      <Option key={obj?.id} value={obj?.id}>
        {obj?.name}
      </Option>
    );
  });

  const productsChildren = allProducts?.map((obj) => {
    return (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    );
  });
  const deleteRawMaterial = (record) => {
    const filteredRaw_materials = raw_materials?.filter(
      (item) => item?.id !== record?.id
    );
    setRaw_materials(filteredRaw_materials);
  };

  const addRawMaterial = () => {
    setRaw_materials([
      ...raw_materials,
      { uuid: raw_materials?.length + 1, id: "", quantity: "" },
    ]);
  };
  return (
    <>
      <Modal
        visible={visible}
        title={
          <p style={{ fontWeight: "bold" }}>
            {checkMode
              ? "Create Produces Inventory"
              : `Update Produces Inventory`}
          </p>
        }
        footer={null}
        onCancel={closeProducesModal}
        maskClosable={false}
        width={"550px"}
      >
        <form onSubmit={handleSubmit(ProducesInventory)} autoComplete="off">
          <div className="content">
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Product</label>
                  <Controller
                    name="product"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        // allowClear
                        name="product"
                        placeholder="Select a product"
                        style={{ width: "100%" }}
                        size="large"
                        value={product}
                        onChange={checkOnchange}
                        defaultValue={checkMode ? null : product}
                      >
                        {productsChildren}
                      </Select>
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Quantity</label>
                  <input
                    name="quantity"
                    className="ant-input ant-input-lg"
                    placeholder="Type in Quantity"
                    value={quantity ? Number(quantity).toLocaleString() : ""}
                    onChange={(event) => {
                      const value = event.target.value.replace(/,/gi, "");

                      if (isNaN(value)) return;

                      setQuantity(value);
                    }}
                    defaultValue={
                      checkMode ? null : one_product_inventory?.value
                    }
                  />
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: "2rem" }}>
              <h6 style={{ marginBottom: "1rem", fontWeight: "bold" }}>
                Raw Materials
              </h6>
              {raw_materials?.map((material, index) => (
                <div
                  className="form-group"
                  key={index}
                  style={{
                    marginBottom: raw_materials?.length > 1 ? "-1rem" : "1rem",
                  }}
                >
                  <Row gutter={24}>
                    <Col xs={15} xl={15} lg={15}>
                      <label>Raw Material</label>
                      <Controller
                        name="id"
                        control={control}
                        render={() => (
                          <Select
                            // allowClear
                            name="raw_material"
                            placeholder="Select a raw material"
                            style={{ width: "100%" }}
                            size="large"
                            value={material?.id}
                            onChange={(value) =>
                              checkOnchange(value, material, "id")
                            }
                          >
                            {rawMaterialsChildren}
                          </Select>
                        )}
                      />
                    </Col>
                    <Col xs={9} xl={9} lg={9}>
                      <label>Quantity</label>
                      <input
                        name="quantity"
                        className="ant-input ant-input-lg"
                        placeholder="Type in Quantity"
                        type="number"
                        value={material?.quantity}
                        onChange={(value) => {
                          checkOnchange(value, material, "quantity");
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      {raw_materials?.length > 1 && (
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            float: "right",
                            margin: "1rem 0",
                          }}
                          className="del"
                          onClick={() => deleteRawMaterial(material)}
                        >
                          <Delete style={{ color: "red" }} />
                        </button>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
                <Col xs={24}>
                  <div style={{ marginBottom: "15px" }}>
                    <Button
                      onClick={addRawMaterial}
                      icon={<Add style={{ marginRight: "5px" }} />}
                      type="primary"
                    >
                      {" "}
                      Add Raw Material
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Divider />
          <Row style={{ margin: "2rem 0" }}>
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                loading={loading}
                type="primary"
                onClick={handleSubmit(ProducesInventory)}
                style={{ marginRight: "10px" }}
              >
                {formMode === "CREATE_MODE" ? "Save" : "Update"} Inventory
              </Button>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default ProducesModal;
