import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Modal, Row } from "antd";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const CreateProductModal = (props) => {
  const {
    visible,
    formMode,
    closeModal,
    one_raw_material,
    UpdateRawMaterial,
    CreateRawMaterial,
  } = props;
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const checkMode = formMode === "CREATE_MODE";
  const { register, handleSubmit, reset, control } = useForm();

  const closeService = () => {
    setName("");
    closeModal();
  };
  const createRawMaterialInventory = async () => {
    setLoading(true);
    if (name === "") {
      toast.error(`Raw material cannot be empty`, {
        pauseOnHover: true,
      });
      setLoading(false);
      return;
    }
    if (formMode === "CREATE_MODE") {
      const createObj = {
        name,
      };
      const res = await CreateRawMaterial(createObj);
      if (res.success) {
        setLoading(false);
        toast.success("Raw Material Created Successfully", {
          pauseOnHover: false,
        });
        closeService();
        requestAnimationFrame(() => {
          reset();
          setLoading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setLoading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setLoading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
      setLoading(false);
    } else if (formMode === "UPDATE_MODE") {
      const updateObj = {
        id: one_raw_material?.id,
        name,
      };
      const res = await UpdateRawMaterial(updateObj);
      if (res.success) {
        setLoading(false);
        toast.success("Raw Material Updated Successfully", {
          pauseOnHover: false,
        });
        closeService();
        requestAnimationFrame(() => {
          reset();
          setLoading(false);
        });
      } else if (!res.success && res.message === undefined) {
        setLoading(false);
        toast.error("Network Error", {
          pauseOnHover: true,
        });
      } else {
        setLoading(false);
        toast.error(res.message, {
          pauseOnHover: true,
        });
      }
    }
  };

  useEffect(() => {
    if (one_raw_material) {
      setName(one_raw_material?.name);
    }
  }, [one_raw_material]);
  return (
    <>
      <Modal
        visible={visible}
        title={
          <p style={{ fontWeight: "bold" }}>
            {checkMode ? "Create Raw Material" : `Update Raw Material`}
          </p>
        }
        footer={null}
        onCancel={closeService}
        maskClosable={false}
        width={"400px"}
      >
        <form
          onSubmit={handleSubmit(createRawMaterialInventory)}
          autoComplete="off"
        >
          <div className="content">
            <div className="form-group">
              <Row gutter={24}>
                <Col xs={24} xl={24} lg={24}>
                  <label>Name</label>
                  <input
                    name="name"
                    className="ant-input ant-input-lg"
                    placeholder="Type in Name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <Divider />
          <Row style={{ margin: "2rem 0" }}>
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                loading={loading}
                type="primary"
                onClick={handleSubmit(createRawMaterialInventory)}
                style={{ marginRight: "10px" }}
              >
                {formMode === "CREATE_MODE" ? "Save" : "Update"} Material
              </Button>
            </Col>
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default CreateProductModal;
