// export  function validateEmail(elementValue) {
//     var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
//     return emailPattern.test(elementValue);
// }

// export  function validatePassword(elementValue) {
//     var passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
//     return passwordPattern.test(elementValue);
// }
import moment from "moment";

const errorMessage = (err) => {
  return {
    non_field_errors: [null],
    email: [null],
    password1: [null],
    password2: [null],
    password: [null],
    first_name: [null],
    last_name: [null],
    username: [null],
    ...err,
  };
};

export { errorMessage };

export const dateFormatterHandler = (dateStr, format = "DD-MM-YYYY") => {
  if (!dateStr) {
    return undefined;
  }
  return moment(dateStr, format, true).isValid()
    ? moment(dateStr, format, true)
    : undefined;
};

export const GetFromNowDate = (
  dateValue,
  stringFormat,
  fromNow,
  format = "DD-MM-YYYY"
) => {
  if (moment(dateValue, stringFormat).isValid()) {
    if (fromNow) {
      return moment(dateValue).fromNow();
    }
    return moment(dateValue, stringFormat).format(format);
  }
  return "";
};

export const formatDateObjectHandler = (dateObj, format = "YYYY-MM-DD") => {
  if (!dateObj) {
    return undefined;
  }
  if (!moment.isMoment(dateObj)) {
    return moment(dateObj).format(format);
  }
  return dateObj.format(format);
};

export const CurrencySign = "₦";
// export const CurrencySign = '$';

// check if an item exist
export const ItemExist = (list, item, value) => {
  if (!Array && list === undefined) {
    return;
  }
  return list.item === value;
};
export const findAllTotal = (list) => {
  if (!Array && list === undefined) {
    return 0;
  }
  if (list !== undefined && list.length > 0) {
    return list.reduce((a, b) => a + parseFloat(b), 0);
  }
  return "0.00";
};

export const formatAmount = (num) => {
  if (num) {
    const initial = parseFloat(num).toFixed(2);
    return initial.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const formatNumber = (num) => {
  if (!num) {
    return;
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// TO REDUCE STRING LENGTH
export const truncateText = (string = "", length = "30", ending = "...") => {
  if (!(string && length)) {
    return;
  }
  return length > string.length
    ? string
    : string.substring(0, length - ending.length) + ending;
};

export const capitalize = (val) => {
  return val.charAt(0).toUpperCase() + val.slice(1);
};

export const priceFormat = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
    currencyDisplay: "symbol",
  }).format(value);

export const phoneNumberFormat = (phoneNumber) => {
  return (
    phoneNumber.slice(0, 3).replace(/(.{3})/g, "$1 ") +
    phoneNumber.slice(3, 6).replace(/(.{3})/g, "$1 ") +
    phoneNumber.slice(6, 10).replace(/(.{4})/g, "$1 ") +
    phoneNumber.slice(10, 14).replace(/(.{4})/g, "$1 ")
  ).trim();
};
