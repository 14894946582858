import React, { useState } from "react";
import DefaultLayout from "../Layout/DefaultLayout";
import { Tabs } from "antd";
import { ConfigurationContainer } from "./AdminStyle.styled";
import SystemConfiguration from "./config/system/SystemConfiguration";
import ServiceConfiguration from "./config/services/ServiceConfiguration";
import SubscriptionConfiguration from "./config/subscriptions/SubscriptionConfiguration";
import AddNewCrop from "./config/crop/AddNewCrop";

const { TabPane } = Tabs;

const AdminConfiguration = () => {
  const [activeKey, setActiveKey] = useState("1");

  const nextTab = () => {
    if (activeKey === "3") {
      return;
    }
    setActiveKey(String(parseInt(activeKey) + 1));
  };
  const prevTab = () => {
    if (activeKey === "1") {
      return;
    }
    setActiveKey(String(parseInt(activeKey) - 1));
  };

  function callback() {
    setActiveKey(activeKey);
  }

  return (
    <DefaultLayout browserTitle="Configuration">
      <ConfigurationContainer data-aos="fade-right">
        <Tabs onChange={callback} defaultActiveKey="1">
          <TabPane tab="System Settings" key="1">
            <SystemConfiguration />
          </TabPane>
          <TabPane tab="Services" key="2">
            <ServiceConfiguration />
          </TabPane>
          <TabPane tab="Subscription Plans" key="3">
            <SubscriptionConfiguration />
          </TabPane>
          <TabPane tab="Crop Management" key="4">
            <AddNewCrop />
          </TabPane>
        </Tabs>
        {/* <div className="button-control">
                    <Row gutter={14} align="middle" justify="center">
                        <Col>
                            <Button className="btn prev" onClick={prevTab}>
                                <ArrowLeft /> Prev
                            </Button>
                        </Col>
                        <Col>
                            <Button className="btn next" onClick={nextTab}>
                                Next <ArrowRight />
                            </Button>
                        </Col>
                    </Row>
                </div> */}
      </ConfigurationContainer>
    </DefaultLayout>
  );
};

export default AdminConfiguration;
